import React, {Component, useState, useEffect, Fragment}  from 'react';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import Sidebar from '../Main/Sidebar';

import Login from '../Login'
import RegisterCore from '../Register/RegisterCore'
import VerifyRegisterCore from '../Register/VerifyRegisterCore'
import LogoutCore from '../Logout/LogoutCore';
import UserSettings from '../UserSettings'

import Event from '../Event'

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

import {CSSTransition} from 'react-transition-group'
import JwtDecode from 'jwt-decode'

import _ from 'lodash';

import './animation.css'
import './styles.css'

import {
    AspectMeasuresService,
    WorkGroupsService,
    PublishSubscribe
} from '../../services'

import panels from '../../constants/panels';

export default function Main(props){
  let defaultPanelGroupExists = 'huddle-reports';
  let defaultPanelNoGroup = 'workunit-crew';
  let [workGroup, setWorkGroup] = useState(null);
  let [panel, setPanel] = useState('');
  

  //Effect for initially loading the page:
  useEffect(() => {

    const loadData = async () => {
      console.log("Running useEffect of Main component");
      let workGroups = await WorkGroupsService.getUserWorkGroups();
     
      if (workGroups.length > 0){
        await updateWorkGroup(workGroups[0]);
        if (props.match.params.page == 'loading'){
          props.history.push(panels.get(defaultPanelGroupExists).path)
        }
      } else {
        setWorkGroup(false);
        if (props.match.params.page == 'loading'){
          props.history.push(panels.get(defaultPanelNoGroup).path)
        }
      }
    }
    loadData();
    //This is written this way instead of being directly inline
    //because you can't directly use async/await in the useEffect hook.

    let body = document.querySelector("body");
    body.insertBefore(document.querySelector('.footer'), body.firstChild);
    body.insertBefore(document.querySelector('#main-container'), body.firstChild);
    body.insertBefore(document.querySelector('#sidebar-control'), body.firstChild);
    body.insertBefore(document.querySelector('#mySidenav'), body.firstChild);
    body.insertBefore(document.querySelector('[data-navbar="static"]'), body.firstChild);

  }, []);

  let workgroupOnLoad = localStorage.getItem("activeWorkGroup");
  let idworkgroup = -1;
  if (workgroupOnLoad){
    let groupData = JSON.parse(workgroupOnLoad);
    idworkgroup = groupData.idworkgroup;
  }

   //Publish/Subscribe listener; exists to kick users out of the app on deletion from a work group
  let PS = PublishSubscribe.usePublishSubscribe({
    origin: "Main/index.js",
    topic: "CH/workgroups",
    identifier: {idworkgroup},
    callback: (message) => {
      const userJWT = localStorage.getItem('token')
      const userDecoded = JwtDecode(userJWT)
      const email = userDecoded ? userDecoded['email'] : null

      console.log("DeleteUser Got message from CH workGroups with data", message, "my email is", email, "are they equal?", message.idemployee == email);

      if (message.type == "removed-employee" && message.idemployee == email){
        localStorage.removeItem("activeWorkGroup")
        console.log("DeleteUser refreshing page")
        window.location = "app/loading";
      }
    }
  })


  //Effect for loading the correct panel from the URL:
  useEffect(() => {

    console.log("useEffect match main page", "page param:" + props.match.params.page);

    if (props.match.params.page == "loading"){
      console.log("useEffect match main page", "Returning without getting panel because loading");
      return
    } else {

      let tempPanel = panels.get(props.match.params.page);
      console.log("useEffect match main page", "tempPanel:", tempPanel);
      if (tempPanel != undefined){
         setPanel(tempPanel)
      } else {
        props.history.push('/app/huddle-reports');
      }
    }
   
  }, [props.match.params.page]);

  //useEffect(() => console.log("Triggering re-render of Main"));

  let updateWorkGroup = async (group) => {
    console.log("Calling updateWorkGroup with group ", group);
    setWorkGroup(group);
    localStorage.setItem("activeWorkGroup", JSON.stringify(group));
    //let hasLibrary = await AspectMeasuresService.getWorkgroupHasLibrary(group);
    //setHasLibrary(hasLibrary);
  }

  const AnimatedPanel = ({mode, children}) => { 

    //console.log("AnimatedPanel in check for mode " + mode + " and displayState " + displayState, mode == displayState)

    /*return(
      <CSSTransition 
        in={mode == displayState}
        unmountOnExit
        timeout={400}
        classNames="slide-right"
        className="slide-right-base bg-light"
      >
        {children}
      </CSSTransition>
    )*/

    return children;

  }

  let displayThis = (obj, showLogs) => {
    let {showIfNoWorkGroup, displayTo} = obj;
    let workGroupRoleExists = (!_.isEmpty(workGroup) && !!workGroup.role);
    let displayToMe = (workGroupRoleExists ? (displayTo.indexOf(workGroup.role) !== -1) : false )
    let hasWorkGroup = (workGroup !== false);

    console.log("displayThis obj:", obj);
    console.log("displayThis displayTo val:", displayTo);
    console.log("displayThis displayToMe val:", displayToMe);


    if (hasWorkGroup && workGroupRoleExists){
     return displayToMe;
    } else {
      return showIfNoWorkGroup;
    }
  }

  return(<>
    <Navbar />
    <Sidebar 
      displayThis={displayThis}
      activePanel={panel}
      panels={panels}
    />

    <div className="container-fluid" id="main-container">
     
      <main id="main" className={"px-4"}>
         
         {panel ? 
            <panel.JSX
              history={props.history}
              unit={workGroup}
              updateWorkGroup={updateWorkGroup}
            />
          : null}

      </main>
    </div>

    <Footer />

    <Event eventtype="chUserCheck" />
  </>)

 

}
