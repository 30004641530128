import {isJsonString} from './isJsonString';

export function parseIfJSONString(passed){

	if (isJsonString(passed)){
		return JSON.parse(passed);
	} else {
		return passed;
	}

}