import React, {Fragment, useState, useEffect} from 'react';
import ViewReport from '../ViewReport';
import SuccessPopup from '../../common/Popups'
import _ from 'lodash';
import {
    ReportsService
} from '../../../services'


export default function HuddleAcknowledgement (props) {

	let [loadingReport, setLoadingReport] = useState(true);
	let [loadingTakingTooLong, setLoadingTakingTooLong] = useState(false);
	let [sendingTakingTooLong, setSendingTakingTooLong] = useState(false);
	let [acknowledgementLocked, setAcknowledgementLocked] = useState(false);
	let [reportLocked, setReportLocked] = useState(false);
	let [reportInvalid, setReportInvalid] = useState(false);
	let [notes, updateNotes] = useState("");
	let [notesInvalid, setNotesInvalid] = useState(false)
	let [sending, setSending] = useState(false);
	let [successPopupTrigger, setSuccessPopupTrigger] = useState(false);
	let [errorPopupTrigger, setErrorPopupTrigger] = useState(false);

	const controller = new AbortController();
	let timeoutAmount = 5 * 1000;

	useEffect(() => {
		loadReport();

		setTimeout(() => {
			console.log("setTimeout function firing")
			if (loadingReport){
				setLoadingTakingTooLong(true);
				console.log("Controller aborting due to loading taking too long");
				controller.abort();
			}
		}, timeoutAmount)
		
	}, [])


	let loadReport = async () => {
		let {tokenData, workGroupData} = await ReportsService.loadReportFromToken(props.match.params.verificationtoken, controller.signal);

		console.log("tokenData:", tokenData);

		if (!_.isEmpty(tokenData) && tokenData[0].idreport){
			tokenData = tokenData[0];
			if (tokenData.acknowledged){
				setAcknowledgementLocked(true);
			} else if (tokenData.status == "finished" && tokenData.acknowledged == 0){
				setAcknowledgementLocked(true);
				setReportLocked(true);
			}
			
			console.log("Valid report found in loadReport")

			await localStorage.setItem("activeReport", tokenData.idreport);
			await localStorage.setItem("activeWorkGroup", JSON.stringify(workGroupData));
			setLoadingReport(false);
		} else if (tokenData === false){
			setLoadingTakingTooLong(true);	
			controller.abort();
			console.log("Controller aborting due to token data being false")
		} else {
			console.log("invalid report found in loadReport");
			setReportInvalid(true);
		}
	}

	let acknowledgeReport = async () => {

		if (notes && notes.length > 65535){
			setNotesInvalid(true);
		} else {
			setSending(true);
			setNotesInvalid(false);
			let token = props.match.params.verificationtoken;

			let data = {notes, token}

			setTimeout(() => {
				if (sending && !errorPopupTrigger){
					setErrorPopupTrigger(true);
				}
			}, timeoutAmount)

			let results = await ReportsService.acknowledgeReport(data, controller.signal);
			setSending(false);

			if (results === false){
				if (!errorPopupTrigger){
					setErrorPopupTrigger(true);
				}
			} else {
				setSuccessPopupTrigger(true);
			}
		}
		
	}

	if (reportInvalid){
		return <div class="alert alert-warning m-5" role="alert">
		  <strong>Invalid huddle.</strong> Please contact your admin if you believe you are recieving this message in error.
		</div>
	}
	else if (loadingReport){

		if (loadingTakingTooLong){
			return  <div class="alert alert-danger m-5" role="alert">
			  <strong>Your huddle couldn't be loaded.</strong> You might need to check your connection, or try again later.
			</div>
		} else {
			return <> <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> Loading your huddle... </>
		}

	} 
	else return <>

		{acknowledgementLocked ?<>
			{reportLocked ? 
				<div class="alert alert-info m-5" role="alert">
				  This Huddle has been closed; you can no longer acknowledge it.
				</div>
			:
				<div class="alert alert-info m-5" role="alert">
				  You have already acknowledged this Huddle.
				</div>
			}

			<ViewReport 
				{...props}
				acknowledgementMode = {true}
			/>

		</>

		: <>

			<ViewReport 
				{...props}
				acknowledgementMode = {true}
			/>

			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-10 mx-auto border-top my-4 pt-4">

						<div className="form-group">
			                <p className="fs-16 strong mt-4 mb-2">Notes</p>
			                <textarea className={"form-control" + (notesInvalid ? " is-invalid" : "")}
			                    placeholder="Enter any additional information or feedback" 
			                    rows="4"
			                    value={notes || ""}
			                    onChange={(e) => updateNotes(e.target.value)}
			                    disabled={sending}
			                    >
			                
		                    </textarea>
		                    {notesInvalid ? <p classname="text-danger">Notes are too long</p> : null}
			            </div>

						<button className="btn btn-primary btn-block" disabled={sending} onClick={acknowledgeReport}>
							{sending ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null }
							Acknowledge
						</button>

						<SuccessPopup
				        	trigger={successPopupTrigger}
				        	popupId="successPopupAcknowledgement"
				        	style="success"
				        	useButton={true}
				        	buttonLabel="Done"
				        	title={"Huddle Acknowledged"}
				        	message={"You have successfully acknowledged this huddle."}
				        	callback={() =>{
				        		setSuccessPopupTrigger(false);
				        		setSending(false);
				        		setAcknowledgementLocked(true);	
				        	}}
				        />

				        <SuccessPopup
				        	trigger={errorPopupTrigger}
				        	popupId="errorPopupAcknowledgement"
				        	style="danger"
				        	useButton={true}
				        	buttonLabel="OK"
				        	title={"Huddle Not Acknowledged"}
				        	message={"Check your connection and try again later"}
				        	callback={() =>{
				        		setSuccessPopupTrigger(false);
				        		setSending(false);
				        	}}
				        />


					</div>
				</div>
			</div>
		</>}

	</>

}
