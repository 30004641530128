import React, {Component, Fragment} from 'react'
import CreateReport from './CreateReport';
import CompleteReport from './CompleteReport';
import SuccessPopup from '../../common/Popups';
import { url } from '../../../constants'
import {
    LibraryService,
    ApiService,
    ReportsService,
    WorkGroupsService
} from '../../../services'
import {
    chLoadReport,
    alphanumericSort
} from '../../../utils'
import _ from 'lodash'
import {
  isMobileOnly,
  isTablet
} from 'react-device-detect'
import formatDataForSubmit from "./utils/formatDataForSubmit"

export default class ReportSetup extends Component {

	constructor(props){
        super(props);

        let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));

        this.state = {
        	name: "", 
        	startDate: null,
        	idsite: null,
        	sitesList: [],
        	employeesList: [],
            createdby: ApiService.getJWTEmail(),
        	groupsList: [{name: "", entries: [{name: "", oppositeRelations: []}]}],
        	workUnit,
            phase: "create",
            showCancelPopup: false,
            measuresList: null,
            notes: "",
            idaspects: [],
            submitDisabled: false,
            sendingReport: false,
            employeeIDs: [],
            idreport: null,
            status: "setup_create",
            loadedReportData: false,
            anyMeasuresSelected: false, 

            showPreviousReportDatePicker: false,
            previousReportDate: null,
        }
    }

    componentWillMount = async () => {
    	let {workUnit, createdby} = this.state;

    	let groupsList = await LibraryService.getLibrary(workUnit.idworkgroup, "aspect");
    	let employeesList = await WorkGroupsService.getEmployees(workUnit.idworkgroup);
        employeesList = this.ensureCreatorSelected(employeesList);

        let employeeIDs = employeesList.map(({idemployee}) => {return idemployee});
    	let sitesList = alphanumericSort(await WorkGroupsService.getWorkGroupSites(workUnit.idworkgroup), "name");
        let loadedReportData = false;

        let newState = { groupsList, employeesList, employeeIDs, sitesList, loadedReportData}

        if (localStorage.getItem("activeReportToEdit")){
            let idreport = localStorage.getItem("activeReportToEdit");
            console.log("loading activeReportToEdit with idreport", idreport);
            loadedReportData = await ReportsService.getReport(idreport);
            console.log("activeReportToEdit loadedReport data:", loadedReportData);
            let loadReportIntoNewState = chLoadReport({groupsList, employeesList, employeeIDs, sitesList, loadedReportData, idreport, includeMeasures: true});
            console.log("activeReportToEdit loadReportIntoNewState:", loadReportIntoNewState);

            loadReportIntoNewState.loadedReport = true;
            localStorage.removeItem("activeReportToEdit");
            loadReportIntoNewState.sitesList = alphanumericSort(loadReportIntoNewState.sitesList, "name");

            newState = _.cloneDeep(loadReportIntoNewState);
            console.log("activeReportToEdit newState in localStorage if statement:", newState);

        }

        console.log("activeReportToEdit newState:", newState);
    	this.setState(newState, () => {
            if (newState.phase == "complete"){
                //this.completeReport();
            } else {

            }
        })
    }

    getNumSelected = (list) => {
    	let numSelected = 0;
    	for (var i = 0; i < list.length; i++){
    		if (list[i].selected){
    			numSelected++;
    		}
    	}

    	return numSelected;
    }

    setSelectedEmployee = (index, val) => {
    	let {employeesList, createdby} = this.state;
        if (employeesList[index].email !== createdby){
    	   employeesList[index].selected = !val;
        }

    	this.setState({employeesList});
    }

    setSelectedAspect = async (groupIndex, entryIndex, val) => {
    	let {groupsList, measuresList} = this.state;
        let selectedAspect = groupsList[groupIndex].entries[entryIndex];
        let updatedValue = !val;
    	groupsList[groupIndex].entries[entryIndex].selected = updatedValue;

        let idaspects = this.getIDAspects(groupsList);
        console.log("measuresList current list of aspects:", idaspects);        

        if (updatedValue == false){ //If aspect is being removed
            console.log("measuresList aspect is being removed with aspect:", selectedAspect);

            let measuresToRemove = []

            if (!_.isEmpty(measuresList)){
                for (var i = 0; i < measuresList.length; i++){
                    if (!_.isEmpty(measuresList[i].entries)){
                        for (var j = 0; j < measuresList[i].entries.length; j++){
                            if (idaspects.length > 0){
                                let measure_idaspects = measuresList[i].entries[j].idaspects;
                                console.log("measuresList measure entry being looked at:", measuresList[i].entries[j].name);

                                if (measure_idaspects !== null && measure_idaspects !== undefined){


                                    measure_idaspects = measure_idaspects.split(',');
                                    console.log("measuresList measure_idaspects:", measure_idaspects)

                                    let entryHasAnAspect = false;

                                    for (var k = 0; k < idaspects.length; k++){
                                        let aspectindex = measure_idaspects.indexOf(idaspects[k] + "");
                                        if (aspectindex !== -1){
                                            console.log("measuresList found idaspect " + idaspects[k]);
                                            entryHasAnAspect = true;
                                        }
                                    }

                                    if (!entryHasAnAspect){
                                        measuresToRemove.push({i, j});
                                    }
                                }
                            } else {
                                measuresToRemove.push({i, j});
                            }
                        }

                    }
                }
            }

            console.log("measuresToRemove:", measuresToRemove);

            if (measuresToRemove.length > 0){
                for (var x = measuresToRemove.length-1; x >= 0; x--){
                    let {i, j} = measuresToRemove[x];
                    measuresList[i].entries.splice(j, 1);
                    if (measuresList[i].entries.length == 0) measuresList[i].entries = undefined;
                }

            }

        } else {
            if (!_.isEmpty(measuresList)){
                let newMeasures = await LibraryService.getMeasuresForAspectList([selectedAspect.idaspect]);
                console.log("measuresList newMeasures:", newMeasures);

                for (var i = 0; i < newMeasures.length; i++){
                    if (!_.isEmpty(newMeasures[i].entries)){
                        for (var k = 0; k < newMeasures[i].entries.length; k++){
                            if (measuresList[i].entries){
                                let existingMeasures = measuresList[i].entries.map(({idmeasure}) => {return idmeasure});
                                if (existingMeasures.indexOf(newMeasures[i].entries[k].idmeasure) == -1){
                                    measuresList[i].entries.push(newMeasures[i].entries[k]);
                                    measuresList[i].entries = _.sortBy(measuresList[i].entries, "name");
                                }
                            } else {
                                measuresList[i].entries = [_.cloneDeep(newMeasures[i].entries[k])];
                            }
                        }
                    }
                }
            }
        }

    	this.setState({groupsList, measuresList});
    }

    setSelectedSite = async (idsite) => {

        let {employeesList, employeeIDs, groupsList, sitesList, workUnit, idreport} = this.state;

        let newState = {}

        let loadedReportData = await ReportsService.getNewestReportBySite(idsite);
        console.log("setSelectedSite loadedReportData:", loadedReportData);

        console.log("Log to trigger recompile")

        if (loadedReportData != null){

            newState = await chLoadReport({sitesList, employeesList, employeeIDs, groupsList, loadedReportData, partialLoad: true});
            newState.idreport = (this.state.loadedReport ? idreport : null);
            newState.employeesList = this.ensureCreatorSelected(newState.employeesList);
            console.log("setSelectedSite idsite param:", idsite);
            console.log("setSelectedSite newState:", newState)
        } else {
            newState.idreport = (this.state.loadedReportData ? idreport : null);
            for (var i = 0; i < sitesList.length; i++){
                if (sitesList[i].idsite == idsite){
                    newState.name = sitesList[i].name;
                }
            }
            newState.idsite = idsite;
            newState.groupsList = await LibraryService.getLibrary(workUnit.idworkgroup, "aspect");
            newState.employeesList = await WorkGroupsService.getEmployees(workUnit.idworkgroup);
            newState.employeesList = this.ensureCreatorSelected(newState.employeesList);

            console.log("setSelectedSite idsite param:", idsite);
            console.log("setSelectedSite newState:", newState)
        }

        if (newState.sitesList !== undefined) newState.sitesList = alphanumericSort(newState.sitesList, "name");
      
        this.setState(newState);
    }

    handleChange = (key, val) => {
        this.setState({[key]: val});
    }

    setMeasureStatus = (groupIndex, entryIndex, val) => {
        let {measuresList} = this.state;
        measuresList[groupIndex].entries[entryIndex].status = val;
        let anyMeasuresSelected = true;
        this.setState({measuresList, anyMeasuresSelected});
    }

    cancelReport = () => {
        this.setState({showCancelPopup: true})
    }

    completeReport = async () => {
        console.log("Calling completeReport");
        let {groupsList, measuresList} = this.state;
        let idaspects = this.getIDAspects(groupsList);

        if (_.isEmpty(measuresList)){
            console.log("idaspects for measuresList in completeReport:", idaspects);
            measuresList = await LibraryService.getMeasuresForAspectList(idaspects);
        }

        this.setState({measuresList, idaspects, phase: "complete"}, () => {
            console.log("completeReport completed with measuresList", measuresList)
        })
    }

    getIDAspects = (groupsList) => {
        let idaspects = [];

        //Extract selected idaspects from groupsList
        for (var i = 0; i < groupsList.length; i++){
            for (var j = 0; j < groupsList[i].entries.length; j++){
                let entry = groupsList[i].entries[j]
                if (entry.selected){
                    idaspects.push(entry.idaspect)
                }
            }
        }

        return idaspects;
    }

    ensureCreatorSelected = (employeesList, createdby) => {
        if (employeesList == undefined) employeesList = _.cloneDeep(this.state.employeesList);
        if (createdby == undefined) createdby = this.state.createdby;

        for (var i = 0; i < employeesList.length; i++){
            if (employeesList[i].email == createdby){
                employeesList[i].selected = true;
            }
        }

        return employeesList;
    }

    returnToCreate = () => {
        this.setState({phase: "create"})
    }

    updateNotes = (e) => {
        this.setState({notes: e.target.value});
    }

    saveReport = async (redirectPage = "huddle-reports") => {
        let {status, phase} = this.state;
        this.setState({sendingReport: true, status: "setup_" + this.state.phase}, async () => {
            console.log("saveReport status:", this.state.status);

            let res = await ReportsService.saveReportSetup(formatDataForSubmit(this.state));
            if (res.success){
                if (phase == "complete"){

                    if (redirectPage == "report-view"){
                        localStorage.setItem("activeReport", res.idreport);
                    }
                    this.props.history.push('/app/'+redirectPage);
                } else {
                    this.props.history.push("/app/huddle-reports")
                }
            } else {
                this.setState({sendingReport: false});
                alert("Error saving report");
            }
        });
    }

    render = () => {
    	let {status, phase, measuresList, notes, idaspects, sendingReport} = this.state;
        let content = null;

        return(<div>
            {phase == "create" ? 
                <CreateReport
                    reportState = {this.state}
                    handleChange= {this.handleChange}
                    getNumSelected = {this.getNumSelected}
                    setSelectedAspect = {this.setSelectedAspect}
                    setSelectedEmployee = {this.setSelectedEmployee}
                    setSelectedSite = {this.setSelectedSite}
                    cancel={this.cancelReport}
                    completeReport = {this.completeReport}
                    saveReportForLater = {this.saveReport}
                    createdby = {this.state.createdby}
                    isMobileOnly={isMobileOnly}
                    isTablet={isTablet}
                />
            : 
                <CompleteReport
                    measuresList = {measuresList}
                    setMeasureStatus = {this.setMeasureStatus}
                    returnToCreate = {this.returnToCreate}
                    finishReportSetup = {(redirectPage) => this.saveReport(redirectPage)}
                    notes = {notes}
                    updateNotes = {this.updateNotes}
                    idaspects = {idaspects}
                    sendingReport={sendingReport}
                    startDate={this.state.startDate}
                    isMobileOnly={isMobileOnly}
                    isTablet={isTablet}
                /> }

             <SuccessPopup
                popupId="cancelPopup"
                style="warning"
                title="Confirm"
                message="Really exit report creation?"
                buttonLabel="Yes"
                trigger={this.state.showCancelPopup}
                callback={() =>  {this.props.history.goBack();}}
                useButton = {true}
                />
        </div>)
    }
}
