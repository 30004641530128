import _ from 'lodash';

export function loadEntryImages(entry){
    if (_.isEmpty(entry) || _.isEmpty(entry.pictures)){
        return [];
    } else if (typeof entry.pictures == "string"){
        return entry.pictures.split(',');
    } else if (Array.isArray(entry.pictures)) {
        return entry.pictures;
    } else {
        return [];
    }
}