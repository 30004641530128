import React, {Component, Fragment} from 'react'
import DatePicker from 'react-datepicker' 
import ViewEntryModal from '../../common/ViewEntryModal';

export default class CreateReport extends Component {

	constructor(props){
        super(props);

        this.state = {
        	activeGroupIndex: 0,
        	activeEntryIndex: 0,
        	employeeFilter: "",
        }
    }

    setActiveEntry = (activeGroupIndex, activeEntryIndex) => {
        this.setState({activeEntryIndex, activeGroupIndex})
    }

    render = () => {
    	let {handleChange, getNumSelected, cancel, completeReport, setSelectedSite, saveReportForLater} = this.props;
    	let {startDate, name, sitesList, groupsList, employeesList, idsite, showPreviousReportDatePicker, previousReportDate, sendingReport} = this.props.reportState;

    	return(<>
			<header className="section-header mt-5 mb-0">
				<h2><strong>Start Report</strong></h2>
			</header>
			<div className="section pt-0">
				<div className="container px-0">
					<div className="row">

						<div className="col-md-9 col-xl-8 mx-auto border-top mt-4 pt-4 mb-6 px-0">

							<div className="col-12 px-0 clearfix">
								<div className="col-12 col-lg-6 float-left">
									<i className="fa fa-map-marker float-left mt-3 fs-18" aria-hidden="true"></i>
									<div className="col-11 col-lg-10 p-0 m-0 mb-5 mb-lg-3 contrast float-left">
										<select className="form-control strong fs-16 p-0 ml-2 pl-2"
										value={idsite}
			                            onChange={(e) => {
			                                setSelectedSite(e.target.value)
			                            }}>
											<option value={null}>Project Site</option>
											{sitesList.map(({idsite, name}, index) => {return(
												<option value={idsite}>{name}</option>
											)})}
										</select>
									</div>
								</div>
								<div className="col-12 col-lg-6 float-left">
									<i className="fa fa-calendar float-left mt-3 fs-18" aria-hidden="true"></i>
									<div className="col-11 col-lg-6 p-0 m-0 mb-5 mb-lg-4 contrast float-left">
									  <DatePicker
										className={"form-control strong fs-16 p-0 ml-2 pl-2"}
										dateFormat="MM/dd/yyyy"
										selected = {startDate || null}
										onChange={ startDate => handleChange("startDate", startDate) }
										placeholderText="Date"
						              />
									</div>

								</div>
							</div>

							<div className="col-12 mt-2 pb-2 px-0 border-bottom">
								<div className="col-12 col-lg-6 pl-1 mb-2 clearfix">
									<p className="mt-4 mb-1 fs-17 float-left"><i className="fa fa-users px-2" aria-hidden="true"></i><strong>Employees</strong></p>
									<button className="btn btn-sm btn-secondary fs-12 mt-4 mb-3 ml-4 px-6 float-left" disabled={idsite == null} data-toggle="modal" data-target="#select-crew">Select<i className="fa fa-long-arrow-right pl-2" aria-hidden="true"></i></button>
								</div>

								<div className="col-12 ml-5">
									<ul className="list-unstyled card-columns">
										{
											(getNumSelected(employeesList) > 0) ? 
												employeesList.map((employee, index) => {
													if (employee.selected){
														return <li className="strong" key={index}>{employee.name || employee.idemployee}</li>
													}
												})
											:
												<li className="ml-5"><em>None selected</em></li>
										}
									</ul>
								</div>
							</div>

							<div className="col-12 pb-0 mb-0 ml-2 px-0 clearfix">
								<p className="mt-4 mb-0 fs-17 float-left"><i className="fa fa-cube px-2" aria-hidden="true"></i><strong>Relevant Aspects</strong></p>
								<button className="btn btn-sm btn-secondary fs-12 mt-4 ml-4 px-6 float-left" disabled={idsite == null} data-toggle="modal" data-target="#select-aspects">Select<i className="fa fa-long-arrow-right pl-2" aria-hidden="true"></i></button>
							</div>        

							{groupsList.map((group, groupIndex) => {
								return(<div className="col-10 pl-0 mb-5 ml-7">
									<p className="fs-16 mt-4 mb-2"><strong>{group.name}</strong></p>
									{
										(getNumSelected(group.entries) > 0) ? <ul className="card-columns">
												{group.entries.map((entry, entryIndex) => {
													if (entry.selected){
														return <li key={entryIndex}><a className="text-primary strong" data-toggle="modal" data-target="#view-aspect" onClick={() => this.setActiveEntry(groupIndex, entryIndex)}>{entry.name}</a></li>
													}
												})}
											</ul>:
												 <p className="ml-5"><em>None selected</em></p>
									}
																			
								</div>)

							})}                 

						</div>
					</div>

					{this.props.isMobileOnly ?

						<div class="mb-6 pt-4 border-top">
	                        <button type="button" class="btn btn-block btn-secondary my-4" onClick={cancel}><i class="fa fa-angle-left mr-1"></i> CANCEL</button>
	                        <button type="button" class="btn btn-block btn-outline-primary my-4" onClick={saveReportForLater} disabled={sendingReport}>
	                        	{sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
	                        	Save for Later
                        	</button>
	                        <button type="button" class="btn btn-block btn-primary my-4" onClick={completeReport}>CONTINUE <i class="fa fa-angle-right ml-1"></i></button>
	                    </div>

						:

						<div className="col-md-10 col-xl-6 mx-auto mb-6 flexbox pt-4 border-top">
							<button className="btn btn-secondary" type="button" onClick={cancel}><i className="fa fa-angle-left mr-1"></i> CANCEL</button>
							<button type="button" className="btn btn-outline-primary" onClick={saveReportForLater} disabled={sendingReport}>
								{sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
								Save for Later
							</button>
							<button type="button" className="btn btn-primary" onClick={completeReport} disabled={sendingReport}>CONTINUE <i className="fa fa-angle-right ml-1"></i></button>
						</div>


					}

					


				</div>
			</div>

			{this.renderSelectCrew()}
			{this.renderGetAspectModal()}
			{this.renderViewAspect()}
		</>)
    }

    renderSelectCrew = () => {
    	let {workUnit, employeesList} = this.props.reportState;
    	let {employeeFilter} = this.state;

		return(<div className="modal fade" id="select-crew" tabindex="-1" role="dialog" aria-labelledby="select-crew" aria-hidden="true">
			<div className="modal-dialog modal-long" role="document">
				<div className="modal-content">

					<div className="modal-header">
						<h5 className="modal-title" id="select-crew">Employees</h5>
							<button type="button" className="close pt-5 pb-0" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

					<div className="modal-body">
						<p className="strong fs-14 mb-2">Select Employees who are present for this Crew Huddle:</p>

						<p className="fs-16 strong">
							<a className="p-1">{workUnit.name}</a>
						</p>

						<div className="col-11 form-group input-group px-0">
							<input type="text" className="form-control" placeholder="Search for..." 
								value={employeeFilter}
								onChange={(e) => {this.setState({employeeFilter: e.target.value})}}
							/>
							<div className="input-group-append ml-4">
								<button className="btn btn-xs btn-secondary px-5" type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
							</div>
						</div>

						<div className="custom-controls-stacked ml-6">

							{employeesList.map((employee, index) => { 

								if (employeeFilter){
									let employeeToken = employee.name;
									if (!employee.name) employeeToken = employee.idemployee;

									if (employeeToken.toLowerCase().indexOf(employeeFilter.toLowerCase()) !== -1){
										return this.renderSelectEmployeeRow(employee, index)
									}
								} else {
									return this.renderSelectEmployeeRow(employee, index)
								}
								
							})}

						</div>

					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Add Employees</button>
					</div>
				</div>
			</div>
		</div>)
    }

    renderSelectEmployeeRow = (employee, index) =>{
    	let {setSelectedEmployee, createdby} = this.props;

    	return(
    		<div className="custom-control custom-checkbox mb-1" key={index} onClick={() => setSelectedEmployee(index, employee.selected)} disabled={employee.email == createdby}>
				<input type="checkbox" className="custom-control-input" checked={employee.selected} />
				<label className="custom-control-label fs-16">{employee.name || employee.idemployee}</label>
			</div>
		)
    }

    renderViewAspect = () => {
    	let {groupsList} = this.props.reportState;
        let {activeEntryIndex, activeGroupIndex} = this.state;
        let group = groupsList[activeGroupIndex];

        if (!group) return null;

        let entry = group.entries[activeEntryIndex];

        if (!entry) return null;

        return(
            <ViewEntryModal
                id="view-aspect"
                entry={entry}
                capitalizedModeName={"Aspect"}
                oppositeModeName={"Measure"}
             >
            <button type="button" data-dismiss="modal" aria-label="Close" className="btn btn-primary">Done</button>
        </ViewEntryModal>)
    }

    renderGetAspectModal = () => {
        let {groupsList} = this.props.reportState;
        let {setSelectedAspect} = this.props;

        return(<div className="modal fade" id="select-aspects" tabindex="-1" role="dialog" aria-labelledby="connect-aspects" aria-hidden="true">
            <div className="modal-dialog modal-long" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="connect-aspects">Select Aspects</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        {groupsList.length == 0 ?
                            <p className="strong fs-14 mb-2">No Aspects to select</p>
                        :<Fragment>
                            <p className="strong fs-14 mb-2">Select Aspects that are relevant to this work:</p>
                            <div className="accordion accordion-light accordion-arrow-right" id="measures-list">
                                {groupsList.map((group, groupIndex) => { return(<div className="card">
                                    <p className="card-title fs-16 strong">
                                        <a className="p-0" data-toggle="collapse" href={"#collapse-ml-"+groupIndex}>{group.name}</a>
                                    </p>

                                    <div id={"collapse-ml-"+groupIndex} className="collapse" data-parent="#measures-list">
                                        <div className="card-body">

                                            {group.entries.map((entry, entryIndex) => {return(
                                                <div className="custom-controls-stacked ml-6">

                                                    <div className="custom-control custom-checkbox mb-1" onClick={(e) => setSelectedAspect(groupIndex, entryIndex, entry.selected)}>
                                                        <input type="checkbox" className="custom-control-input"
                                                          checked={entry.selected}
                                                        />
                                                        <label className="custom-control-label fs-16">{entry.name}</label>
                                                    </div>
                                                </div>
                                            )})}

                                        </div>
                                    </div>
                                </div>)})}
                                </div>
                        </Fragment>}
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Add Aspects</button>
                    </div>
                </div>
            </div>
        </div>)
    }

}

