import { url } from '../constants'
import JwtDecode from 'jwt-decode'

export const ReportsService = {
  saveReportSetup,
  startReport,
  deleteReport,
  getReport,
  getPastReports,
  getRecentReports,
  shareSingleReport,
  shareMultipleReports,
  getNewestReportBySite,
  loadInProgressReport,
  loadReportFromToken,
  acknowledgeReport,
  endReport
}

async function getReport(idreport){
  const resp = await fetch(`${url.backend}/reports/getReport/${idreport}`).then(handleResponse)
  return resp.report
}

async function getRecentReports(idworkgroup, role){
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const email = userDecoded ? userDecoded['email'] : null
  const phone = userDecoded ? userDecoded['custom:phone'] : null
  const resp = await fetch(`${url.backend}/reports/getRecentReports/${idworkgroup}/${role}/${email}/${phone}`).then(handleResponse)
  return resp.reports
}


async function getPastReports(idworkgroup, role){
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const email = userDecoded ? userDecoded['email'] : null
  const phone = userDecoded ? userDecoded['custom:phone'] : null
  const resp = await fetch(`${url.backend}/reports/getPastReports/${idworkgroup}/${role}/${email}/${phone}`).then(handleResponse)
  return resp.reports
}

async function saveReportSetup(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/reports/saveReportSetup/`, requestOptions)
        .then (handleResponse);
}

async function deleteReport(idreport){
  let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  let {idworkgroup} = workUnit;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idworkgroup, idreport})
    };
    return fetch (`${url.backend}/reports/deleteReport/`, requestOptions)
        .then (handleResponse);
}

async function startReport(idreport){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idreport})
    };
    return fetch (`${url.backend}/reports/startReport/`, requestOptions)
        .then (handleResponse);
}

async function endReport(idreport){
  let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  let {idworkgroup} = workUnit;
  console.log("endReport idworkgroup:", idworkgroup);

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idreport, idworkgroup})
    };
    return fetch (`${url.backend}/reports/endReport/`, requestOptions)
        .then (handleResponse);
}

async function loadInProgressReport(idreport){
  const resp = await fetch(`${url.backend}/reports/loadInProgressReport/${idreport}`).then(handleResponse)
  return resp.report
}

async function shareSingleReport(data 
    /*idreport: id of report; 
    recipients: array of email addresses 
    name: name of user who generated report
    unit: name of workunit for which the report was generated*/ ){

  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const name = userDecoded ? userDecoded['name'] : null
  let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));

  data.name = name;
  data.unit = workUnit.name;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/reports/shareSingleReport/`, requestOptions)
        .then (handleResponse);
}

async function shareMultipleReports(data /*
    reportids: array of idreport items; 
    filters: any filters applied to get said id list; 
    recipients; array of email addresses
    name: name of user who generated report
    unit: name of workunit for which the report was generated*/ ){

  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const name = userDecoded ? userDecoded['name'] : null
  let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));

  data.name = name;
  data.unit = workUnit.name;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/reports/shareMultipleReports/`, requestOptions)
        .then (handleResponse);
}

async function getNewestReportBySite(idsite){
  const resp = await fetch(`${url.backend}/reports/getNewestReportBySite/${idsite}`).then(handleResponse)
  return resp.report
}

async function loadReportFromToken(token, signal){
  const resp = await fetch(`${url.backend}/reports/loadReportFromToken/${token}`, {
      method: 'GET',
      signal
    })
    .then(handleResponse)
    .catch((error) => handleError(error, {tokenData: false}))
  return resp.data
}

async function acknowledgeReport(data, signal){
  console.log("Calling acknowledgeReport");

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        signal
    };
    return fetch (`${url.backend}/reports/acknowledgeReport/`, requestOptions)
        .then (handleResponse)
        .catch(handleError)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function handleError(error, toReturn = false){

  console.log("Error:", error);
  return toReturn;

}
