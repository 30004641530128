import React, {Component, useState, useEffect, Fragment} from 'react'
import SuccessPopup from '../../common/Popups'
import ViewEntryModal from '../Modals/ViewEntryModal';
import EditOrAddEntry from '../Modals/EditOrAddEntry';
import EditGroupName from '../Modals/EditGroupName';
import {
    LibraryService
} from '../../../services'
import {Link} from 'react-router-dom'
import panels from '../../../constants/panels';
import DisplayGroup from './DisplayGroup';
import _ from 'lodash';

import {
    etchLibraryTerms,
    getSpecificEntryFromGroupsArray
} from '../../../utils'

export default function ViewLibrary ({unit, mode}) {

    let [entryUpdate, setEntryUpdateDirect] = useState({activeEntry: {identry: 0}, typeOfUpdate: "", trigger: false});
    let [groupUpdate, setGroupUpdateDirect] = useState({activeGroup: "", typeOfUpdate: "", trigger: false});
    let [deleteGroupTrigger, setDeleteGroupTrigger] = useState(false);
    let [deleteEntryTrigger, setDeleteEntryTrigger] = useState(false);
    let [editOrAddEntryTrigger, setEditOrAddEntryTrigger] = useState(false);
    let [loading, setLoading] = useState(true);
    let [groups, setGroups] = useState([]);
    let [modalOpen, setModalOpen] = useState(false);

    const terms = etchLibraryTerms(mode);

    useEffect(() => {
    	refreshGroups();
    }, [unit, (unit !== null ? unit.idworkgroup : undefined)]);

    useEffect(() => {

        console.log("DeleteEntry", "ViewLibrary update triggered with entryUpdate:", entryUpdate, "and groupUpdate,", groupUpdate)

        if (entryUpdate.trigger = true){
            let {activeEntry, typeOfUpdate} = entryUpdate;

            if (typeOfUpdate == "deleteEntry"){
                setDeleteEntryTrigger(true);
            } else if (typeOfUpdate == "editEntry"){
                setEditOrAddEntryTrigger(true);
            }

            resetEntryTrigger();
        } 

        if (groupUpdate.trigger = true){
            let {activeGroup, typeOfUpdate} = groupUpdate;

            if (typeOfUpdate == "addEntry"){
                setEditOrAddEntryTrigger(true);
            } else if (typeOfUpdate == "delete"){
                setDeleteGroupTrigger(true);
            }

            resetGroupTrigger();
        }

    }, [entryUpdate.trigger, groupUpdate.trigger])

    const resetEntryTrigger = () => setEntryUpdateDirect((prev) => {return {...prev, trigger: false}});
    const resetGroupTrigger = () => setGroupUpdateDirect((prev) => {return {...prev, trigger: false}});
    const setEntryUpdate = (updateData) => { setEntryUpdateDirect(() => { console.log("entry update:",  {...updateData, trigger: true}); return {...updateData, trigger: true} })};
    const setGroupUpdate = (updateData) => { setGroupUpdateDirect(() => { return {...updateData, trigger: true} })};


    const refreshGroups = async (suppressLoading) => {
        if (!suppressLoading && !loading) {setLoading(true)}
        let fetchedGroups = [];
        if (unit !== null) {fetchedGroups = await LibraryService.getLibrary(unit.idworkgroup, mode)}

        console.log("refreshGroups groups:", fetchedGroups)

        setGroups(fetchedGroups);
        setLoading(false);
        resetEntryTrigger();
        resetGroupTrigger();

        if (entryUpdate.activeEntry && entryUpdate.activeEntry[terms.mode.identry]){
            let {requestedEntry} = getSpecificEntryFromGroupsArray({
                groups: fetchedGroups,
                idkey: terms.mode.idkey,
                identry: entryUpdate.activeEntry[terms.mode.identry]
            });
            setEntryUpdateDirect((prev) => {
                return {
                    ...prev,
                    activeEntry: requestedEntry
                }
            })
        }
    }

    const deleteSelectedEntry = async () => {
        let identry = entryUpdate.activeEntry[terms.mode.identry];

        console.log("DeleteEntry deleteSelectedEntry firing with identry", identry, "and entryUpdate", entryUpdate);
        let res = await LibraryService.deleteEntry({identry, mode});
        
        if (res.success){
            document.getElementById("close-modal-button").click();
            refreshGroups(true);
        }

        setDeleteEntryTrigger(false);
    }

    const deleteGroup = async (groupName) => {
        let res = await LibraryService.deleteGroup({mode, groupName});
        
        if (res.success){
            refreshGroups(true);
        }

        setDeleteGroupTrigger(false);
    }

    if (unit == null) return null;

    else return(<>
        
            <header className="section-header mt-7 mb-0">
                <h2><strong>{unit.name} Library</strong></h2>

            </header>
            <div className="section pt-1">
                <div className="container">

                    <div className="col-lg-10 mx-auto mb-6 p-0">  
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <Link className={"nav-link px-7 " + (mode == terms.type1.simple ? "active" : null) } to={"/app/workunit-library-"+terms.type1.simplePlural}>{terms.type1.displayPlural}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link px-7 " + (mode == terms.type2.simple ? "active" : null) } to={'/app/workunit-library-'+terms.type2.simplePlural}>{terms.type2.displayPlural}</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 mx-auto border-bottom p-0 m-0 my-lg-4 pt-lg-4 pb-lg-7">
                            <div className="col-lg-10 accordion accordion-light accordion-arrow-right  ml-lg-6" id="practices-1">

                                {loading ? 
                                    `Getting ${terms.mode.displayPlural}...`
                                :
                                    groups.map((group, key) => {
                                        return <DisplayGroup
                                            group={group}
                                            key={key}
                                            mode={mode}
                                            setEntryUpdate={setEntryUpdate}
                                            setGroupUpdate={setGroupUpdate}
                                            groupIndex={key}
                                            terms={terms}
                                        />
                                    })
                                }

                                <div className="row  mt-6 ml-7">
                                    <Link className="btn btn-outline-primary text-capitalize" to={"/app/"+mode+"-group-setup"}>+ Add {terms.mode.display} Group</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <ViewEntryModal
            id="view-practice"
            entry={entryUpdate.activeEntry}
            terms={terms}
            mode={mode}
            footer = {() => {
                return <>
                    <button type="button" 
                        class="btn btn-xs btn-outline-danger mt-1 ml-4" 
                        onClick={() => {
                            console.log("DeleteEntry onClick firing")
                            setEntryUpdate({
                                typeOfUpdate: "deleteEntry",
                                activeEntry: entryUpdate.activeEntry
                            })
                        }}
                    >
                        Delete {terms.mode.display}
                    </button>
                    <button type="button" 
                        class="btn btn-outline-primary mr-4" 
                        data-toggle="modal" data-target="#editAddEntry"
                        onClick={() => {
                            setEntryUpdate({
                                typeOfUpdate: "editEntry",
                                activeEntry: entryUpdate.activeEntry
                            })
                        }}>
                        Edit {terms.mode.display}
                    </button>
                </>
            }}
         / >

        <EditOrAddEntry
            passedActiveEntry = {entryUpdate.activeEntry}
            passedActiveGroup = {groupUpdate.activeGroup || entryUpdate.activeEntry.type}
            mode = {mode}
            trigger={editOrAddEntryTrigger}
            callback = {() => {
                refreshGroups(true);
                setEditOrAddEntryTrigger(false);
            }}
            onClose = {() => {
                if (editOrAddEntryTrigger){
                    setEditOrAddEntryTrigger(false)
                }
            }}
         />
         <EditGroupName
            oldName={groupUpdate.activeGroup}
            mode={mode}
            terms={terms}
            callback={() => refreshGroups(true)}
         />
         <SuccessPopup
            popupId={"deleteEntryPopup"+terms.mode.simple}
            title="Warning!"
            style="warning"
            message={"Really delete this " + terms.mode.display + "?"}
            buttonLabel="Confirm"
            trigger={!!deleteEntryTrigger}
            callback={deleteSelectedEntry}
            useButton = {true}
            disallowClose = {false}
            closeCallback={() => {
                if (deleteEntryTrigger){
                    console.log("DeleteEntry closeCallback firing")
                    setDeleteEntryTrigger(false)
                }
            }}
        />


         {/*<SuccessPopup
            popupId="deleteGroupPopup"
            title="Warning!"
            style="warning"
            message={"Really delete this " + terms.mode.display + " group?"}
            buttonLabel="Confirm"
            trigger={deleteGroupTrigger}
            callback={() =>  {deleteGroup();}}
            useButton = {true}
            disallowClose = {false}
            closeCallback={() => setDeleteGroupTrigger(false)}
            />*/}
        </>)


}