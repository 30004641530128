import React, {Component, Fragment} from 'react'
import {
    WorkGroupsService,
    AllSitesService
} from '../../services'

import {alphanumericSort} from '../../utils'

export default class ImportSites extends Component {

	 constructor(props){
        super(props);

        this.state = {
        	sitesList: null,
        	success: false,
        	idworkgroup: 0,
        	filter: "",
        	submitting: false,
        }
    }

    componentWillMount = async() => {
    	if (this.props && this.props.unit && this.props.unit.idworkgroup){
    		this.loadSites(this.props.unit.idworkgroup);	
    	}
    }

    componentWillReceiveProps = async (newProps) => {
    	console.log("Calling ImportSites componentWillReceiveProps with props " + newProps);
    	let oldIdWorkGroup = (this.props.unit ? this.props.unit.idworkgroup : 0)
    	if (newProps.unit && newProps.unit.idworkgroup != oldIdWorkGroup){
    		this.loadSites(newProps.unit.idworkgroup)
    	}    	
    }

    loadSites = async (idworkgroup) => {
    	let sitesList = await AllSitesService.forCHImport();
    	//sitesList = alphanumericSort(sitesList, "name")
    	this.setState({sitesList, idworkgroup});
    }


    toggleVisible = (e, key) => {
    	let {sitesList} = this.state;
    	sitesList[key].visible = !sitesList[key].visible;
    	this.setState({sitesList});
    }

    submitImport = async () => {
    	let {unit} = this.props;
    	this.setState({loading: true}, async () => {
    		let res = await WorkGroupsService.bulkSetSiteVisibility(this.state);
	        if (res.success == true && unit){
	        	let sitesList = await AllSitesService.forCHImport();
	        	//sitesList = alphanumericSort(sitesList, "name")
	    		this.setState({sitesList, success: true, loading: false});
    		}
		})
    }

    render = () => {
    	let {sitesList, success, loading} = this.state;

	    return(<>
	    	<header class="section-header mb-0">
            	<h2><strong>Import Sites</strong></h2>
            </header>

	    	<div class="row">
				<div class="col-9 border-primary px-6 py-3 mx-auto mt-3">
					<div class="row mt-0 mb-0 ml-0">
						<div class="px-1">
							{sitesList == null ? <p class="mb-0 pb-0">Getting your sites...</p>
								 :
								 (sitesList.length > 0 ? <Fragment>
									<p class="mb-0 pb-0"><i class="fa fa-exclamation text-success strong mr-2"></i>You have sites in other Earth Ideal applications that you can import into Crew Huddle.</p>
									<button class="btn btn-secondary pl-6 mt-4 mb-4" type="button" data-toggle="collapse" data-target="#importsites" aria-expanded="false" aria-controls="importsites">Import Sites</button> 
								</Fragment> :
								<p class="mb-0 pb-0"><i class="fa fa-exclamation text-success strong mr-2"></i>You have <strong>no</strong> sites in other Earth Ideal applications that you can import into Crew Huddle.</p>
							)}
						</div>

						<div class="collapse border-top mt-6 col-12 pb-5" id="importsites">
							<div class="card card-body p-0">
								<div class="row m-0">
									<div class="col-12 mt-3 p-0">
										<label class="strong p-0 m-0 mb-6">Mark the sites your work unit needs to report on:</label>
										<div class="form-group row gap-y">

											<div className="col-lg-12" style={{position: "sticky", top: 0, background: "#fff", zIndex: 100}}>
												<div className="row">
													<div className="col-lg-6 col-12">
								                        <input type="text" className="form-control" placeholder="Filter sites..."
								                            value={this.state.filter}
								                            onChange={(e) => this.setState({filter: e.target.value})} />
						                            </div>
						                            <div className="col-12 mt-6">
														<button class="btn btn-sm btn-primary" onClick={this.submitImport} disabled={loading}>{loading ? <img src="../img/app/photoswipe/preloader.gif" className="mr-2" /> : null}UPDATE SITES</button>
						                            </div>
						                            {success ?  <div className="col-12 alert alert-success mt-6 mr-auto" role="alert">
						                                Sites have been updated! {/*<a href="#" onClick={() => window.location.reload()}>Refresh to continue.</a>*/}
						                            </div> : null}
					                            </div>
						                     </div>

											{sitesList && sitesList.length > 0 ? 
												(sitesList.map((site, key) => { 
													let {filter} = this.state;

													if (!filter || (filter && site.name.toLowerCase().indexOf(filter.toLowerCase()) != -1)){

														return(<div class="col-12 col-lg-6 flexbox ml-4 mr-lg-12 mb-1 p-0" key={key}>
															<div class="col-6 p-0 m-0 mt-1 strong">
																<p class="p-0 m-0">{site.name}</p>
															</div>
															<div class="col-12 mt-2">
																<div class="switch" onClick={e => this.toggleVisible(e, key)}>
																	<input type="checkbox" class="switch-input" checked={site.visible == 1} />
																	<label class="switch-label">Visible</label>
																</div>
															</div>
														</div>)
													} else return null;
												}))
											: null }


										</div>
									</div>

								</div>
							</div>
						</div>

					</div>
	        	</div>
	        </div>
        </>)
	}

}