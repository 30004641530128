import React, {Component} from 'react';
//import { Spinner } from 'react-bootstrap';
import ajax, {Request} from 'superagent';
import {CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe} from 'react-stripe-elements';
import { setStripeToken, setStripeId } from "../../../../../services/userService";
import {haulcheckURl, assuredURL, assuredAPIURL, earthidealURL, url} from '../../../../../constants';
import jwtDecode from 'jwt-decode';


class CardEntryForm extends Component {
       
 constructor(props){
        super(props);

        const userJWT = localStorage.getItem('token');
        const user = jwtDecode(userJWT);

        this.state = {
            custName: '',
            company: '',
            line1: '',
            line2: '',
            stateName: '',
            zip: '',
            cardnumber: '',
            cardtype: '',
            cardexp: '',
            ccv: '',
            disbledCardSubmitButton: false,
            userId: user['cognito:username'],
            userEmail: user.email
        }
    }

    handleChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value,
        })
    }


    createStripeData = async (data) => {
        //this.setState({disbledCardSubmitButton: true})
        // Replace by stripe.createPaymentMethod
        let token = await this.props.stripe.createPaymentMethod('card');
        this.props.displayLoadingWidget();

        console.log("Token:", token);
        if (token.error){
            alert("Error! " + token.error.message);
            return;
        } else {
            const submitData = {
                source : {
                    object: 'card',
                    payment_method: token.paymentMethod.id,
                    name: data.custName,
                    company: data.company,
                    address_line1: data.line1,
                    address_line2: data.line2,
                    address_state: data.stateName,
                    address_zip: data.zip,
                    email: this.state.userEmail,
                },
            }
            ajax.post(`${assuredAPIURL}/stripe/customer/`)
            .send(submitData)
            .then(res => {
                setStripeToken(this.state.userId, token.paymentMethod.id);
                setStripeId(this.state.userId, res.body.customer.id);
                this.props.getStripePaymentMethod(token.paymentMethod.id);
            }).catch(e => {
                console.log(e);
                this.props.hideLoadingWidget();
            });
        }
    }


    render(){
        return (<React.Fragment>
            <div className="form-group row gap-y">
                <label className="col-12 pb-1">Billing Address</label>

                <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                    <input className="form-control form-control-sm" name="custName" type="text" value={this.state.custName} onChange={this.handleChange} placeholder="Cardholder Name" />
                </div>
                <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                    <input className="form-control form-control-sm" name="company" type="text" value={this.state.company} onChange={this.handleChange} placeholder="Company Name" />
                </div>
                <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                    <input className="form-control form-control-sm" name="line1" type="text" value={this.state.line1} onChange={this.handleChange} placeholder="Address Line 1" />
                </div>
                <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                    <input className="form-control form-control-sm" name="line2" type="text" value={this.state.line2} onChange={this.handleChange} placeholder="Address Line 2" />
                </div>
                <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mb-3" placeholder="State">
                    <select name="stateName" className="form-control form-control-sm" onChange={this.handleChange}>
                        <option>State</option>
                        <option>AK</option>
                        <option>AL</option>
                        <option>AR</option>
                        <option>AZ</option>
                        <option>CA</option>
                        <option>CO</option>
                        <option>CT</option>
                        <option>DE</option>
                        <option>FL</option>
                        <option>GA</option>
                        <option>HI</option>
                        <option>IA</option>
                        <option>ID</option>
                        <option>IL</option>
                        <option>IN</option>
                        <option>KS</option>
                        <option>KY</option>
                        <option>LA</option>
                        <option>MA</option>
                        <option>MD</option>
                        <option>ME</option>
                        <option>MI</option>
                        <option>MN</option>
                        <option>MO</option>
                        <option>MS</option>
                        <option>MT</option>
                        <option>NC</option>
                        <option>ND</option>
                        <option>NE</option>
                        <option>NH</option>
                        <option>NJ</option>
                        <option>NM</option>
                        <option>NV</option>
                        <option>NY</option>
                        <option>OH</option>
                        <option>OK</option>
                        <option>OR</option>
                        <option>PA</option>
                        <option>RI</option>
                        <option>SC</option>
                        <option>SD</option>
                        <option>TN</option>
                        <option>TX</option>
                        <option>UT</option>
                        <option>VA</option>
                        <option>VT</option>
                        <option>WA</option>
                        <option>WI</option>
                        <option>WV</option>
                        <option>WY</option>
                    </select>
                </div>
                <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mb-3">
                    <input className="form-control form-control-sm" type="text" placeholder="Zip Code" />
                </div>
            </div>
            <div className="form-group row gap-y mt-4">
                <label className="col-12 pb-1">Credit Card</label>

                <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                    <CardNumberElement className="form-control form-control-sm force-font" type="text" placeholder="Card Number" />
                </div>
                <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mb-3">
                    <CardExpiryElement className="form-control form-control-sm force-font" type="text" placeholder="Exp Date (mm/yy)" />
                </div>
                <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mr-12 mb-3">
                    <CardCvcElement className="form-control form-control-sm force-font" type="text" placeholder="Security Code" />
                </div>
                <div className="col-md-6">
                    <div data-provide="recaptcha" data-enable="#submit-btn"></div>
                    <br />
                    <button id="submit-btn" className="btn btn-primary" type="submit" disabled={this.state.disbledCardSubmitButton} onClick={() => this.createStripeData(this.state)}>Update Card Info</button>

                </div>
            </div>
        </React.Fragment>)
    }
}

export default injectStripe(CardEntryForm);
