import React, {Component, Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import UploadImages from './UploadImages'
import ConnectionModal from "./ConnectionModal"

import {
    LibraryService
} from '../../../services'

import {
    etchLibraryTerms,
    loadEntryImages,
    loadEntryOppositeRelationships,
} from '../../../utils'

export default function EditOrAddEntry({mode, passedActiveGroup, passedActiveEntry, callback}) {

    const smallCharLimit = 140;
    const bigCharLimit = 65535;
    const terms = etchLibraryTerms(mode);
    const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
    const emptyEntryData = {
        name: "",
        description: "",
        connectionData: "",
        pictures: []
    }

    let [editMode, setEditMode] = useState(false);
    let [uploadPhotoSuccessPopup, setUploadPhotoSuccessPopup] = useState(false);
    let [errorUploadingPhotoPopupTrigger, setErrorUploadingPhotoPopupTrigger] = useState(false);
    let [oppositeRelationships, setOppositeRelationships] = useState([]);
    let [freshOppositeLibrary, setFreshOppositeLibrary] = useState([]);
    let [activeEntry, setActiveEntryDirect] = useState(_.cloneDeep(emptyEntryData));

    const setActiveEntry = (key, value) => {
        setActiveEntryDirect((prev) => {
            let newData = _.cloneDeep(prev);
            console.log("EditOrAddEntry setActiveEntry with key", key, "and value",value)

            newData[key] = value;
            console.log("EditOrAddEntry setActiveEntry with newData", newData)

            return newData;
        })
    }

    useEffect(() => {
        loadLibrary(true);
    }, [])

    console.log("EditOrAddEntry activeEntry:", JSON.stringify(activeEntry));

    useEffect(() => {
        let {identry} = terms.mode;
        let passedIdEntry = passedActiveEntry[identry] || 0;
        let currentIdEntry = activeEntry[identry] || 0;

        console.log("EditOrAddEntry activeEntry useEffect firing")
        if (passedIdEntry != currentIdEntry){
            if (passedActiveEntry !== false){
                let cloneActiveEntry = _.cloneDeep(passedActiveEntry)
                let pictures = loadEntryImages(passedActiveEntry);
                loadOppositeRelationships(passedActiveEntry[identry]);
                console.log("value of pictures:", pictures)
                if (pictures){
                    cloneActiveEntry.pictures = pictures;
                } else {
                    cloneActiveEntry.pictures = [];
                }
                setActiveEntryDirect(cloneActiveEntry);
                setEditMode(!!passedIdEntry);
            } else {
                setEmptyState()
            }
        } 

    }, [JSON.stringify(passedActiveEntry)])


    const loadLibrary = async (initOppositeRelationships = false) => {
        let freshLibrary = await LibraryService.getLibrary(unit.idworkgroup, terms.opposite.simple);
        setFreshOppositeLibrary(_.cloneDeep(freshLibrary));

        if (initOppositeRelationships){
            setOppositeRelationships(_.cloneDeep(freshLibrary));
        }
    }

    const loadOppositeRelationships = async (identry) => {
        let relationships = _.cloneDeep(freshOppositeLibrary);
        let loadedOppositeRelations = await LibraryService.getOppositeRelations(identry, mode);

        setOppositeRelationships(() => {
            return loadEntryOppositeRelationships(relationships, loadedOppositeRelations, terms.opposite.identry)
        });
    }

    const setEmptyState = () => {
        setOppositeRelationships(_.cloneDeep(freshOppositeLibrary))
        setActiveEntryDirect(_.cloneDeep(emptyEntryData));
    }


    const submit = async () => {
        let res = null;
        let toSend = _.cloneDeep(activeEntry);
        toSend.type = passedActiveGroup;
        toSend.mode = mode;
        toSend.identry = toSend[terms.mode.identry];

        console.log("EditOrAddEntry submit toSend:", toSend);

        if (editMode){
            res = await LibraryService.editEntry(toSend);
        } else {
            res = await LibraryService.addIndividualEntry(toSend);
        }

        if (res.success){
            //setEmptyState();
            callback();
        }
    }


    return (<>
        {/*<button class="d-none" id={"editAddEntry-open"} data-toggle="modal" data-target="#editAddEntry" type="button"></button>*/}
        <div class="modal fade" style={{zIndex: '2000', backgroundColor: "rgba(84,84,84,0.3)"}} id="editAddEntry" tabindex="-1" role="dialog" aria-labelledby="add-aspect" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <p class="modal-title strong fs-17">{editMode ? "Edit" : "Add"} {terms.mode.displayArticle} {terms.mode.display}</p>
                        <button type="button" id={"editAddEntry-close"} class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="col-12 pl-0 mb-3 ml-lg-4 clearfix">
                            <i class="fa fa-cube float-left mt-4 pr-2 pr-lg-0" aria-hidden="true"></i>
                            <div class="col-11 p-0 pr-lg-6 mr-lg-2 mb-4 ml-lg-4 float-left">
                                <div class="contrast">
                                    <input className={"form-control p-0"}
                                        type="text" 
                                        placeholder={terms.mode.display + " Name"}
                                        value={activeEntry.name}
                                        maxLength={smallCharLimit}
                                        onChange={(e) => setActiveEntry("name", e.target.value)} />
                                </div>
                            </div>
                            <div class="col-12 col-lg-11 float-left">
                                <div class="form-group">
                                    <textarea className={"form-control"}
                                        placeholder={terms.mode.display + " Description"} 
                                        rows="2"
                                        value={activeEntry.description}
                                        maxLength={bigCharLimit}
                                        onChange={(e) => setActiveEntry("description", e.target.value)}></textarea>
                                </div>
                            </div>
                            <div class="col-12 col-lg-11 float-left flexbox">
                                <button type="button" class="btn btn-xs btn-outline-primary mr-3" data-toggle="modal" data-target="#connect-practices">Connect {terms.opposite.displayPlural}</button>
                                <button type="button" class="btn btn-xs btn-secondary" data-toggle="modal" data-target="#add-photos">Add Images</button>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close" disabled={!activeEntry.name} onClick={submit}>{editMode ? "Apply Changes" : "Add " + terms.mode.display } </button>
                    </div>
                </div>
            </div>
            
        </div>

        <ConnectionModal
            oppositeRelationships={oppositeRelationships}
            terms={terms}
            mode={mode}
            nestedMode={true}
            identry={activeEntry ? activeEntry[terms.mode.idkey] : 0}
            passedConnectionData={activeEntry && activeEntry[terms.opposite.identry+"s"] ? activeEntry[terms.opposite.identry+"s"] : ""}
            callback={(connectionData) => {setActiveEntry("connectionData", connectionData)}}
        />

        <UploadImages
            callback={(pictures) => setActiveEntry("pictures", pictures)}
            capitalizedModeName={terms.mode.display}
            identry={activeEntry["id"+terms.mode.simple] || 0}
            pictures={activeEntry.pictures}
            terms={terms}
        />

    </>)
 
}