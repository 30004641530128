import _ from 'lodash'

//This is meant to be used specifically with arrays of objects
//Do not make this function async... this is largely meant for state updates. Use a wrapper if it must be async

export function removeChildFromArray (previousArray, childId, childKey=null, returnChildCallback = null){
  let arr = _.cloneDeep(previousArray);
  console.log("Calling removeChildFromArray with params", previousArray, childId, childKey, returnChildCallback)

  if (childKey !== null){
    console.log("Calling removeChildFromArrayOfObjects based on child");
    arr = removeChildFromArrayOfObjects(arr, childId, childKey, returnChildCallback)
  } else {
    arr = removeChildFromArrayOfPrimitives(arr, childId, returnChildCallback)
  } 

  return arr;
}

function removeChildFromArrayOfObjects(arr, childId, childKey, returnChildCallback){
  let child = _.remove(arr, {[childKey]: childId}); 
  if (returnChildCallback != null && typeof returnChildCallback == 'function') returnChildCallback(child);

  return arr;
}

function removeChildFromArrayOfPrimitives(arr, child, returnChildCallback){
  console.log("Calling removeChildFromArrayOfPrimitives with params",arr,child,returnChildCallback);

  if (arr.length && arr.length > 0 && typeof arr[0] == "string" && typeof child == "number"){
    child = child+"";
  } else if (arr.length && arr.length > 0 && typeof arr[0] == "number" && typeof child == "string"){
    child = parseInt(child);
  }

  let index = arr.indexOf(child);
  if (index !== -1){
    arr.splice(index, 1)
  }

  if (returnChildCallback != null && typeof returnChildCallback == 'function') returnChildCallback(child);

  return arr;
}
