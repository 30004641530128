import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import {earthidealAPIURL} from '../../constants';

import SetupGuideModal from './SetupGuideModal';
import PhoneNumberCompanyName from './PhoneNumberCompanyName';
import ChUserCheck from './ChUserCheck';

export default class Event extends Component {

    constructor(props){

      const userJWT = localStorage.getItem('token');
      const userDecoded = jwtDecode(userJWT);
      let username = userDecoded['cognito:username'];
      let email = userDecoded['email'];

      super(props)

      this.state={
        username,
        email,
        seen: true
      }
  }


  componentWillMount = async () => {
    let {email} = this.state;
    let {eventtype} = this.props;

    console.log("Event mounting with event type " + eventtype);


    if (eventtype){
      ajax.get(`${earthidealAPIURL}/events/getByEventTypeAndUsername/${eventtype}/${email}`)
        .end((error, response) => {
          if (!error && response) {
              this.setState({seen: response.body.eventSeen})
          } else {
              console.log('There was an error fetching', error);
          }
        }
      );
    }
  }

  markAsSeen = async () => {

    let {email} = this.state;
    let {eventtype} = this.props;

    ajax.post(`${earthidealAPIURL}/events/markAsSeen`)
      .send({
        eventtype,
        email
      })
      .end((error, response) => {
        if (!error && response) {
            //this.setState({seen: true})
        } else {
            console.log('There was an error fetching', error);
        }
      }
    );

  }


	render(){
    let {eventtype} = this.props; 
    let {seen} = this.state;

    if (seen == true) return null;
    if (eventtype == "hcSetupGuideModal"){
      return <SetupGuideModal markAsSeen={this.markAsSeen} />
    }
    else if (eventtype == "phoneNumberCompanyName"){
      return <PhoneNumberCompanyName markAsSeen={this.markAsSeen} eiBackend={earthidealAPIURL} />
    }
    if (eventtype == "chUserCheck"){
      return <ChUserCheck markAsSeen={this.markAsSeen} seen={seen} />
    }
    else {
      return null;
    }
	}

}