import React, {useState, useEffect, Fragment} from 'react'
import JwtDecode from 'jwt-decode'
import {
    WorkGroupsService
} from '../../../services'


export default function NewWorkUnit({updateWorkGroup}){

    //Always delete localstorage on this screen to avoid inappropriate data access
    localStorage.removeItem("activeWorkGroup");

    const userJWT = localStorage.getItem('token')
    const userDecoded = JwtDecode(userJWT)
    const email = userDecoded ? userDecoded['email'] : null
    const usersName = userDecoded ? userDecoded['name'] : null

    let [creator, updateCreator] = useState({email, usersName, role: ""})
    let [name, setName] = useState("");
    let [nameInvalid, setNameInvalid] = useState(false)
    let [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    let submit = async () => {
         if (name.length > 100){
            setNameInvalid(true);
            setSubmitButtonDisabled(false);

            return;
        } else {
            setSubmitButtonDisabled(true);
            setNameInvalid(false);
            let workGroup = await WorkGroupsService.createNewWorkGroup({name, creator});
            let idworkgroup = workGroup.idworkgroup
            if (updateWorkGroup != undefined) updateWorkGroup({
                idworkgroup, name,
                role: "admin",
            })
            //setSubmitButtonDisabled(false);
            window.location.reload();
        }
    }


    return <>

        <div class="col-12 col-md-10 px-4 pt-2 pb-4 mx-auto mt-4">
            <div class="card border">
                <div class="card-body">
                    <p class="lead fw-700 card-title border-bottom">Work Unit</p>
                    <div class="col-12 mr-auto alert-light p-1">
                        <div class="col-12 mr-auto alert-light border-bottom py-2">
                            <p class="text-dark fs-16 ml-4 mb-0">You do not belong to a work unit yet.</p>
                            <p class="small-2 text-dark ml-4 mb-3"><em>If you believe that a work unit already exists within your company, ask your admin to send you an invitation.</em></p>
                        </div>
                        <div class="col-12 mr-auto alert-light pt-4">
                            <p class="mb-2">Start a work unit within your company to bring your crew together.</p>
                            {/* <a class="fs-12 fw-600" href="#">Learn more <i class="fa fa-angle-right pl-1"></i></a> */}
                        </div>
                    </div>
                    <div class="col-12 col-lg-11">
                        <div class="form-group mb-2">
                            <div class="col-lg-6 p-0 pb-4 float-left">
                                <label class="strong p-0 m-0">Name of Work Unit:</label>
                                <input class="form-control form-control-sm py-0 float-left fs-13" type="text" placeholder="Company or Division Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />

                            </div>
                            <button class="btn btn-sm btn-primary float-left mt-md-6 mx-3 ml-auto ml-md-4" 
                                onClick={submit} 
                                disabled={submitButtonDisabled}>
                                {submitButtonDisabled ? <img src="../img/app/photoswipe/preloader.gif" className="mr-2" /> : null}
                                Create Work Unit
                            </button>
                        </div>
                       
                        {nameInvalid ? <label class="text-warning">Name is too long!</label> : null}
                    </div>
                </div>
            </div>
        </div>

    </>

}