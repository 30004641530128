import _ from 'lodash';

export function etchLibraryTerms(mode = undefined){
	let type1 = {
		simple: "aspect",
		simplePlural: "aspects",
		simpleArticle: "an ",

		display: "Aspect",
		displayPlural: "Aspects",
		displayArticle: "an ",

		identry: 'idaspect',
		idkey: 'idaspect', //Duplicate for the sake of semantic code
	}


	let type2 = {
		simple: "measure",
		simplePlural: "measures",
		simpleArticle: "an ",

		display: "Measures",
		displayPlural: "Measure",
		displayArticle: "a ",

		identry: 'idmeasure',
		idkey: 'idmeasure', //Duplicate for the sake of semantic code

	}


	let library = {
		display: "AspectMeasures",
		simple: "aspectmeasures"
	}



	let originalPracticeTerms = _.cloneDeep(type1);
	type1.oppositeTerms = _.cloneDeep(type2);
	type2.oppositeTerms = _.cloneDeep(originalPracticeTerms);

	let toReturn = {type1, type2, library}

	if (mode === "aspect"){
		toReturn.opposite = _.cloneDeep(type2);
		toReturn.mode = _.cloneDeep(type1);
	} else if (mode === "measure"){
		toReturn.opposite = _.cloneDeep(type1);
		toReturn.mode = _.cloneDeep(type2);
	}

	return toReturn;
}