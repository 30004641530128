import React, {useState, Fragment, useEffect} from 'react'
import ManageWorkUnit from './ManageWorkUnit'
import NewWorkUnit from './NewWorkUnit'
import {
    WorkGroupsService
} from '../../../services'

export default function WorkUnitCrew({unit, updateWorkGroup}){

    return(<>
        <header class="section-header mb-0">
            <h2><strong>Work Unit Crew</strong></h2>
        </header>
                

        {unit && unit.idworkgroup && (unit.deleted == 0 || unit.deleted == "0") ?
            
            <ManageWorkUnit
                updateWorkGroup={updateWorkGroup}
                unit={unit}
            />
            
            :

            <NewWorkUnit 
                updateWorkGroup={updateWorkGroup}
            />
        }
                        

    </>)

}

