import React, {Fragment} from 'react'
import PastReports from './PastReports';
import RecentReports from './RecentReports';

export default function HuddleReports(props){

    return(<>

        <header class="section-header mb-7">
            <h2><strong>Huddle Reports</strong></h2>
        </header>

        <RecentReports {...props} />

        <PastReports {...props} />
        
   </>)

}