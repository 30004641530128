import { url } from '../constants'
import JwtDecode from 'jwt-decode'

export const LibraryService = {
  getWorkgroupHasLibrary,
  addGroupsToLibrary,
  addIndividualEntry,
  editEntry,
  deleteEntry,
  getLibrary,
  getMeasuresForAspectList,
  getOppositeRelations,
  deleteGroup,
  renameGroup
}

async function getWorkgroupHasLibrary(idworkgroup){
  const resp = await fetch(`${url.backend}/library/getWorkgroupHasLibrary/${idworkgroup}`).then(handleResponse)
  return resp.hasLibrary
}

async function getLibrary(idworkgroup, mode){
  const resp = await fetch(`${url.backend}/library/getLibrary/${idworkgroup}/${mode}`).then(handleResponse)
  return resp.groups
}

async function addGroupsToLibrary(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/addGroupsToLibrary/`, requestOptions)
        .then (handleResponse);
}

async function addIndividualEntry(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/addIndividualEntry/`, requestOptions)
        .then (handleResponse);
}

async function editEntry(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/editEntry/`, requestOptions)
        .then (handleResponse);
}

async function deleteEntry(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;
  
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/deleteEntry/`, requestOptions)
        .then (handleResponse);
}

async function getMeasuresForAspectList(aspectList){
  if (aspectList.length == 0) aspectList="0"
  const resp = await fetch(`${url.backend}/library/getMeasuresForAspectList/${aspectList}`).then(handleResponse)
  return resp.groups
}

async function getOppositeRelations(identry, mode){
  const resp = await fetch(`${url.backend}/library/getOppositeRelations/${identry}/${mode}`).then(handleResponse)
  return resp.oppositeRelations
}

async function deleteGroup(data){
  let {idworkgroup} = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = idworkgroup;

  console.log("deleteGroup data:", data);

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/deleteGroup/`, requestOptions)
        .then (handleResponse);
}

async function renameGroup(data){
  let {idworkgroup} = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = idworkgroup;

  console.log("renameGroup data:", data);

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/renameGroup/`, requestOptions)
        .then (handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
