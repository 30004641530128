import React, {Fragment, useEffect, useState} from 'react'
import moment from 'moment';
import {
   StakeholdersService,
  // PublishSubscribe,
   WorkGroupsService
} from '../../../services'

import {alphanumericSort} from '../../../utils'

export default function AddStakeholderContacts(props){

	let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));

    let stakeHoldersToAddObj = {
        name: "",
        email: "",
        valid_email: true,
        company: "",
        siteids: [],
        valid_siteids: true,
        idworkgroup: workUnit.idworkgroup
    }

    let [stakeholdersToAdd, setStakeholdersToAdd] = useState([]);
    let [existingStakeholdersList, setExistingStakeholdersList] = useState([])
    let [workUnitSites, setWorkUnitSites] = useState([])
    let [submitting, setSubmitting] = useState(false);
    let [submitted, setSubmitted] = useState(false);

    let initializeComponent = async () => {
        let sites = await WorkGroupsService.getWorkGroupSites(workUnit.idworkgroup);
        setWorkUnitSites(alphanumericSort(sites, "name"));
        setStakeholdersToAdd([JSON.parse(JSON.stringify(stakeHoldersToAddObj))])
    }

    useEffect(() => {
        initializeComponent();
       // PublishSubscribe.quickSubscribe("")
    }, [])


    let handleChange = (index, key, value) => {
        let this_stakeholdersToAdd = JSON.parse(JSON.stringify(stakeholdersToAdd));
        this_stakeholdersToAdd[index][key] = value;
        setStakeholdersToAdd(this_stakeholdersToAdd);
    }

    let handleSitesChange = (index, target) => {

    	console.log("target in handleSitesChange:", target.options);
    	let sites = [];

    	for (var i = 0; i < target.options.length; i++){
    		let {value, selected} = target.options[i];
    		if (selected && value != 0){
    			sites.push(value);
    		}
    	}

    	console.log("Sites in handleSitesChange:", sites);
    	handleChange(index, "siteids", sites);
    }

    let increaseStakeholdersToAddLength = () => {
        let this_stakeholdersToAdd = JSON.parse(JSON.stringify(stakeholdersToAdd));
        this_stakeholdersToAdd.push(JSON.parse(JSON.stringify(stakeHoldersToAddObj)));
        setStakeholdersToAdd(this_stakeholdersToAdd);
    }

    let removeStakeholder = (key) => {
        let this_stakeholdersToAdd = JSON.parse(JSON.stringify(stakeholdersToAdd));
        this_stakeholdersToAdd.splice(key, 1);
        setStakeholdersToAdd(this_stakeholdersToAdd);
    }

    let submit = async () => {
    	setSubmitting(true);

    	let this_stakeholdersToAdd = JSON.parse(JSON.stringify(stakeholdersToAdd));

    	let anyEmailsInvalid = false;
    	let emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let anySitesInvalid = false;

    	this_stakeholdersToAdd.map((stakeholder) => {

    		if (!emailRegexp.test(stakeholder.email)){
    			anyEmailsInvalid = true;
    			stakeholder.valid_email = false;
    		} else {
    			stakeholder.valid_email = true;
    		}

            if (stakeholder.siteids.length == 0){
                anySitesInvalid = true;
                stakeholder.valid_siteids = false;
            } else {
                stakeholder.valid_siteids = true;
            }

    	});

    	setStakeholdersToAdd(this_stakeholdersToAdd);

    	if (anyEmailsInvalid || anySitesInvalid){
    		setSubmitting(false);

    	} else {
    		await StakeholdersService.saveNewStakeholders(this_stakeholdersToAdd);
    		setSubmitting(false);
    		setStakeholdersToAdd([JSON.parse(JSON.stringify(stakeHoldersToAddObj))])
    		setSubmitted(true);
    	}
    	
    }

	return(<>
		<div class="">
            <div class="col-12 p-0 mb-6 border-bottom">
                <label class="p-0 m-0 strong">Add stakeholders for sites in your work unit:</label>
            </div>

            {stakeholdersToAdd.map(({name, email, company, valid_email, valid_siteids}, key, array) => {
                return <div class="row px-4 mt-2 mb-7">

                    <div class="col-12 twonhalf-col p-0 mr-2 mb-4">
                        <div class="">
                            <input 
                                class="form-control" 
                                type="text" 
                                placeholder="Full Name"
                                value={name}
                                onChange={({target}) => handleChange(key, 'name', target.value)} />
                        </div>
                    </div>
                    <div class="col-12 twonhalf-col p-0 mr-2 mb-4">
                        <div class="">
                            <input 
                            	class="form-control" 
                            	type="text" 
                            	placeholder="Company Name"
                            	value={company}
                                onChange={({target}) => handleChange(key, 'company', target.value)} />
                        </div>
                    </div>
                    <div class="col-12 twonhalf-col p-0 mr-2 mb-4">
                        <div class="">
                            <input 
                            	class={"form-control " + (valid_email ? "" : "is-invalid")}
                            	type="text" 
                            	placeholder="Email Address"
                            	value={email}
                                onChange={({target}) => handleChange(key, 'email', target.value)} />
                        </div>
                        {!valid_email ? <span class="text-danger">Email address is invalid</span> : null}
                    </div>
                    <div class="col-12 col-lg-3 form-group p-0 mr-2 mt-0 mb-4">
                        <select class={"form-control form-control-sm form-select h-100 " + (valid_siteids ? "" : "is-invalid")} multiple aria-label="multiple select example" 
                        	onChange={({target}) => handleSitesChange(key, target)}

                    	>
                            <option selected value={0}>Select one or more</option>
                            {
                                workUnitSites.length ? 
                                    workUnitSites.map(({name, idsite}, index) => {
                                        return <option value={idsite} key={"siteopt-"+idsite+"-"+key}>{name}</option>
                                    })
                                : null
                            }
                        </select>
                        {!valid_siteids ? <span class="text-danger">Select at least one site</span> : null}
                    </div>

                    <div class="p-0 mr-2 mt-0 mb-4">
                        {array.length > 1 ? 
                            <a class="small item-remove text-dark float-right mt-1 ml-4" href="#" onClick={() => removeStakeholder(key)}><i class="ti-close"></i></a>
                        : null}
                    </div>
                    
                </div>
            })}
            
            <div class="mt-lg-6 d-flex">

                    <button class="btn btn-circle btn-outline-secondary my-auto px-4" onClick={increaseStakeholdersToAddLength}><i class="fa fa-plus fw-400"></i></button>

                    <button class="btn btn-sm btn-primary my-auto ml-auto float-right" onClick={submit} disabled={submitting || stakeholdersToAdd.length == 0}>
                    	{submitting ? <img src="../img/app/photoswipe/preloader.gif" /> : null}
                    	Add Contacts
                	</button>
            </div>

        </div>

	</>)

}