import { url, earthidealAPIURL } from '../constants'
import JwtDecode from 'jwt-decode'

export const ApiService = {
  getResources,
  getUniqueResources,
  getUniqueResourceTypes,
  getQuantities,
  getDiscoveryModes,
  getJWTUsername,
  getJWTEmail,
  getJWTInfo,
  sendInviteEmails,
  checkEmailsExist,
  getEveryUserMaterial
}

async function getResources() {
  const resp = await fetch(`${url.backend}/api/getresources`).then(handleResponse)
  return resp.resourcesList
}

async function getUniqueResources() {
  const resp = await fetch(`${url.backend}/api/getuniqueresources`).then(handleResponse)
  return resp.uniqueResourcesList
}

async function getUniqueResourceTypes() {
  const resp = await fetch(`${url.backend}/api/getuniqueresourcetypes`).then(handleResponse)
  return resp.uniqueResourceTypesList
}

async function getQuantities() {
  const resp = await fetch(`${url.backend}/api/getquantities`).then(handleResponse)
  return resp.quantitiesList
}

async function getDiscoveryModes() {
  const resp = await fetch(`${url.backend}/api/getdiscoverymodes`).then(handleResponse)
  return resp.discoveryModesList
}

function getJWTUsername() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null

  if (username === null) {
    localStorage.removeItem('token')
    window.location.href = `${url.frontend}/login`
    return
  } else {
    return username
  }
}

function getJWTEmail() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const email = userDecoded ? userDecoded['email'] : null

  if (email === null) {
    localStorage.removeItem('token')
    window.location.href = `${url.frontend}/login`
    return
  } else {
    return email
  }
}

function getJWTInfo() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['username'] : null

  if (username === null) {
    localStorage.removeItem('token')
    window.location.href = `${url.frontend}/login`
    return
  } else {
    return userDecoded
  }
}

async function sendInviteEmails(data){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  }
  const resp = await fetch(`${earthidealAPIURL}/users/sendInviteEmails`, requestOptions)
      .then(handleResponse)

  return resp.success
}

async function checkEmailsExist(emailsList) {
  const resp = await fetch(`${earthidealAPIURL}/users/checkEmailsExist/${emailsList}`).then(handleResponse)
  return resp.usersExist
}

async function getEveryUserMaterial(email) {
  const resp = await fetch(`${earthidealAPIURL}/users/getEveryUserMaterial/${email}`).then(handleResponse)
  return resp.materials
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
