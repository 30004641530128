import React, {Fragment, useEffect, useState} from 'react'
import SuccessPopup from '../../common/Popups'
import {
   StakeholdersService,
   PublishSubscribe,
   //WorkGroupsService
} from '../../../services'

import {alphanumericSort, parseIfJSONString} from '../../../utils'


export default function ViewStakeholderContacts(props){

	let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));

	const [stakeholdersList, setStakeholdersList] = useState([]);
	const [popupTrigger, setPopupTrigger] = useState(0);


	useEffect(() => {
        initializeComponent();
    }, [])

    let initializeComponent = async () => {
		console.log("Calling initializeComponent for ViewStakeholderContacts");
        refreshStakeholders()

       PublishSubscribe.quickSubscribe(
	    	"People/ViewStakeholderContacts",
	    	"CH/stakeholders",
	    	handleMessage,
	    	{idworkgroup: workUnit.idworkgroup},
   		);
    }

    //Todo someday: Change this so that the messages are used to update the list instead of the endpoint
    let refreshStakeholders = async () => {
		let existingStakeholders = await StakeholdersService.getStakeholdersByWorkGroup(workUnit.idworkgroup);
        setStakeholdersList(existingStakeholders);
    }

    let handleMessage = (message, objs) => {
    	message = parseIfJSONString(message);
    	
    	if (message.type == "new-stakeholders" || message.type == "deleted-stakeholder"){
    		refreshStakeholders();
    	}
    }

    /*let addStakeholderToList = (stakeholder) => {
    	let this_stakeholdersList = JSON.parse(JSON.stringify(stakeholdersList));
    	this_stakeholdersList.push(stakeholder);
    	setStakeholdersList(this_stakeholdersList);
    }

    let removeStakeholderFromList = (sent_idstakeholder) => {
    	console.log("removeStakeholderFromList param", sent_idstakeholder);
    	console.log("removeStakeholderFromList val for stakeholdersList", stakeholdersList);
    	let this_stakeholdersList = JSON.parse(JSON.stringify(stakeholdersList));
    	console.log("removeStakeholderFromList this_stakeholdersList before loop", this_stakeholdersList)
    	this_stakeholdersList = this_stakeholdersList.filter(({idstakeholder}) => {return sent_idstakeholder != idstakeholder});
    	console.log("removeStakeholderFromList this_stakeholdersList after loop", this_stakeholdersList)
    	setStakeholdersList(this_stakeholdersList);
    }*/

    let openDeleteStakeholderPopup = (idstakeholder) => {
    	console.log("Calling openDeleteStakeholderPopup with idstakeholder " + idstakeholder );
    	setPopupTrigger(idstakeholder) //Storing the stakeholder in the popup trigger to avoid unecessary multiple state refreshes
    }

    let deleteStakeholder = async () => {
    	console.log("Value of stakeholderToDelete:", popupTrigger);
    	await StakeholdersService.deleteStakeholder({idstakeholder: popupTrigger, idworkgroup: workUnit.idworkgroup});
    	setPopupTrigger(false);
    }

	return(<>

		<p class="lead fw-700 card-title">Work Unit Stakeholders</p>
        <p>
            Go to Past Reports to share reports with these stakeholders.
            {/* <a class="fs-12 fw-600" href="#">Learn more <i class="fa fa-angle-right pl-1"></i></a>*/}
        </p>
        <hr class="my-2" />

        <div class="mt-4">
            <div class="col-12 p-0">
                <label class="strong">Contacts entered for sites in this work unit:</label>
            </div>

        	{stakeholdersList.length ? stakeholdersList.map(({name, company, email, idstakeholder, sites}, key) => {
	            return <div class="row px-4 mt-2 pt-4 pb-4 border-top clearfix" key={idstakeholder}>
	                <div class="col-9 twonhalf-col p-0">
	                    <strong class="text-dark">{name}</strong>
	                </div>
	                <div class="col-9 twonhalf-col p-0 float-left">
	                    <strong class="small-2 fw-100">{company}</strong>
	                </div> 
	                <div class="col-10 col-md-3 p-0 mr-2 float-left">
	                    <span class="small-2 fw-100">{email}</span>
	                </div>
	                <div class="col-9 twonhalf-col p-0 mr-2 float-left">
	                	{sites && sites.length ? alphanumericSort(sites, "name").map((site) => {
	                		return (site ? <div class="small-2 fw-100">{site.name}</div> : null)
	                	}) : null}
	                </div>
	                <div class="col-2 col-md-1 form-group p-0 mr-2 mt-0 float-right">
	                    <a class="small item-remove text-light float-right clearfix mt-1" href="#" onClick={() => openDeleteStakeholderPopup(idstakeholder)}><i class="ti ti-trash"></i></a>
	                </div>
	            </div>
       		}) : null}

        </div>

        <hr />

        <SuccessPopup
        	trigger={!!popupTrigger}
        	popupId="delete-stakeholder-popup"
        	style="warning"
        	useButton={true}
        	buttonLabel="Yes"
        	title={"Delete Stakeholder"}
        	message={"Really delete stakeholder? This cannot be undone"}
        	callback={deleteStakeholder}
        />

	</>)
}