import React, {useState, useEffect} from 'react';
import {
    LibraryService
} from '../../../services'

export default function EditGroupName({mode, oldName, capitalizedModeName, callback}){

    let [newName, setNewName] = useState(oldName);

    useEffect(() => {
        setNewName(oldName)
    }, [oldName])

    let submit = async () => {
        await LibraryService.renameGroup({oldName, newName, mode});
        if (callback) callback();
        let close = document.getElementById("edit-group-name-close");
        if (close) close.click();
    }

    return <div class="modal fade show" id="edit-group-name" tabindex="-1" role="dialog" aria-labelledby="edit-practice" aria-modal="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <p class="modal-title strong fs-17" id="add-practice">Edit {capitalizedModeName} Group Name</p>
                    <button id="edit-group-name-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div class="modal-body">

                    <div class="col-12 pl-0 mb-3 ml-4 clearfix">
                        <i class="fa fa-cubes float-left mt-4" aria-hidden="true"></i>
                        <div class="col-11 p-0 pr-6 mr-2 mb-4 ml-4 float-left">
                            <div class="contrast">
                                <input class="form-control p-0" type="text" placeholder={oldName} value={newName} onChange={(e) => setNewName(e.target.value)} />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" onClick={submit}>Save changes</button>
                </div>
            </div>
        </div>
    </div>
}