import React from "react";
import EnterEntry from "./EnterEntry";


export default function EnterGroup ({group, mode, terms,  groupIndex, smallCharLimit, bigCharLimit, updateGroup, updateEntry, updateEntryIndex, addEntry}) {

    return(<div className="row">
        <div className="col-lg-10 mx-auto border-top my-4 pt-4">
            <div className="col-12 mt-2 mb-6 border-bottom clearfix">
                <i className="fa fa-cubes float-left mt-4" aria-hidden="true"></i>
                <div className="col-11 col-lg-5 p-0 pr-6 mr-2 mb-6 float-left">
                    <div className="contrast">
                        <input 
                        className={"form-control strong fs-16 p-0 ml-2 pl-2"}
                        type="text" 
                        placeholder={terms.mode.display + " Group Name"}
                        value={group ? group.name : ""}
                        maxLength={smallCharLimit}
                        onChange={(e) => updateGroup("name", e.target.value, groupIndex)} />
                    </div>
                </div>
            </div>

            {group.entries.map((entry, entryIndex) => 
            	<EnterEntry
                    key={entryIndex}
            		entry={entry}
            		entryIndex={entryIndex}
                    groupIndex={groupIndex}
            		mode={mode}
            		terms={terms}
            		updateEntry={updateEntry}
                    updateEntryIndex={updateEntryIndex}
                    smallCharLimit={smallCharLimit}
                    bigCharLimit={bigCharLimit}
            	/>
            )}

            <div className="col-12 pb-5 mt-4 ml-6">
                <button className="btn btn-sm btn-outline-secondary fs-14 px-5" onClick={() => addEntry(groupIndex)}>
                    <i className="fa fa-plus mr-2 fw-400"></i><i className="fa fa-cube fw-400" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div> )

}