import React, {Component, Fragment} from 'react'
import SuccessPopup from '../../common/Popups';
import { url } from '../../../constants'
import request from 'superagent';
import Compress from 'compress.js';
import _ from 'lodash';

export default class UploadImages extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    handleFileUpload = (files) => {
    	let {callback} = this.props;
        console.log("handleFileUpload called with files params ", files);

        let compress = new Compress();
        let filesArray = [];
        for (var i = 0; i < files.length; i++){
        	filesArray.push(files.item(i));
        }

        compress.compress(filesArray, {
            size: 4, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 720, // the max width of the output image, defaults to 1920px
            maxHeight: 720, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
        }).then((data) => {
            //alert("handleTempFileUpload called");
            console.log("data:", data);

            let files = [];
            let formData = new FormData();

            for (var i = 0; i < data.length; i++){
	            let img1 = data[i]
	            let base64str = img1.data
	            let imgExt = img1.ext
	            let file = Compress.convertBase64ToFile(base64str, imgExt)
	            formData.append('picture', file);
            }

            console.log(files);
        
			request
			.post(`${url.backend}/library/uploadPhotos`)
			.send(formData)
			.set('Accept', 'application/json')
			.then(response => {
			   // this.setState({uploadPopupTrigger: true});
			    console.log("img upload response:");
			    console.log(response);

			    //Add image to group array
			    let {pictures} = this.props;
			    pictures = JSON.parse(JSON.stringify(pictures)); //Deep copy so I can make updates
			    let fileNames = response.body.fileNames;

			    console.log("Pictures before push:", pictures);
			    console.log("filenames:", fileNames)
			    pictures.push.apply(pictures, fileNames);
			    console.log("Pictures after push:", pictures)


			    callback(pictures);

			    this.setState({uploadPhotoSuccessPopup: true});

			    //document.getElementById("close-upload-images-modal").click()
			})
			.catch((error) => {
			    console.log(error)
			    this.setState({errorUploadingPhotoPopupTrigger: true});                    
			});

        });     
    }


	render = () => {
        let {capitalizedModeName, pictures} = this.props;
        return(<Fragment>

        	<div className="modal fade" style={{zIndex: '2000', backgroundColor: "rgba(84,84,84,0.3)"}} id="add-photos" tabindex="-1" role="dialog">
	            <div className="modal-dialog" role="document">
	                <div className="modal-content">

	                    <div className="modal-body">
	                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-upload-images-modal">
	                            <span aria-hidden="true">&times;</span>
	                        </button>
	                        <p className="strong fs-14 mb-2">Add images to illustrate this {capitalizedModeName}:</p>

	                        <div class="row">
	                        	<div className="custom-file col-4 mx-auto my-3">
                                    <input type="file" name="image" className="custom-file-input ml-2" id="customFile" accept=".png, .jpg, .jpeg" multiple
		                                onChange={(e) => {
		                                this.handleFileUpload(e.target.files);
		                            }} />
                                    <label className={"custom-file-label2 btn btn-circle p-4 mb-0 ml-6"} 
                                    for="customFileTruck">
                                        <i className="lead fa fa-camera"></i>
                                    </label>
                                </div> 
                            </div>

                            {!_.isEmpty(pictures) && pictures.map ? <div class="row">
	                            <div class="col-6 col-sm-3 px-1">
	                            	{pictures.map((image, index) => this.renderImage(image, index, 0, 4))}
	                            </div>
	                            <div class="col-6 col-sm-3 px-1">
	                                {pictures.map((image, index) => this.renderImage(image, index, 1, 4))}
	                            </div>
	                            <div class="col-6 col-sm-3 px-1">
	                                {pictures.map((image, index) => this.renderImage(image, index, 2, 4))}
	                            </div>
	                            <div class="col-6 col-sm-3 px-1">
	                                {pictures.map((image, index) => this.renderImage(image, index, 3, 4))}
	                            </div>
	                         </div> : null}
	                    </div>

	                    <div className="modal-footer">
		                	<button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Done</button>
		                </div>

	                </div>

	            </div>
	        </div>

	        <SuccessPopup
	            popupId="truckIdErrorSettingPhoto"
	            title="Oh no!"
	            message="Error Adding Images!"
	            trigger={this.state.errorUploadingPhotoPopupTrigger}
	            useButton={true}
	            style="warning"
	            callback={() =>  this.setState({errorUploadingPhotoPopupTrigger: false})}
	        />

	        <SuccessPopup
	            popupId="truckIdPhotoUploadSuccess"
	            title="Success!"
	            message={capitalizedModeName + " Images Added!"}
	            trigger={this.state.uploadPhotoSuccessPopup}
	            callback={() =>  {this.setState({uploadPhotoSuccessPopup: false})}}
	        />

        </Fragment>)
    }

    renderImage = (image, index, rowNum, totalRows) => {
        if (index % totalRows == rowNum){
            return <img src={url.backend + "/images/"+ this.props.terms.library.simple +"/" + image} className="img-fluid img-thumbnail p-3 mb-2" data-provide="lightbox" alt="..." />
        } else {
            return null;
        }
    }

}