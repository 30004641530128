import React, {Component} from 'react';
import jwtDecode from 'jwt-decode';
import ajax from 'superagent';
import { Auth } from 'aws-amplify';
import SuccessPopup from '../common/Popups';
import {updateUserService} from '../../services/updateUserService';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2'
import "./style.css"

export default class UserAccountInfo extends Component {

    constructor(props){      
        super(props);
        this.state = this.getDefaultState();
    }

    getDefaultState = () => {

       const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);

        return {
            userInfo: userDecoded, userJWT,
            username: userDecoded['cognito:username'],
            showEditPhoneNumber: false,
            showPhonePopup: false,
            initPhone: "", //phone + "",
            phone: "",
            companyname: "",

            showEditPhoneNumber: false,
            showEditCompanyName: false,
            showEditName: false,

            showPhoneSuccessPopup: false,
            showCompanyNameSuccessPopup: false,
            showNameSuccessPopup: false,

            showCompanyNameInvalidPopup: false,

            loadingPhone: false,
            loadingCompanyName: false,
            loadingName: false,
          
        }

    }

    componentWillMount = () => {
        let {companyname, phone, username, userJWT} = this.state;
        let {eiBackend} = this.props;

        this.setState(this.getDefaultState() , () => {

            ajax.get(`${eiBackend}/users/mycontactinfo/${userJWT}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log("user info",response.body.info)
                    let {phonenumber, companyname, name, username, email} = response.body.info;

                    this.initialValues = {
                        phone: phonenumber,
                        name,
                        companyname
                    }

                    this.setState({
                        phone: phonenumber, 
                        companyname,
                        name
                    });

                } else {
                    console.log('There was an error fetching', error);
                }
            })

        })
        
    }

    updateUserAttribute = async ({awsRequestBody, backendRequestBody, serviceFunction, newState}) => {

        let currentCognitoUser = await Auth.currentUserPoolUser();
        console.log("currentCognitoUser", currentCognitoUser);

        let result = await Auth.updateUserAttributes(currentCognitoUser, awsRequestBody)

        if (result === "SUCCESS"){
            await serviceFunction(backendRequestBody);
            this.setState(newState);

            console.log("current user from aws:", currentCognitoUser)
        }

        updateUserService.refreshJWTToken();
    }


    updatePhoneNumber = async (e) => {
        e.preventDefault();
        let {phone, username} = this.state;

        this.updateUserAttribute({
            awsRequestBody: {'custom:phone': phone}, 
            backendRequestBody: {username, phone},
            serviceFunction: updateUserService.updatePhoneNumber,
            newState: {showPhoneSuccessPopup: true, showEditPhoneNumber: false}
        })

        this.initialValues.phone = phone;       
    }

    updateCompanyName = async (e) => {
        e.preventDefault();
        let {companyname, username} = this.state;

        let isUrlTest = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

        if (!companyname.match(isUrlTest)) {
            this.setState({showCompanyNameInvalidPopup: true})
        } else {
            companyname = companyname.replace(/^\/\/|^.*?:(\/\/)?/, '');

            this.updateUserAttribute({
                awsRequestBody: {'custom:company': companyname}, 
                backendRequestBody: {companyname, username},
                serviceFunction: updateUserService.updateCompanyName,
                newState: {showCompanyNameSuccessPopup: true, showEditCompanyName: false}
            })      
        }
  
    }

    updateName = async (e) => {
        e.preventDefault();
        let {name, username} = this.state;

        name = name.replace(/^\/\/|^.*?:(\/\/)?/, '');

        this.updateUserAttribute({
            awsRequestBody: {name}, 
            backendRequestBody: {name, username},
            serviceFunction: updateUserService.updateName,
            newState: {showNameSuccessPopup: true, showEditName: false}
        })        
    }

    togglePrivate = async (e) => {
        let {eiBackend} = this.props;

        e.preventDefault();
        console.log("Calling togglePrivate")

        let {userInfo, phoneIsPrivate} = this.state;

        let isPrivate = (phoneIsPrivate ? '0' : '1');

        console.log("togglePrivate isPrivate", isPrivate);
        let currentCognitoUser = await Auth.currentUserPoolUser();
        console.log("currentCognitoUser", currentCognitoUser);

        let result = await Auth.updateUserAttributes(
            currentCognitoUser,
            {
                'custom:private': isPrivate
            }
        )

        console.log("togglePrivate AWS update result: ", result);

        if (result === "SUCCESS"){

            ajax.post(`${eiBackend}/users/update/contactinfo`)
            .send({
                username: userInfo['cognito:username'], 
                isPrivate
            })
            .end((error, response) => {
                if (!error && response) {
                    this.setState({showPopup: true, phoneIsPrivate: !phoneIsPrivate}/*, () => this.resetJwtToken({"custom:private": !phoneIsPrivate})*/);
                } else {
                console.log('There was an error fetching', error);
                }
            });
        }     
    }

    formatPhoneNumber = (entry = '') => {
        if (entry.length < 10) return entry;
        
        const match = entry
            .replace(/\D+/g, '').replace(/^1/, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
        const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
        const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
        const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''    
        return `${part1}${part2}${part3}`
    }

    displayName = () => {
        return <div className="col-xs-auto col-sm-7">
            Name: { this.state.name }
            <small 
                className="cursor float-right" 
                onClick={() => this.setState({showEditName: true})}
            >
                ✏️ <a href="#"> Edit</a>
            </small>
        </div>
    }

    displayEmail = () => {
        let {userInfo} = this.state;
        return <div className="col-xs-auto col-sm-7">
            Email: { userInfo.email }
        </div>
    }

    displayUsername = () => {
        let {userInfo} = this.state;
        return <div className="col-xs-auto col-sm-7">
            Username: { userInfo["cognito:username"] }
        </div>
    }

    displayPhoneNumber = () => {
        return <div className="col-xs-auto col-sm-7">
            Mobile Phone Number: { this.formatPhoneNumber(this.state.phone) }
            <small 
                className="cursor float-right" 
                onClick={() => this.setState({showEditPhoneNumber: true})}
            >
                ✏️ <a href="#"> Edit</a>
            </small>
        </div>
    }

    displayCompanyName = () => {
        return <div className="col-xs-auto col-sm-7">
            Company Website: { this.state.companyname }
            <small 
                className="cursor float-right" 
                onClick={() => this.setState({showEditCompanyName: true})}
            >
                ✏️ <a href="#"> Edit</a>
            </small>
        </div>
    }

    editName = () => {
       return <div className="col-xs-auto col-sm-9 mb-1">

            <div class="input-group">
                <input
                    type="text"
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    className="form-control fs-13"
                />

                 <div class="input-group-append">
                        <button class="btn btn-outline-success" onClick={this.updateName}>Submit</button>
                        <button class="btn btn-outline-secondary" onClick={() => this.setState({
                            showEditName: false, 
                            name: this.initialValues.name
                        })}>Cancel</button>
                    </div>
            </div>
        </div>
    }

    editPhoneNumber = () => {
        return <div className="col-xs-auto col-sm-9">
            <div class="input-group my-1">
                <PhoneInput 
                    country='us'
                    onlyCountries={['us']}
                    id="phonenumber"
                    label="Cell Number"
                    name="phonenumber"
                    containerClass="form-control"
                    inputClass="fs-13"
                    dropdownClass="fs-13"
                    placeholder="Cell Number"
                    value={this.state.phone} 
                    onChange={phone => this.setState({phone})}
                />
                 <div class="input-group-append">
                        <button class="btn btn-outline-success" onClick={this.updatePhoneNumber}>Submit</button>
                        <button class="btn btn-outline-secondary" onClick={() => this.setState({
                            showEditPhoneNumber: false, 
                            phone: this.initialValues.phone
                        })}>Cancel</button>
                    </div>
            </div>
        </div>
    }

    editCompanyName = () => {
        return <div className="col-xs-auto col-sm-9">

            <div class="input-group mt-1">
                <input
                    type="text"
                    value={this.state.companyname}
                    onChange={(e) => this.setState({companyname: e.target.value})}
                    className="form-control fs-13"
                />

                 <div class="input-group-append">
                        <button class="btn btn-outline-success" onClick={this.updateCompanyName}>Submit</button>
                        <button class="btn btn-outline-secondary" onClick={() => this.setState({
                            showEditCompanyName: false, 
                            companyname: this.initialValues.companyname
                        })}>Cancel</button>
                    </div>
            </div>
        </div>
    }

    render = () => {

        let {
            showPhoneSuccessPopup, 
            showEditPhoneNumber, 
            showNameSuccessPopup, 
            showEditName,
            showCompanyNameSuccessPopup,
            showEditCompanyName,
            showCompanyNameInvalidPopup
        } = this.state; 

        return(<div>
            <div className="section py-0">
                <div>
                    <h3>
                        Account ID Info 
                    </h3>
                    <small><em>Account ID info cannot be changed</em></small>
                </div>

                <div className="row">
                    {this.displayEmail()}
                    {this.displayUsername()}
                </div>

                <div className="pt-4">
                    <h3>
                        User Contact Info 
                    </h3>
                </div>

                <div className="row">
                    {showEditName ? this.editName() : this.displayName()}
                    {showEditPhoneNumber ? this.editPhoneNumber() : this.displayPhoneNumber()}
                    {showEditCompanyName ? this.editCompanyName() : this.displayCompanyName()}
                </div>

            </div>

            <SuccessPopup
                popupId="popupPhoneUpdated"
                title="Success!"
                message="Phone Number Info Updated"
                trigger={showPhoneSuccessPopup}
                callback={() => {
                  this.setState({showPhoneSuccessPopup: false})
                }}
                closeCallback={() => {
                  this.setState({showPhoneSuccessPopup: false})
                }}
              />
              <SuccessPopup
                popupId="popupNameUpdated"
                title="Success!"
                message="Name Info Updated"
                trigger={showNameSuccessPopup}
                callback={() => {
                  this.setState({showNameSuccessPopup: false})
                }}
                closeCallback={() => {
                  this.setState({showNameSuccessPopup: false})
                }}
              />
              <SuccessPopup
                popupId="popupCompanyNameUpdated"
                title="Success!"
                message="Company Info Updated"
                trigger={showCompanyNameSuccessPopup}
                callback={() => {
                  this.setState({showCompanyNameSuccessPopup: false})
                }}
                closeCallback={() => {
                  this.setState({showCompanyNameSuccessPopup: false})
                }}
              />

              <SuccessPopup
                popupId="companyNameInvalid"
                title="Error"
                message="Company website must be a valid web address"
                trigger={showCompanyNameInvalidPopup}
                callback={() => {
                  this.setState({showCompanyNameInvalidPopup: false})
                }}
                closeCallback={() => {
                  this.setState({showCompanyNameInvalidPopup: false})
                }}
                disallowClose="true"
                useButton="true"
                style="warning"
            />
        </div>);
    }

}

