import React, {Component, Fragment} from 'react'
//import { CustomLogout } from '../Login/customlogout';
import jwtDecode from "jwt-decode";
import {haulcheckURl, assuredURL, assuredAPIURL, earthidealURL, url} from '../../constants';
import ajax from 'superagent';
import LetterAvatar from './LetterAvatar';
import NotificationItem from './NotificationItem';
import SupportGuide from './SupportGuide';
import NavbarCore from './NavbarCore';
import assuredPageUrls from '../../constants/assuredPageUrls';

export default class Navbar extends Component {

    constructor(props){
        super(props);
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        const username = userDecoded ? userDecoded['cognito:username'] : null;

        this.state = {
            username,
            userDecoded,
            iduser: null,
        }
    }

    componentWillMount = () => {

        ajax.post(`${assuredAPIURL}/users/auth`)
            .send(this.state.userDecoded)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({iduser: response.body.user.iduser});                    
                } else {
                    console.log('There was an error', error);
                }
            }
        );
    }

    render = () => {
        return(
            <Fragment>
                <NavbarCore
                    haulcheckFrontendURL={url.frontend}
                    assuredFrontendURL={assuredURL}
                    assuredAPIURL={assuredAPIURL}
                    eiFrontendUrl={earthidealURL}
                    myFrontendUrl={url.frontend}
                    assuredPageUrls={assuredPageUrls}
                    app={"CrewHuddle"}
                    showAlphaBadge={true}
                    iduser={this.state.iduser}
                    username={this.state.username}
                    showSandbox={this.props.showSandbox}
                    logo="../img/logo/ch-logo.png"
                    showCHHome = {this.props.showCHHome}
                >
                    {/*<li class="nav-item">
                        <a class="nav-link" href="#">I am a Site Leader</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#getting-set-up-guide-modal">Getting Set Up</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited to an Agreement</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" href="#" data-toggle="modal" data-target="#completing-setup-getting-started-guide-modal">Completing Setup / Getting Started</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited as a Co-Leader</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited as a Team Member</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-team-member-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-hauls-guide-modal">Tracking Hauls</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited as a Guest</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guest-guide-modal">Site-based Ticket Tracking</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#trucks-tracking-and-reports-guide-modal">Trucks, Tracking, & Reports</a>
                            </li>
                        </ul>
                    </li>*/}
                </NavbarCore>

                {/*<SupportGuide />*/}
            </Fragment>
        )
        //return null;
    }
}