import React, {Component, useState, useEffect, Fragment}  from 'react';
import _ from 'lodash';
import {
   // AspectMeasuresService,
    ReportsService,
    PublishSubscribe
   // WorkGroupsService
} from '../../../services'
import panels from '../../../constants/panels'
import {
    addChildToArray,
    removeChildFromArray,
    updateObjectBasedOnKey,
    isJsonString,
    parseIfJSONString
} from '../../../utils'
import { Link } from 'react-router-dom'
import moment from 'moment';
import SuccessPopup from '../../common/Popups'
import {
  isMobileOnly,
  isTablet
} from 'react-device-detect'
import { Popover, OverlayTrigger } from 'react-bootstrap';

export default function RecentReports(props){

    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    let [client, setClient] = useState(null);
    const [idreportToDelete, setIdReportToDelete] = useState(false);

    useEffect(() => {
       loadReports(true);
       setSubscription();
    }, [props.unit])

    useEffect(() => {return closeSubscription}, []);

    const loadReports = async (showLoading) => {
        let {unit} = props;

        if (unit !== null && unit !== undefined){
            console.log("Loading reports with unit", unit)
            if (loading !== showLoading) setLoading(showLoading);
            let loadedReports = await ReportsService.getRecentReports(unit.idworkgroup, unit.role);
            console.log("loading reports loadedreports:", loadedReports);
            setReports(loadedReports);
            setLoading(false);
        }
    }

    let setSubscription = async () => {
        let {unit} = props;

            if (unit !== null && unit !== undefined){
            client = await PublishSubscribe.quickSubscribe(
                "MySites/Sidebar/index.js",
                "CH/reports",
                handleMessage,
                {idworkgroup: props.unit.idworkgroup}
            );

            setClient(client);
        }
    }

    let closeSubscription = () => {
        if (client !== null){
            setClient(PublishSubscribe.closeClient(client));
        }
    }

    let handleMessage = (message) => {
        console.log("recentReports handleMessage message:", message);
        if (isJsonString(message)) message = JSON.parse(message);

        if (message.type == "remove-report"){
            console.log("recentReports handleMessage removing report")
            setReports(previousState => removeChildFromArray(previousState, message.idreport, "idreport"));
        } else if (message.type == "updated-report"){
            console.log("recentReports handleMessage processing update");
            //setReports(previousState => updateObjectBasedOnKey(previousState, message.data, ["date"], ["asc"]))
            loadReports(false);
        } else if (message.type == "new-report"){
            console.log("recentReports handleMessage processing new report with message", message);
            loadReports(false);
            //setReports(previousState => addChildToArray(previousState, message.report, "idreport", ["date"], ["asc"]))
        }
    }

    const goToEditReport = async (idreport) => {
        await localStorage.setItem("activeReportToEdit", idreport);
        props.history.push("/app/report-setup");
    }

    const goToViewReport = async (idreport) => {
        await localStorage.setItem("activeReport", idreport);
        props.history.push("/app/report-view");
    }

    const deleteReport = async () => {
        let idreport = idreportToDelete;
        setIdReportToDelete(false);
        await ReportsService.deleteReport(idreport);
    }

    const calcPercentage = (numAcknowledged, numAcknowledgements) => {

        let num = 0;
        if (numAcknowledgements == 0) num = 100;
        else num = Math.round((numAcknowledged / numAcknowledgements) * 100);

        return num;
    }

    const endHuddle = async(idreport) => {
        await ReportsService.endReport(idreport);
    }

    const renderSiteName = (isStarted, idreport, siteName) => {
        let className = " strong fs-14" + (!isMobileOnly ? " mb-0" : "");

        return (isStarted ? 
            <a className={"text-dark" + className} href="#" onClick={() => goToViewReport(idreport)}>{siteName}</a>
        :
            <p className={"text-light" + className}>{siteName}</p>
        )
    }

    const renderProgressBar = (isStarted, percentage) => {;
        return <div class="progress">
            <div class={"progress-bar" + (isStarted ? "" : " progress-bar-striped bg-secondary")} role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{width: percentage+'%'}}>
            </div>
        </div>
    }

    const EmployeePopover = (employeesList) =>  {
        return <Popover id="employeePopover" title="Popover top">
            <ul className="list-unstyled m-3">
                {employeesList.length > 0 ? 
                    employeesList.map((employee, index) => {
                        return <li className="strong" key={index}>
                            { employee.acknowledged == 1 ?
                                <i className="ti ti-check strong mr-2 text-success" aria-hidden="true"></i>
                            : employee.acknowledged == 0 ?
                                <i className="ti ti-close strong mr-2 text-danger" aria-hidden="true"></i>
                            : <i className="ti ti-user strong mr-2 text-light" aria-hidden="true"></i> }
                            {employee.name || employee.idemployee}
                            
                        </li>
                    })
                :
                    <li className="ml-5"><em>Error: No employees</em></li>
                }
            </ul>
        </Popover>
    };


    const renderLinks = (isStarted, idreport, status, percentage, employeesList) => {
        if ((!props.unit || props.unit.role == "employee")) return null;
        else return <div class={"text-right flexbox px-0 " + (isMobileOnly ? " pt-4" : isTablet ? " mt-2" : "")}>
            {isStarted ? <>
                <a className="small text-light" href="#" onClick={() => endHuddle(idreport)}><u>End Huddle</u> <i className={"ti ti-image ml-1" + (!isMobileOnly && !isTablet ? " mr-6" : "")} aria-hidden="true"></i></a>
                <OverlayTrigger trigger="click" placement="top" overlay={EmployeePopover(employeesList)}>
                    <a className="small text-light" href="#"><i className="ti ti-user" aria-hidden="true"></i></a>
                </OverlayTrigger>
            </>:<>
                <a className="small text-dark" href="#" onClick={() => goToEditReport(idreport)}><u>Edit Plan</u> <i className={"ti ti-pencil-alt ml-1" + (!isMobileOnly && !isTablet ? " mr-6" : "")} aria-hidden="true"></i></a>
                <a className="small text-light" href="#" onClick={() => setIdReportToDelete(idreport)}><i className="ti ti-trash"></i></a>
            </>}
        </div>
    }

    const renderReportRow = (report, key) => {

        let {siteName, idreport, date, status, numAcknowledged, numAcknowledgements, employeesList} = report;
        let percentage = calcPercentage(numAcknowledged, numAcknowledgements)
        let isStarted = (!status || status.indexOf("setup") === -1)

        console.log("renderReportRow report:", report);

        let badgeClassName = "badge " + (isMobileOnly ? "mb-4" : isTablet ? "" : " mt-0")

        console.log("renderReportRow report:", report);

        return <div class="px-0" key={idreport}>
            <div class="card border-bottom">
                <div class="card-body">
                    
                    {isMobileOnly ? renderSiteName(isStarted, idreport, siteName) : null}
                    <div class="flexbox">
                        {!isMobileOnly ? renderSiteName(isStarted, idreport, siteName) : null}
                        
                        <p className={(isTablet ? "mb-0" : "")}>{date ? moment(date).add('hours',7).format("MMM-DD-YY") : "Date not yet specified"}</p>
                        { status.indexOf("setup") !== -1 ? 
                           <span className={badgeClassName + " badge-pale badge-warning"}>Plan Pending</span>
                        :
                            <span className={badgeClassName + "badge badge-pale badge-primary"}>Huddle Started</span>
                        }

                        {!isMobileOnly ?
                            <div className={"col-3" + (isTablet ? " px-0" : "")}>
                                {renderProgressBar(isStarted, percentage)}
                            </div>
                        :null}

                        {(!isMobileOnly && !isTablet) ? renderLinks(isStarted, idreport, status, percentage, employeesList) : null}

                    </div>
                    {isMobileOnly ?
                        renderProgressBar(isStarted, percentage)
                    : null }
                    
                    
                    {isMobileOnly || isTablet ? renderLinks(isStarted, idreport, status, percentage, employeesList) : null}

                </div>
            </div>
        </div>

        /*return <div className="row" key={idreport}>
            {isStarted ? 
                <div className="col-3"><a className="text-dark strong fs-14" href="#" onClick={() => goToViewReport(idreport)}>{siteName}</a></div>
            :
                <div className="col-3"><p className="text-light strong fs-14">{siteName}</p></div>
            }
            <div className="col-2"><p className="fs-14">{date ? moment(date).add('days', 1).format("MMM-DD-YY") : "Date not yet specified"}</p></div>
            <div className="col-3 pt-2">                        
                <div className="progress">
                    <div className={"progress-bar" + (isStarted ? "" : " progress-bar-striped bg-secondary")} role="progressbar" aria-valuenow={percentage + ""} aria-valuemin="0" aria-valuemax="100" style={{width: percentage+'%'}}>
                    </div>
                </div>
            </div>
            {status.indexOf("setup") !== -1 ? 
                <div className="col-1 mr-5"><span className="badge badge-pale badge-warning">Plan Pending</span></div>
            :
                <div className="col-1 mr-5"><span className="badge badge-pale badge-primary">Huddle Started</span></div>
            }
            <div className="col-2 px-0 gap-x text-right">
                {isStarted ?
                    <a className="small text-light" href="#" onClick={() => endHuddle(idreport)}><u>End Huddle</u> <i className="ti ti-timer ml-1" aria-hidden="true"></i></a>
                :<>
                    <a className="small text-dark" href="#" onClick={() => goToEditReport(idreport)}><u>Edit Plan</u> <i className="ti ti-pencil-alt ml-1" aria-hidden="true"></i></a>
                    <a className="small text-light" href="#" onClick={() => setIdReportToDelete(idreport)}><i className="ti ti-trash"></i></a>
                </>}
            </div>
        </div>*/

    }

    return(<div className="row gap-y px-lg-11">


        <div className="col-12 col-lg-10 mx-auto">
            <div className="card shadow-4">
                <div className="card-body">
                    <div className="row flexbox px-4">
                        <p className="lead-3 mb-2">Pending Huddles</p>
                        {!props.unit || props.unit.role == "employee" ? null : 
                            <Link type="button" className="btn btn-sm btn-primary" to='/app/report-setup'><i className="ti ti-plus"></i> New Huddle</Link>
                        }
                    </div>

                    <hr className="mt-2 mb-5" />

                    { loading ? 

                        <div className="loader">Loading...</div>

                    : !_.isEmpty(reports) ?

                        reports.map((report, key) => renderReportRow(report, key))

                    :
                        <p>No pending huddles found</p>
                    }


                </div>
            </div>
        </div>


        <SuccessPopup
            trigger={idreportToDelete !== false}
            popupId="delete-plan-popup"
            style="warning"
            useButton={true}
            buttonLabel="Yes"
            title={"Delete Plan"}
            message={"Are you sure you want to delete this plan?"}
            callback={deleteReport}
        />


    </div>)
}