export const url = {
  //backend: 'http://localhost:2999',
  //frontend: 'http://localhost:3000',
  backend: 'https://crewhuddle.earthideal.com:2996',
  frontend: 'https://crewhuddle.earthideal.com',
  stripe_key: 'pk_live_o2riQdZlnOHZHwNDUqlOypmF007FS0Aklq'
}
export const earthidealURL = 'https://app.earthideal.com';
export const earthidealAPIURL = 'https://app.earthideal.com:2999';
export const haulcheckURl = 'https://haulcheck.earthideal.com';
export const assuredURL = 'https://assureddisposal.earthideal.com';
export const assuredAPIURL = 'https://assureddisposal.earthideal.com:2997';
