import React, {useState, useEffect, Fragment} from 'react'
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2'
import './phonestyles.css'

export default function UpdateCrewMemberDetails({crewMember, callback, existingCrewMember}){

	return<>
		<div class="col-12 col-lg-2 p-0 mr-1 mb-1">
	        <input class="form-control fs-13" type="text" placeholder="Full Name"
	        	value={crewMember.name}
	        	onChange={e => callback('name', e.target.value)}
	        	disabled={crewMember.isExistingUser}
	        />
	        {crewMember.nameInvalid ? <span className="text-danger small">Full Name required</span> : null}
	    </div>
	    <div class="col-12 twonhalf-col p-0 mr-1 ml-0 mb-1">
	        <input class="form-control fs-13" type="text" placeholder="Email Address"
	        	value={crewMember.idemployee}
	        	onChange={e => callback('idemployee', e.target.value)}
	        	disabled={existingCrewMember}
	        />
	    </div>

    	<PhoneInput 
			country='us'
			onlyCountries={['us']}
			id="phonenumber"
			label="Cell Number"
			name="phonenumber"
			containerClass="col-12 col-lg-2 p-0 mr-1 ml-0 mb-1"
			inputClass="fs-13"
			dropdownClass="fs-13"
			placeholder="Cell Number"
			value={crewMember.phonenumber} 
			onChange={phonenumber => callback('phonenumber', phonenumber)}
			disabled={crewMember.isExistingUser}
		/>

	    <div class="col-12 twonhalf-col p-0 mr-1 ml-0 mb-1">
	        <input class="form-control fs-13" type="text" placeholder="Work Role (ex: Operator)" 
	            value={crewMember.workrole}
	            onChange={(e) => callback("workrole", e.target.value)}
	        />
	    </div>
	    <div class="col-12 col-lg-2 p-0 mr-1 ml-0 mb-1">
	        <select className="form-control fs-13" value={crewMember.role} onChange={(e) => callback("role", e.target.value)}>
	            <option value={"default"}>User Role</option>
	            <option value={"admin"}>Office User</option>
	            <option value={"crewleader"}>Crew Leader</option>
	            <option value={"employee"}>Crew Member</option>   
	        </select>
	    </div>
    </>


}