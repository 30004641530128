import React, {useState, useEffect, useReducer, Fragment}  from 'react';
import JwtDecode from 'jwt-decode'
import SuccessPopup from './Popups'
import {
	ApiService,
    ReportsService,
    //PublishSubscribe
    StakeholdersService
} from '../../services';

export default function StakeholdersModal({idsite, siteName, pdfMode, reportsData}) {
	
	let sendToMyselfObj = {
		name: "Send To Myself",
		email: ApiService.getJWTEmail()
		//email: 'jesstest.ww@gmail.com',
	};

	let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));

	let [stakeholdersList, setStakeholdersList] = useState([JSON.parse(JSON.stringify(sendToMyselfObj))]);
	let [sendingPDF, setSendingPDF] = useState(false);
	let [sentPDF, setSentPDF] = useState(false);
	let [anyStakeholderSelected, setAnyStakeholderSelected] = useState(false);
	let [recipients, setRecipients] = useState([]);

	let setListOfStakeholders = async(idsite) => {
		console.log("Calling setListOfStakeholders");
		let stakeholders = await StakeholdersService.getStakeholdersBySiteAndWorkGroup(idsite, workUnit.idworkgroup);
		stakeholders.unshift(JSON.parse(JSON.stringify(sendToMyselfObj)));
		setStakeholdersList(stakeholders);
		setRecipients([])
	}

	let selectStakeholder = (key) => {
		console.log("Calling selectStakeholder with key " + key);
		if (stakeholdersList[key].selected == undefined) stakeholdersList[key].selected = true;
		else stakeholdersList[key].selected = !stakeholdersList[key].selected;
		setStakeholdersList(stakeholdersList);
		let selectedStakeholders = stakeholdersList.filter(({selected}) => {return selected});

		setRecipients(JSON.parse(JSON.stringify(selectedStakeholders)));

		setAnyStakeholderSelected(selectedStakeholders.length > 0);
	}

	{/*useEffect(() => {
		let body = document.querySelector("body");
    	body.insertBefore(document.querySelector('#stakeholders'), body.firstChild);
	}, [])*/}

	useEffect(() => {
		if (idsite != undefined){
			setListOfStakeholders(idsite);
		}
	}, [idsite]);

	let sendPDF = async() => {

		setSendingPDF(true);

		console.log("sendPDF value of recipients:", recipients);
		reportsData.recipients = recipients;

		console.log("SendPDF reportsData:", reportsData);

		if (pdfMode == "single"){
			await ReportsService.shareSingleReport(reportsData);
		} else {
			await ReportsService.shareMultipleReports(reportsData);
		};

		setSendingPDF(false);
		
		let closeButton = document.getElementById("close-stakeholders-modal");
		if (closeButton){
			closeButton.click();
			setSentPDF(true);
		}
	}


	return (<>
		<div class="modal fade" id="stakeholders" tabindex="-1" role="dialog" aria-labelledby="connect-measures" aria-hidden="true">
			<div class="modal-dialog modal-long" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="connect-measures">Share with Stakeholders</h5>
						<button type="button" class="close" id="close-stakeholders-modal" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

				<div class="modal-body">
					<p class="strong fs-14 mb-2">Who would you like to send this to?</p>

					<div class="card">
						<p class="card-title fs-16 strong mb-0">{siteName}</p>

						<div class="card-body">

							<div class="custom-controls-stacked ml-6">

								{stakeholdersList.length ? stakeholdersList.map(({name, selected}, key) => {
									return (<>
										<div class="custom-control custom-checkbox mb-1">
											<input type="checkbox" 
												class="custom-control-input" 
												id={"stakeholder-"+key}
												checked={selected}/>
											<label class="custom-control-label fs-16" for={"stakeholder-"+key} onClick={() => selectStakeholder(key)}>{name}</label>
										</div>

										{key == 0 ? <br /> : null}
									</>)

								}) : null}
							
							</div>
						</div>
					</div>

				</div>

					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
						<button type="button" 
						class="btn btn-primary" 
						onClick={sendPDF}
						disabled={sendingPDF || !anyStakeholderSelected}>
							{sendingPDF ? <img src="../img/app/photoswipe/preloader.gif" /> : null}
							Email Report PDF
						</button>
					</div>
				</div>
			</div>
		</div>

		<SuccessPopup
        	trigger={sentPDF}
        	popupId="sent-reports-pdf"
        	style="success"
        	useButton={false}
        	buttonLabel="Yes"
        	title={"Report PDF Request Sent"}
        	message={"Your request has been processed and stakeholders will be contacted soon!"}
        	callback={() => setSentPDF(false)}
        />

	</>)

}