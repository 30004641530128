
import _ from 'lodash';

export function detectIsValidObjectInGroup(groups, groupIndex, entryIndex = false) {

  //console.log("Calling detectIsValidObjectInGroup with params groups", groups, "groupIndex", groupIndex, "entryIndex", entryIndex)

  if (!_.isEmpty(groups[groupIndex])){
      
    //console.log("detectIsValidObjectInGroup group is not empty")

    if (entryIndex === false){
      return true; //Return if group is valid and entry is not being searche for
    } else {
      let activeGroup = groups[groupIndex]
      if (!_.isEmpty(groups[groupIndex]).entries){

        //console.log("detectIsValidObjectInGroup group entries are not empty")
        let activeEntry = groups[groupIndex].entries[entryIndex];

        if (!!activeEntry){
          return true; //Return true if entry is valid within valid group
        }

      }
    }
  }

  return false; //If not returned by now, the return value is false
  
}