import React, {Fragment} from 'react'
import AddStakeholderContacts from './AddStakeholderContacts';
import ViewStakeholderContacts from './ViewStakeholderContacts';

export default function StakeholderContacts(props){


    return(<>

        <header class="section-header mb-0">
            <h2><strong>Stakeholder Contacts</strong></h2>
        </header>

        <div class="col-12 col-md-10 my-5 mx-auto">
            <div class="card border">
                <div class="card-body">

                    <AddStakeholderContacts />
                    
                </div>
            </div>
        </div>

        <div class="col-12 col-md-10 my-5 mx-auto">
            <div class="card border">
                <div class="card-body">

                    <ViewStakeholderContacts />

                </div>
            </div>
        </div>
        
   </>)

}