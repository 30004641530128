import React, {useState, useEffect} from 'react';
import Creatable, { useCreatable } from 'react-select/creatable';
import _ from "lodash";

export default function Combobox({
  items,
  value,
  onSelect,
  filterOptions,
  className,
  identifier,
  width,
  renderItem,
  normalStyle,
  hoverStyle,
  showAddOption,
  onAddElement,
  placeholder,
  disabled,
  closeMenuOnSelect,
  hideSelectedOptions
}) {

  const [selected, setSelected] = useState([])

  useEffect(() => {
    console.log("Combobox Change in selected")
    if (onSelect && typeof onSelect == 'function'){
      if (!_.isEmpty(selected)){
        onSelect(selected)
      } else {
        onSelect([])
      }
    }
  }, [selected])



  return  <Creatable
      classNames={{

      }}
      getOptionLabel={(item) => {
        //console.log("Calling getOptionLabel with item", item, " which is of type", typeof item)
        if (item.__isNew__){
          return item.label;
        } else {
          return `${item[identifier]}`

        }
      }}
      getOptionValue={(item) => `${item[identifier]}`}
      options={items} 
      value={selected}
      onChange={(val) => {
        //console.log("ComboBox onChange val:", val);
        setSelected(val)
      }}
      hideSelectedOptions={hideSelectedOptions ? hideSelectedOptions : true}
      closeMenuOnSelect={closeMenuOnSelect ? closeMenuOnSelect : true}
      placeholder={placeholder}
      noOptionsMessage={() => {return "Type to add an option"}}
      onCreateOption={(val) => {
        //console.log("ComboBox onCreateOption val:", val);
        onAddElement(val);
        setSelected([{name: val}])
      }}
      allowCreateWhileLoading={true}
    />


    return null;

}