import React, {Component, Fragment} from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'
import {url, earthidealAPIURL} from '../../../constants';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import {
    WorkGroupsService
} from '../../../services'
import SiteListItem from "./SiteListItem"

import {alphanumericSort} from '../../../utils'

export default class Sites extends Component {

	 constructor(props){
        super(props);

        this.state = {
        	sitesList: null,
        }
    }

    componentWillMount = async() => {
    	if (this.props && this.props.unit && this.props.unit.idworkgroup){
    		this.getSites(this.props.unit.idworkgroup);	
    	}
    }

    componentWillReceiveProps = (newProps) => {
    	let oldIdWorkGroup = (this.props.unit ? this.props.unit.idworkgroup : 0)
    	if (newProps.unit && newProps.unit.idworkgroup != oldIdWorkGroup){
    		this.getSites(newProps.unit.idworkgroup);
    	}    	
    }

    getSites = async (idworkgroup) => {
    	let sitesList = await WorkGroupsService.getWorkGroupSites(idworkgroup)
    	sitesList = alphanumericSort(sitesList, "name")
        this.setState({sitesList})
    }

    render = () => {
    	if (this.state.sitesList == null) return null;

    	return(<>
		  	<header class="section-header mb-0">
                <h2><strong>{this.props.title}</strong></h2>
            </header>

    		<div className="row">

				<div className="col-9 border-primary px-6 py-3 mx-auto mt-3">
					{this.renderSitesList()}
				</div>
			</div>
    	</>)
    }

    renderSitesList = () => {
    	let {sitesList} = this.state;
    	let {unit} = this.props;

    	if (sitesList == null){
    		return (<div className="row mt-0 mb-0 ml-0">
				<div className="px-1">
					<p className="mb-0 pb-0"><i className="fa fa-eye strong mr-2"></i>Getting sites...</p>
				</div>
			</div>)

    	} else {
    		return(
	    		<div className="row mt-0 mb-0 ml-0">
					<div className="col">
						<p className="mb-0 pb-0"><i className="fa fa-eye strong mr-2"></i>You currently have <strong>{sitesList.length}</strong> sites that are visible to your work unit.</p>

						{sitesList.length > 0 ? 
							<ul className="list-unstyled pt-4 strong w-100">
								{sitesList.map((site, key) => {
									return (<SiteListItem site={site} key={key} />)
								})}
							</ul>
						: null }

					</div>
				</div>

			)
		}

    }

}
