import React, {Component, Fragment} from 'react'
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import H from "@here/maps-api-for-javascript";
import {url, earthidealAPIURL} from '../../constants';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import {
    WorkGroupsService
} from '../../services'

import HereMapsSiteWidget from './HereMapsSiteWidget';

export default class NewSite extends Component {

	 constructor(props){
        super(props);

        this.minNameLength = 4;
  		this.maxNameLength = 140;

        this.state = {
        	sitename: "",
            markerPosition: {lat: 43.03501925228261, lng: -87.90833392173009},
            success: false,
            siteNameInvalid: false
        }
    }

    submitNewSite = async () => {
    	const userJWT = localStorage.getItem('token')
		const userDecoded = jwtDecode(userJWT)
		const username = userDecoded ? userDecoded['cognito:username'] : null
		

    	let {sitename, markerPosition} = this.state;

    	if (sitename.length < this.minNameLength || sitename.length > this.maxNameLength){
    		this.setState({siteNameInvalid: true})
    	} else {
    		this.setState({siteNameInvalid: false});
	    	let {unit} = this.props;
	        ajax.post(earthidealAPIURL + "/sites/add")
	            .send({
	                formValues: {
	                    nameProject: sitename
	                },
	                geometry: markerPosition,
	                userId: username,
	                appOfOrigin: "crewhuddle"
	            }).then(async (res) => {
	                let idsite = res.body.haulSiteId;
	                let sitesList = await WorkGroupsService.setSiteVisibility({idsite, idworkgroup: unit.idworkgroup, visibility: 1});
	                this.setState({sitesList, success: true})
	            }).catch(error => {
	                console.log("Site create error:", error);
	                alert("Error creating site!")
	            });
        }
    }

    mapClicked = (mapProps, map, clickEvent) => {
        const { latLng } = clickEvent;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.setState({markerPosition: { lat, lng }});
    }

    render = () => {

    	let {siteNameInvalid} = this.state;
    	let siteNameTooShort = (this.state.sitename.length < this.minNameLength);
    	let siteNameTooLong = (this.state.sitename.length > this.maxNameLength);

    	return(<>
     		<header class="section-header mb-0">
            	<h2><strong>New Site</strong></h2>
            </header>

            <div class="row">

				<div class="col-10 border-primary px-6 py-3 mx-auto mt-3">

					<div id="workunitsites">
						<div class="card card-body">
							<div class="row m-0">
								<div class="col-12 col-lg-11 mt-5">
									<div class="form-group row gap-y">

										<div class="col-lg-7 p-0 mb-6">
											<div class="">
												<label class="strong p-0 m-0">Name your new site:</label>
												<input class={"form-control p-2 " + (siteNameInvalid && ((siteNameTooShort || siteNameTooLong)) ? "is-invalid" : "mb-4")} type="text" placeholder="Project Name or Location"
													value={this.state.sitename}
													onChange={(e) => this.setState({sitename: e.target.value})} />
													{siteNameInvalid ? 
													siteNameTooLong ?
														<span className="text-danger mb-4">New site name is too long</span>
													: siteNameTooShort ?
														<span className="text-danger mb-4">New site name is too short</span>
													: null
												: null}
												<div style={{height: '300px'}} id="map-wrapper">
													<HereMapsSiteWidget
														callback={(markerPosition) => {
															this.setState({markerPosition});
														}}
													/>
								                </div>
											</div>
										</div>

									</div>
									<button className="btn btn-sm btn-primary float-right mt-8 mt-md-2" disabled={this.state.success} onClick={this.submitNewSite}>SAVE SITE</button>
								</div>

								{this.state.success ?  <div className="col-11 alert alert-success mt-6 mr-auto" role="alert">
		                            Your site has been added!
		                        </div> : null}

							</div>

						</div>
					</div>

				</div>

        	</div>
    	</>)
	}
  

}
