import React, {Component, Fragment} from 'react'
import ViewEntryModal from '../../common/ViewEntryModal';

export default class CompleteReport extends Component {

	constructor(props){
        super(props);

        this.state = {
            activeMeasureGroup: 0,
            activeMeasureEntry: 0,
        }
    }

    render = () => {
    	let {measuresList, returnToCreate, notes, updateNotes, finishReportSetup, startDate, sendingReport} = this.props;
    	//let {startDate, name, sitesList, groupsList, employeesList, idsite} = this.props.reportState;

    	return(<>
            <header className="section-header mt-5 mb-0">
                <h2><strong>Report Setup</strong></h2>
            </header>
            <div className="section pt-1">
                <div className="container px-0">
                    <div className="row">

                        <div className="col-12 col-lg-10 mx-auto border-top">
                            <div className="col-12 mt-2 pb-4 mb-4 border-bottom">
                                <p className="mt-4 mb-0 fs-17"><i className="fa fa-cube px-2" aria-hidden="true"></i><strong>Measures Connected to Your Aspects</strong></p>
                            </div> 
                            <div className="col-12 col-lg-10 mb-4 ml-7">
                                <p className="fw-400 fs-14 mb-2 ml-lg-6">View and mark off each Measure:</p>
                            </div>    
                        </div>

                        <div className="col-12 col-lg-10 mx-auto">
                            {measuresList.map(this.renderMeasureGroup)}
                        </div>

                        <div className="col-lg-8 float-left mt-2 ml-lg-9 mb-4">
                            <div className="form-group">
                                <p className="fs-16 strong mt-4 mb-2">Additional Considerations</p>
                                <textarea className="form-control" 
                                    placeholder="Library improvements, other comments, considerations, elaborations, unique situations, etc." 
                                    rows="4"
                                    value={notes}
                                    onChange={updateNotes}></textarea>
                            </div>
                        </div>

                    </div>

                </div>

                {this.props.isMobileOnly ?

                        <div class="mb-6 pt-4 border-top">
                            <button type="button" class="btn btn-block btn-secondary my-4" onClick={returnToCreate}><i class="fa fa-angle-left mr-1"></i> BACK</button>
                            <button type="button" class="btn btn-block btn-outline-primary my-4" onClick={() => finishReportSetup("huddle-reports")} disabled={sendingReport}>
                                {sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
                                Save for Later
                            </button>
                            <button type="button" class="btn btn-block btn-primary my-4" disabled={sendingReport || startDate==null} onClick={() => finishReportSetup("report-view")}>
                                {sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
                                {startDate==null ? "DATE REQUIRED TO " : ""}SAVE & VIEW REPORT{startDate==null ? "" : <i className="fa fa-angle-right ml-1"></i>}
                            </button>
                        </div>

                        :

                        <div className="col-md-10 col-xl-6 mx-auto mb-6 flexbox pt-4 border-top">
                            <button className="btn btn-secondary" type="button" onClick={returnToCreate}><i className="fa fa-angle-left mr-1"></i> CANCEL</button>
                            <button type="button" className="btn btn-outline-primary" onClick={() => finishReportSetup("huddle-reports")} disabled={sendingReport}>
                                {sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
                                Save for Later
                            </button>
                            <button type="button" className="btn btn-primary" disabled={sendingReport || startDate==null} onClick={() => finishReportSetup("report-view")}>
                                {sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
                                {startDate==null ? "DATE REQUIRED TO " : ""}SAVE & VIEW REPORT{startDate==null ? "" : <i className="fa fa-angle-right ml-1"></i>}
                            </button>
                        </div>


                    }


                {/*<div className="col-lg-10 mx-auto flexbox pt-4 border-top">
                    <div>
                        <a className="btn btn-secondary" href="#" onClick={returnToCreate}><i className="fa fa-angle-left mr-1"></i> BACK</a>
                    </div>
                    <button type="button" className="btn btn-outline-primary" href="#" disabled={sendingReport} onClick={() => finishReportSetup("huddle-reports")}>
                        {sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
                        Save For Later
                    </button>
                    <div>
                        <button type="button" className="btn btn-primary" href="#" disabled={sendingReport || startDate==null} onClick={() => finishReportSetup("report-view")}>
                            {sendingReport ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
                            SAVE & VIEW REPORT<i className="fa fa-angle-right ml-1"></i>
                        </button>
                    </div>
                </div>*/}
                <div className = "col-lg-10 mx-auto  mb-6 ">
                    <p className="text-right small"><i>Be sure to mark all of your measures before proceeding</i></p>
                </div>

            </div>

            {this.renderMeasureModal()}
      </>)
    }

    renderMeasureGroup = (group, groupIndex) => {

        return(<div className={"col-lg-5 pl-0 mb-2 float-left ml-lg-" + (groupIndex%2 == 0 ? "8" : "3")} key={groupIndex}>
            <p className="fs-16 mt-4 mb-2"><strong>{group.name}</strong></p>

            {group.entries ? <Fragment>
                {group.entries.map((entry, index) => this.renderMeasureEntry(entry, index, groupIndex))}
            </Fragment>: <p className="ml-5"><em>No measures from this group</em></p>}
        </div>)
    }

    renderMeasureEntry = (entry, index, groupIndex) => {
        let {status, name} = entry;
        let {setMeasureStatus} = this.props;

        return(
            <div className="p-0 m-0 mb-3">
                <button type="button" className={"btn btn-xs btn-outline-danger" + (status == "na" ? " active" : "")}
                    onClick={() => setMeasureStatus(groupIndex, index, "na")}>
                    <i className="ti ti-na strong" aria-hidden="true"></i>
                </button>
                <button type="button" className={"btn btn-xs btn-outline-success" + (status == "acknowledged" ? " active" : "")}
                    onClick={() => setMeasureStatus(groupIndex, index, "acknowledged")}>
                    <i className="ti ti-check strong" aria-hidden="true"></i>
                </button>
                <span className="ml-4">
                    <a className={(status == null ? " strong text-primary" : 'text-dark')} data-toggle="modal" data-target="#mark-measure" href="#"
                    onClick={() => this.setState({activeMeasureGroup: groupIndex, activeMeasureEntry: index})} >{name}</a>
                </span>
            </div>
        )
    }

    renderMeasureModal = () => {
        let {measuresList, idaspects} = this.props;
        let {activeMeasureGroup, activeMeasureEntry} = this.state;
        let group = measuresList[activeMeasureGroup];
        let entry = null;

        console.log("renderMeasureModal group:", group);

        if (group && group.entries){
            entry = group.entries[activeMeasureEntry];
        } 

        console.log("Returning ViewEntryModal")
        return( <ViewEntryModal
                    id="mark-measure"
                    entry={entry}
                    capitalizedModeName={"Measure"}
                    oppositeModeName={"Aspect"}
                    filterIds={idaspects}
                >
                <button type="button" data-dismiss="modal" aria-label="Close" className="btn btn-primary">Done</button>
            </ViewEntryModal>)

    }

}