import mqtt from 'mqtt'
import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {parseIfJSONString} from "../utils"

let options = {
  username: "HCActiveMQ",
  password: process.env.REACT_APP_AMQ_PASSWORD, //Password and endpoint in .env file for security reasons
  //clientId: 'mqttLambda_HC', //Not included as it causes connection to fail for some reason
  protocolId: 'MQTT',
  protocolVersion: 4,
  // protocolId: 'MQIsdp',
  //protocolVersion: 3,
  debug: false,
  keepalive: 60,
  clean: true,
  reconnectPeriod: 1000,
  queueQoSZero: true,
}


//Client is created on page load and closed on page unload. This way, a single client subscribes to all topics
//Previously, every topic got its own client, which was resource intensive.
//See also BUG-1493
let mqEndpoint = process.env.REACT_APP_AMQ_ENDPOINT;
let client = mqtt.connect(mqEndpoint, options);

window.addEventListener("unload", function (){
  console.log("AMQ calling closeClient");
  if (client !== null && client.end !== undefined && typeof client.end === 'function') client.end();
  return null;
})

let connections = {};

async function quickSubscribe(originName = "[caller unknown]", topic = false, handleMessageCallback = false, passedIdentifier = false, offlineCallback = false){

   let subscribeToTopic = (passedTopic, passedIdentifier, passedCallback) => {
     client.subscribe(passedTopic, (err)=>{
      if (err){
        console.log("AMQ quickSubscribe error from origin "+originName+":", err);
      } else {

        let connectionObj = {callback: passedCallback, identifier: passedIdentifier}

        if (connections[passedTopic]){
          connections[passedTopic].push(connectionObj);
        } else {
          connections[passedTopic] = [connectionObj];
        }

        console.log("AMQ quickSubscribe success from origin " + originName + " with topic " + passedTopic);
      }
    });
  }

  let badTopicOrCallbackMsg = (passedTopic, passedIdentifier, passedCallback) => {
    console.log("AMQ Invalid topic", passedTopic, typeof passedTopic, "and/or message callback", passedCallback, typeof passedCallback, "from origin", originName, "with identifier", passedIdentifier)
  }

  let validTopic = (passedTopic) => {
    return (passedTopic && typeof passedTopic == "string");
  }

  let validCallback = (passedCallback) => {
    return (passedCallback && typeof passedCallback == "function")
  }



  if (topic == false) return;

  if (Array.isArray(topic) && Array.isArray(handleMessageCallback)){
    //console.log("AMQ Calling quickSubscribe with topic and callbacks in array format")

    for (var i = 0; i < topic.lenght; i++){
      let currentTopic = topic[i];
      let currentCallback = handleMessageCallback[i];

      if (validTopic(currentTopic) && validCallback(currentCallback)){
        subscribeToTopic(currentTopic, passedIdentifier, currentCallback)
      } else {
        badTopicOrCallbackMsg(currentTopic, passedIdentifier, currentCallback);
      }
    }

  } else if (typeof topic == "string" && typeof handleMessageCallback == "function") {

   // console.log("AMQ calling quickSubscribe with topic and callbacks in string/function format")

    subscribeToTopic(topic, passedIdentifier, handleMessageCallback);
  } else {
    badTopicOrCallbackMsg(topic, passedIdentifier, handleMessageCallback);
  }

 

  client.on('offline', async(packet) => {
    console.log("AMQ", "quickSubscribe offline from origin " + originName);
    // According to documentation in this case the client is the one who has gone offline,
    // Ask to reload! or try to reconnect
    client.reconnect();
    //client.end();
    if (offlineCallback) offlineCallback();
  });

  client.on('message', async (topic, passedMessage) => {
    //console.log("AMQ", "quickSubscribe recieved raw message", message, " from topic " + topic)
    //let message = new TextDecoder("utf-8").decode(message_raw)

    passedMessage = await parseUint8Array(passedMessage);

    /*console.log("AMQ", "message.payload:", message.payload);
    console.log("AQM")
    console.log("AMQ", "message instanceOf uint8Array:", message instanceof Uint8Array);*/

    passedMessage = JSON.parse(passedMessage);

    if (typeof passedMessage == "object"){
      _.forOwn(passedMessage, (value, key) => {
          passedMessage[key] = parseIfJSONString(value);
      })
    }

    console.log("AMQ initial passed message:", passedMessage, "And initial passed identifier:", passedIdentifier, "and currently defined identifier:", identifier)

    let {message, identifier} = passedMessage;

    console.log("AMQ identifier from passed message:", identifier)

    //console.log("AMQ", "quickSubscribe recieved message ",message," from topic " + topic, "with passedIdentifier", passedIdentifier);


    console.log("AMQ Existing Connections:", connections);

    if (!_.isEmpty(connections[topic])){
      for (var i = 0; i < connections[topic].length; i++){
        let {callback} = connections[topic][i];
        if (callback && typeof callback == "function"){
          let identifiersAreEqual = _.isEqual(identifier, passedIdentifier)
          console.log("AMQ passedIdentifier:", passedIdentifier, "identifier from message:", identifier, "are they equal?", identifiersAreEqual)

          if (identifiersAreEqual){
            callback(message);
          }
        } 
      }

    }

  })

  client.on('error', (err) => {
    console.log("AMQ quickSubscribe error from origin "+originName+":", err);
    if (err==='ECONNRESET') {
      client.reconnect();
    }
  })

  client.on('close', () => {
    console.log("AMQ quickSubscribe connection closed from " + originName);
  })

  return client;
}

async function parseUint8Array (message){
  if (message instanceof Uint8Array){
    //console.log("AMQ", "Message being parsed from Uint8Array:", message);
    var plainMessage = "";
    for (var i = 0; i < message.length; i++) {
      plainMessage += String.fromCharCode(parseInt(message[i]));
    }
    //console.log("AMQ","plainMessage:", plainMessage);

    return plainMessage;
  } else {
    return message;
  }
}

//Syntactic sugar, just another name for closeSubscription to help avoid breaking old or misnamed code
async function closeClient(originName = "[caller unknown]", identifier, topic){
  return closeSubscription(originName, identifier, topic)
}

async function closeSubscription(originName = "[caller unknown]", identifier, topic){
  console.log("AMQ calling closeSubscription from " + originName + " with topic " + topic);
  if (client !== null && client.unsubscribe !== undefined && typeof client.unsubscribe === 'function' && topic !== undefined) {
    client.unsubscribe(topic);
    if (connections[topic]) connections[topic] = undefined;
  };
  return client;
}

//Hook implemented for BUG-1493
//This helps keep logic consistent among components

function usePublishSubscribe ({origin, topic, identifier, callback, onOfflineCallback}) {

  useEffect(() => {
    console.log("AMQ Calling useEffect from usePublishSubscribe from origin ", origin, "and identifier", identifier)
     initializePS();
     return () => unmountPS()
  }, [])

  let initializePS = () => {
    console.log("AMQ Calling initializeSubscription from usePublishSubscribe from origin ", origin, "and identifier", identifier, "with quickSubscribe", quickSubscribe)

    quickSubscribe(
      origin,
      topic,
      (message) => callback(message),
      identifier,
      onOfflineCallback
    );
  }

  let unmountPS = () => {
    closeSubscription(origin, topic, identifier);
  }

  return {unmountPS}

}

export const PublishSubscribe = {
  quickSubscribe, closeSubscription, closeClient, usePublishSubscribe
}
