import _ from 'lodash';

export function loadEntryOppositeRelationships(freshOppositeLibrary, loadedOppositeRelations, oppositeIdKey){
	let loadingLibrary = loadedOppositeRelations.map((entry) => {return entry[oppositeIdKey]});
	let relationships = _.cloneDeep(freshOppositeLibrary);

    for (var i = 0; i < relationships.length; i++){
        for (var j = 0; j < relationships[i].entries.length; j++){
            let entry = relationships[i].entries[j];

            if (loadingLibrary.indexOf(entry[oppositeIdKey]) != -1){
                entry.selected = true;
            }
        }
    }

    return relationships;
}