import _ from "lodash";

export default function formatDataForSubmit(data){


  //Turn list of groups into list of idaspects to save
  console.log("saveReportSetup data as passed to function:", data)

  let dataToSend = _.cloneDeep(data)

  let {groupsList} = dataToSend;
  let newGroupsList = [];

  //Extract just the IDs of the aspects, measures, and employees to be saved as well as the workgroup ID
  dataToSend.groupsList = formatAspects(dataToSend.groupsList);
  dataToSend.employeesList = formatEmployees(dataToSend.employeesList);
  dataToSend.measuresList = formatMeasures(dataToSend.measuresList);
  dataToSend.idworkgroup = dataToSend.workUnit.idworkgroup;

  //Keep only the essential data and remove the rest before returning
  let essentialData = [
    "name", 
    "startDate", 
    "idsite", 
    "notes", 
    "createdby", 
    "creatorname",
    "idworkgroup", 
    "groupsList", 
    "employeesList", 
    "measuresList", 
    "idreport", 
    "status"
  ]

  dataToSend = _.pick(dataToSend, essentialData);
  console.log("saveReportSetup data as passed to backend:", dataToSend)

  return dataToSend;

}

function formatEmployees (employeesList) {

  let newEmployeesList = [];

  if (!_.isEmpty(employeesList)){
    for (var i = 0; i < employeesList.length; i++){
      let {selected, idemployee} = employeesList[i];

      if (selected){
        newEmployeesList.push({idemployee})
      }
    }
  }

  return newEmployeesList;
}


function formatAspects (groupsList) {

  let newGroupsList = [];

  if (!_.isEmpty(groupsList)){
    for (var i = 0; i < groupsList.length; i++){
      if (!_.isEmpty(groupsList[i].entries)){
        for (var j = 0; j < groupsList[i].entries.length; j++){
          let {selected, idaspect} = groupsList[i].entries[j];

          if (selected){
            newGroupsList.push({idaspect});
          }

        }
      }
    }
  }
 
  return newGroupsList;
}

function formatMeasures (measuresList) {

  let newMeasuresList = [];

  if (!_.isEmpty(measuresList)){
   for (var i = 0; i < measuresList.length; i++){
      if (!_.isEmpty(measuresList[i].entries)){
        for (var j = 0; j < measuresList[i].entries.length; j++){
          let {status, idmeasure} = measuresList[i].entries[j];
          newMeasuresList.push({idmeasure, status});
        }
      }
    }
  }

  return newMeasuresList;
}
