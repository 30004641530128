import React, {Fragment, useState, useEffect} from 'react';
import _ from 'lodash'; 

import  Checkboxes from './Checkboxes'
import {getSpecificEntryFromGroupsArray} from "../../../utils"

export default function ConnectionModal({oppositeRelationships, nestedMode, identry, callback, terms, passedConnectionData, passedOppositeRelationships}){

    /*const createConnectionData = (x) => {
        if (x) {console.log("createConnectionDataParam:",x); return _.cloneDeep(x);}
        else return [];
    }*/

    let [connectionData, setConnectionDataDirect] = useState(new Array(oppositeRelationships.length));

    useEffect(() => setDefaultState(), [identry])

    let findSelectedEntries = (relevantEntries, relevantConnectionsString = passedConnectionData) => {

        let connectionsArr = relevantConnectionsString.split(',');
        let finalArr = [];

        console.log("findSelectedEntries connectionsArr:", connectionsArr)

        for (var i = 0; i < relevantEntries.length; i++){
            console.log("findSelectedEntries relevantEntries["+i+"]", relevantEntries[i])
            let id = relevantEntries[i];
            if (connectionsArr.indexOf(id+"") !== -1){
                console.log("findSelectedEntries found id in connectionsArr");
                finalArr.push(id)
            }

        }

        console.log("findSelectedEntries finalArr", finalArr);
        return finalArr.join(',');
    }

    let setDefaultState = () => {
        if (Array.isArray(passedConnectionData)){
            setConnectionDataDirect(_.cloneDeep(passedConnectionData));
        } else if (typeof passedConnectionData == "string"){
            let newConnectionData = new Array(oppositeRelationships.length);
            for (var i = 0; i < newConnectionData.length; i++){
                let groupIds = oppositeRelationships[i].entries.map( (entry) => { return entry[terms.opposite.identry] })
                
                console.log("setDefaultState strings to overlap: passedConnectionData", passedConnectionData, "groupIds", groupIds)
                newConnectionData[i] = findSelectedEntries(groupIds);
                console.log("setDefaultState strings found overlap:", newConnectionData[i])
            }

            setConnectionDataDirect(newConnectionData);
        }

    }


    let setConnectionData = (index, value, useCallback = true) => {
        console.log("passedConnectionData setConnectionData called with params index", index, "value,", value, "useCallback", useCallback, "and initial value of connectionData", connectionData)
        

        setConnectionDataDirect((prev) => {

            let cloneArr = _.cloneDeep(prev);

            console.log("passedConnectionData setConnectionData cloneArr["+index+"]:", cloneArr[index], "truthy or falsy,", !!cloneArr[index]);

            //if (!cloneArr[index]){
                cloneArr[index] = value + ""
            //} else {
            //    cloneArr[index] += "," + value
            //}

            console.log("passedConnectionData setConnectionData cloneArr:", cloneArr);

            cloneArr[index] = _.uniq(cloneArr[index].split(',')).join(',')

            if (callback && useCallback){
                console.log("setConnectionData callback being called with cloneArr", cloneArr, "which joins into", cloneArr.join());
                
                let toReturn = ""
                for (var i = 0; i < cloneArr.length; i++){
                    if (cloneArr[i] !== undefined){
                        toReturn += cloneArr[i] + ","
                    }
                }

                callback(toReturn);
            }

            return cloneArr
        });
       
    }

    /*let setDefaultState = () => {
        setConnectionDataDirect([])
        console.log("setDefaultState firing with passed value", passedConnectionData, "and oppositeRelationships", oppositeRelationships)
        let passedConnectionArr = [];

        if (typeof passedConnectionData == "string"){
          passedConnectionArr = passedConnectionData.split(',');
        } else if (Array.isArray(passedConnectionData)){
            passedConnectionArr = _.cloneDeep(passedConnectionData)
        } else if (typeof passedConnectionData == "number"){
            passedConnectionArr = [passedConnectionData]
        }

        console.log("setDefaultState useEffect array", passedConnectionArr)

        for (var i = 0; i < passedConnectionArr.length; i++){
            let connection = parseInt(passedConnectionArr[i]) !== NaN ? parseInt(passedConnectionArr[i]) : passedConnectionArr[i];

            console.log("setDefaultState connection:", connection);

            for (var j = 0; j < oppositeRelationships.length; j++){
                console.log("setDefaultState j loop")
                let group = oppositeRelationships[j];
                let groupIds = group.entries.map((entry) => {
                    let id = entry[terms.opposite.idkey];
                    if (parseInt(id) != NaN){
                        return parseInt(id);
                    } else {
                        return id;
                    }
                });

                console.log("setDefaultState passedConnectionData groupIds:", groupIds);

                let connectionIndex = groupIds.indexOf(connection);

                console.log("setDefaultState passedConnectionData connectionIndex:", connectionIndex)

                if (connectionIndex != -1){
                    setConnectionData(j, connection, false)
                }
               
            }
        }
    }*/

    useEffect(() => {
        if (callback){
            callback(connectionData);
        }

    }, [connectionData])

    return(<div className="modal fade" style={nestedMode ? {zIndex: '5000', backgroundColor: "rgba(84,84,84,0.3)"} : null} id="connect-practices" tabindex="-1" role="dialog" aria-labelledby="connect-actions" aria-hidden="true">
        <div className="modal-dialog modal-long" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="connect-practices">Connect {terms.opposite.displayPlural}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">

                    {_.isEmpty(oppositeRelationships) ?
                        <p className="strong fs-14 mb-2">No {terms.opposite.displayPlural} to connect</p>
                    :<>
                        <p className="strong fs-14 mb-2">Select {terms.opposite.displayPlural} to associate with this {terms.mode.display}:</p>
                        <div class="accordion accordion-light accordion-arrow-right" id="actions-list">
                            {oppositeRelationships.map((group, groupIndex) => { return(<div class="card" key={groupIndex}>
                                <p class="card-title fs-16 strong">
                                    <a class="p-0" data-toggle="collapse" href={"#collapse-ml-"+groupIndex}>{group.name}</a>
                                </p>

                                <div id={"collapse-ml-"+groupIndex} class="collapse" data-parent="#actions-list">
                                    <div class="card-body">

                                        <Checkboxes
                                            name={"connection-"+groupIndex}
                                            onChange={(val) => {setConnectionData(groupIndex, val)}}
                                            value={connectionData[groupIndex]}
                                            wrapperClassName="custom-controls-stacked ml-6"
                                            defaultCheckboxVal={
                                                typeof passedConnectionData == "string" ?
                                                    connectionData[groupIndex]
                                                : Array.isArray(passedConnectionData) ?
                                                    passedConnectionData[groupIndex]
                                                : null
                                            }
                                            divClassName="custom-control custom-checkbox mb-1"
                                            labelClassName="custom-control-label fs-16"
                                            buttons={group.entries.map((item) => {
                                                return {label: item.name, 
                                                    value: item[terms.opposite.idkey] + "",
                                                    forceUseValue: true}
                                            })}
                                        />

                                        {/*group.entries.map((entry, entryIndex) => {return(
                                            <div class="custom-controls-stacked ml-6">

                                                <div class="custom-control custom-checkbox mb-1" onClick={(e) => this.setSelectedEntry(e, groupIndex, entryIndex, entry.selected)}>
                                                    <input type="checkbox" className="custom-control-input"
                                                      checked={entry.selected}
                                                    />
                                                    <label class="custom-control-label fs-16">{entry.name}</label>
                                                </div>
                                            </div>
                                        )})*/}

                                    </div>
                                </div>
                            </div>)})}
                        </div>
                    </>}
                    
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Apply Connections</button>
                </div>
            </div>
        </div>
    </div>)
}