import { url } from '../constants'
import JwtDecode from 'jwt-decode'

export const StakeholdersService = {
  getStakeholdersBySiteAndWorkGroup,
  getStakeholdersByWorkGroup,
  saveNewStakeholders,
  deleteStakeholder,
}

async function getStakeholdersBySiteAndWorkGroup(idsite, idworkgroup){
  const resp = await fetch(`${url.backend}/stakeholders/getStakeholdersBySiteAndWorkGroup/${idsite}/${idworkgroup}`).then(handleResponse)
  return resp.stakeholders
}

async function getStakeholdersByWorkGroup(idworkgroup){
  const resp = await fetch(`${url.backend}/stakeholders/getStakeholdersByWorkGroup/${idworkgroup}`).then(handleResponse)
  return resp.stakeholders
}

async function saveNewStakeholders(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/stakeholders/saveNewStakeholders/`, requestOptions)
        .then (handleResponse);
}

async function deleteStakeholder(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/stakeholders/deleteStakeholder/`, requestOptions)
        .then (handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
