import React, {Component, Fragment} from 'react'
import StakeholdersModal from '../common/StakeholdersModal';
import SuccessPopup from '../common/Popups'
import { url } from '../../constants';
import ViewEntryModal from '../common/ViewEntryModal';
import moment from 'moment';
import _ from 'lodash';
import {
   // AspectMeasuresService,
    ReportsService,
   // WorkGroupsService
} from '../../services'

import panels from '../../constants/panels'
import { Link } from 'react-router-dom'
import {
  isMobileOnly,
  isTablet
} from 'react-device-detect'

export default class ViewReport extends Component {

	constructor(props){
        super(props);

        let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));
        let idreport = JSON.parse(localStorage.getItem('activeReport'));


        this.state = {
            activeMeasureGroup: 0,
            activeMeasureEntry: 0,
            activeAspectGroup: 0,
            activeAspectEntry: 0,
            allMeasuresMarked: true,
            report: {status: null, date: null, siteName: null, employeesList: [], groupsList: [], measuresList: []},
            workUnit,
            idreport,
            idaspects: [true],
            sendingData: false,
            showPastReportPopup: false,
            showSuccessPopup: false,
        }
    }

    componentWillMount = async () => {
    	this.loadReport()
    }

    loadReport = async() => {
    	let {idreport} = this.state;
    	let report = await ReportsService.getReport(idreport);
    	this.setState({report});

    	let idaspects = [];
    	let {groupsList, measuresList, acknowledgements} = report;
    	let allMeasuresMarked = true;

        //Extract selected idaspects from groupsList

        for (var i = 0; i < groupsList.length; i++){

        	if (groupsList[i].entries){
        		//console.log("Entries at index " + i);
	            for (var j = 0; j < groupsList[i].entries.length; j++){
	                let entry = groupsList[i].entries[j]
	                console.log("Entry: ", entry);
                	console.log("Pushing entry ", entry);
                    idaspects.push(entry.idaspect)
	            }
            } else {
            	//console.log("No entries for group at index " + i);
            }
        }


        //Determine if measures are all marked or not
        if (!_.isEmpty(measuresList) > 0){
	        for (var i = 0; i < measuresList.length; i++){
	            let {entries} = measuresList[i]

	            if (!_.isEmpty(entries)){

	                for (var j = 0; j < entries.length; j++){
	                	if (entries[j].status == null) allMeasuresMarked = false;
	                }

	            }

	        }
        } else {
        	allMeasuresMarked = false;
        }

        this.setState({idaspects, allMeasuresMarked});
    }

    setActiveEntry = (activeAspectGroup, activeAspectEntry) => {
    	this.setState({activeAspectGroup, activeAspectEntry})
    }

    sendSMS = async () => {
    	let {employeesList, createdby} = this.state.report;
    	let showSuccessPopup = true;
    	let showPastReportPopup = false;

    	if (_.isEmpty(employeesList) || (!_.isEmpty(employeesList) && employeesList.length == 1 && employeesList[0].idemployee.toLowerCase() == createdby.toLowerCase())){
    		showSuccessPopup = false;
    		showPastReportPopup = true;
    	}

    	this.setState({sendingData: true});
    	await ReportsService.startReport(this.state.idreport);
    	this.setState({sendingData: false, showSuccessPopup, showPastReportPopup}, async () => {
    		await this.loadReport();
    	});
    }

    goToEditReport = async (idreport) => {
        await localStorage.setItem("activeReportToEdit", this.state.idreport);
        this.props.history.push("/app/report-setup");
    }

	render = () => {
		let {acknowledgementMode} = this.props;
		let {workUnit, idaspects, allMeasuresMarked} = this.state;
		let {date, status, siteName, idsite, idreport, employeesList, groupsList, measuresList, notes, acknowledgements, creator} = this.state.report;
		let showErrors = (_.isEmpty(idaspects) || !allMeasuresMarked)

		return(<div>
		
			<header className="section-header mt-5 mb-0">
				<h2><strong>{date ? "Report For " + moment(date).add('days', 1).format("MMMM D, YYYY") : "Crew Huddle Report"}</strong></h2>
				{/*<button class="btn btn-success" onClick={this.shareReport}>Share</button>*/}
				{/*<p className="lead strong">January 18, 2021</p>*/}
			</header>
			<div className={"section pt-0" + (acknowledgementMode ? " pb-0" : "")}>
				<div className="container px-0">
					<div className="row">

						{!acknowledgementMode ? <div class="col-12 mx-auto">
							<div class="ml-8 text-left float-left">
								<Link class="btn btn-sm btn-secondary" to={() => panels.get('huddle-reports').path}><i class="fa fa-angle-left mr-1"></i>Huddle Reports</Link>
								{/*<button type="button" class="btn btn-sm btn-secondary">Recent<i class="fa fa-angle-right ml-1"></i></button>*/}
							</div>
							<div class="text-right float-right">
								{/*<button type="button" class="btn btn-sm btn-light"><i class="ti ti-import mr-1" aria-hidden="true"></i>Download</button>*/}
								{status == "finished" && (workUnit && workUnit.role !== "employee") ? <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#stakeholders"><i class="ti ti-share mr-1" aria-hidden="true"></i>Share</button> : null}
							</div>
						</div> : null}

						<div className="col-12 col-lg-10 mx-auto border-top my-4 pt-4">

							<div className="col-12 col-lg-5 mb-4">
								<div className="col-11 col-lg-10 p-0 m-0">
									<p className="lead-3 p-0 mb-0 ml-2 pl-2">{workUnit.name}</p>
								</div>
							</div>

							<div className="col-12 mt-2 clearfix">
								<div className="col-12 col-lg-5 float-left">
								<i className="fa fa-map-marker float-left mt-2 fs-18" aria-hidden="true"></i>
									<div className="col-11 col-lg-10 p-0 m-0 float-left">
										<p className="strong fs-16 p-0 mb-0 ml-2 pl-2">{siteName ? siteName : "No site selected"}</p>
									</div>
								</div>
								<div className="col-12 col-lg-7 float-left">
									<i className="fa fa-calendar float-left mt-2 fs-18" aria-hidden="true"></i>
									<div className="col-11 col-lg-6 p-0 m-0 float-left">
										<p className="strong fs-16 p-0 mb-0 ml-2 pl-2">{date ? moment(date).add('days', 1).format("MMMM D, YYYY") : "No date selected"}</p>
									</div>
								</div>
							</div>

							<div className="col-12 mt-2 pb-2 border-bottom">
								<div className="col-12 col-lg-6 pl-1 mb-2 clearfix">
									<p className="mt-4 mb-1 fs-17 float-left"><i className="fa fa-users px-2" aria-hidden="true"></i><strong>Employees</strong></p>
								</div>

								<div className="col-12 ml-5">
									<ul className="list-unstyled card-columns">
										{employeesList.length > 0 ? 
												employeesList.map((employee, index) => {
													return <li className="strong" key={index}>
														{(status.indexOf('setup') == -1) ? employee.acknowledged == 1 ?
															<i className="ti ti-check strong mr-2 text-success" aria-hidden="true"></i>
														: employee.acknowledged == 0 ?
															<i className="ti ti-close strong mr-2 text-danger" aria-hidden="true"></i>
														: <i className="ti ti-user strong mr-2 text-light" aria-hidden="true"></i> : null }
														{employee.name || employee.idemployee}
														
													</li>
												})
											:
												<li className="ml-5"><em>None selected</em></li>
										}
									</ul>
								</div>
							</div>

							<div className="col-12 pb-0 mb-0 ml-2 clearfix">
								<p className="mt-4 mb-0 fs-17 float-left"><i className="fa fa-cube px-2" aria-hidden="true"></i><strong>Relevant Aspects</strong></p>
							</div>  

							{groupsList.map((group, groupIndex) => {
								return(<div className="col-10 pl-0 mb-5 ml-7">
									<p className="fs-16 mt-4 mb-2"><strong>{group.name}</strong></p>
									{
										group.entries && group.entries.length > 0 ? <ul className="card-columns">
												{group.entries.map((entry, entryIndex) => {
													return <li key={entryIndex}><a className="text-primary strong" data-toggle="modal" data-target="#view-aspect" href="#" onClick={() => this.setActiveEntry(groupIndex, entryIndex)}>{entry.name}</a></li>
												})}
											</ul>:
												 <p className="ml-5"><em>None selected</em></p>
									}
																			
								</div>)

							})}      

						</div>

						<div className="col-12 col-lg-10 mx-auto border-top">
							<div className="col-12 mt-2 pb-4 mb-4 border-bottom">
								<p className="mt-4 mb-0 fs-17"><i className="fa fa-cube px-2" aria-hidden="true"></i><strong>Measures Connected to Your Aspects</strong></p>
							</div> 
							<div className="col-12 mt-2">
								<p className="fw-400 fs-14 mb-2 ml-lg-6">Measure Review:</p>
							</div>    
						</div>

						<div className="col-12 col-lg-10 mb-4 ml-7">
							{measuresList.map(this.renderMeasureGroup)}
						</div>

	                    <div className="col-lg-8  ml-lg-9 mb-4 float-left">
	                        <p className={"mt-4 " + (notes ? "mb-2" : "")}>
	                        	<span className="fs-16 strong mb-0">Additional Considerations</span>
	                        	
	                        	<><br /><span>{!notes ? "None Reported" : notes}</span><br /><br /><i>Created by {creator}</i></>
	                        </p>

		                    {acknowledgements && !_.isEmpty(acknowledgements) ? 
		                    	acknowledgements.map(({acknowledged, notes, name}, index) => {
		                    		if (acknowledged === 1 || acknowledged === "1"){
		                    			return this.renderNotes(notes, name, "")
		                    		} else return null;
		                    	})
		                    : null}
	                      
	                    </div>

	                    {!acknowledgementMode && _.isEmpty(acknowledgements) && status !== "open" && status !== "finished" ? <>
	                    	
	                    	{isMobileOnly ? 
	                    		<div className={"col-12 mx-auto mb-6 pt-4 border-top" + (showErrors ? "" : "mb-6")}>
		                           <button className="btn btn-secondary btn-block my-4" href="#" onClick={this.goToEditReport}><i className="fa fa-angle-left mr-1"></i> BACK</button>

		                    		<Link className="btn btn-outline-primary btn-block my-4" onClick={() => this.setState({sendingData: true})} disabled={this.state.sendingData} to={() => panels.get('huddle-reports').path}>
		                    			{this.state.sendingData ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
				                       	Save for Later
				                    </Link>
				                    <button type="button" className="btn btn-block btn-primary my-4" href="#" disabled={this.state.sendingData || showErrors} onClick={this.sendSMS}>
				                        {this.state.sendingData ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
				                       	SEND HUDDLE TO CREW
				                    </button>
		                        </div>


	                    	:
		                    	<div className={"col-lg-10 mx-auto flexbox pt-4 border-top" + (showErrors ? "" : "mb-6")}>
		                    		<button className="btn btn-secondary" href="#" onClick={this.goToEditReport}><i className="fa fa-angle-left mr-1"></i> BACK</button>

		                    		<Link className="btn btn-outline-primary" onClick={() => this.setState({sendingData: true})} disabled={this.state.sendingData} to={() => panels.get('huddle-reports').path}>
		                    			{this.state.sendingData ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
				                       	Save for Later
				                    </Link>
				                    <button type="button" className="btn btn-primary" href="#" disabled={this.state.sendingData || showErrors} onClick={this.sendSMS}>
				                        {this.state.sendingData ? <img className="mr-1" src="../img/app/photoswipe/preloader.gif" /> : null}
				                       	SEND HUDDLE TO CREW
				                    </button>
				                </div>
				            }

			                {showErrors ? 
			                	<div className="col-lg-10 mx-auto mb-6 text-right">
	                    			{_.isEmpty(idaspects) ?
	                    				<p className="text-right small"><i>You must add aspects and mark all of your measures before proceeding</i></p>
	                    			: !allMeasuresMarked ?
	                    				<p className="text-right small"><i>You must mark all of your measures before proceeding</i></p>
	                    			:null}
			                	</div>
	                    			
		                	: null }

		                </> : null}

	                    {/*!acknowledgementMode && acknowledgements && !_.isEmpty(acknowledgements) && acknowledgements[0].token ? 
	                    	acknowledgements.map(({name, token}, index) => {
								return <p><a href={"/huddle-acknowledgement/"+token}>
		                    		Super secret tester-only acknowledgement test link to acknowledge as {name}!
		                    	</a></p>
	                    	})

	                    : "No super-secret acknowledgement test links to see here. Maybe the only employee added was the creator, or this is a huddle from before acknowledgements were a thing?"*/}


					</div>
				</div>

			</div>

			{this.renderMeasureModal()}
  			{this.renderViewAspect()}

  			{!acknowledgementMode && (workUnit && workUnit.role !== "employee") ? <StakeholdersModal
                idsite={idsite}
                siteName={siteName}
                reportsData={{idreport}}
                pdfMode="single"
            /> : null}

            <SuccessPopup
	        	trigger={this.state.showPastReportPopup}
	        	popupId="reportWillBeClosed"
	        	style="info"
	        	useButton={false}
	        	title={"Report Complete"}
	        	message={"This report will be in Past Reports, since no employees need to acknowledge it"}
	        	callback={() => this.setState({showPastReportPopup: false})}
	        />

	        <SuccessPopup
	        	trigger={this.state.showSuccessPopup}
	        	popupId="huddleHasBeenStarted"
	        	style="success"
	        	useButton={false}
	        	title={"Huddle started!"}
	        	message={"Messages have been sent out to your crew for acknowledgement."}
	        	callback={() => this.setState({showSuccessPopup: false})}
	        />

		</div>)
	}

	renderNotes = (notes, name) => {
		return <p className="">{notes ? <>{notes} <br /></> : null} - <i>{name}</i></p>
	}

	 renderMeasureGroup = (group, groupIndex) => {

        return(<div className={"col-lg-5 pl-0 mb-2 float-left ml-lg-" + (groupIndex%2 == 0 ? "8" : "3")} key={groupIndex}>
            <p className="fs-16 mt-4 mb-2"><strong>{group.name}</strong></p>

            {group.entries ? <Fragment>
                {group.entries.map((entry, index) => this.renderMeasureEntry(entry, index, groupIndex))}
            </Fragment>: <p className="ml-5"><em>No measures from this group</em></p>}
        </div>)
    }

    renderMeasureEntry = (entry, index, groupIndex) => {
        let {status, name} = entry;
        return (
            <p className={"p-0 m-0" + (status == null ? " ml-6" : '')}>

                {status == "acknowledged" ? 
                    <i className="ti ti-check text-success strong pr-4" aria-hidden="true"></i>
                : status == "na" ? 
                    <i className="ti ti-na text-danger strong pr-4" aria-hidden="true"></i>
                : 
                    null
                }

                <a className={"text-primary" + (status == null ? "" : " strong")} data-toggle="modal" data-target="#mark-measure" href="#"
                    onClick={() => this.setState({activeMeasureGroup: groupIndex, activeMeasureEntry: index})} >{name}</a>
            </p>
        )
    }

    renderViewAspect = () => {
    	let {groupsList} = this.state.report;
        let {activeAspectEntry, activeAspectGroup} = this.state;
        let group = groupsList[activeAspectGroup];

        if (!group) return null;

        let entry = null;
        if (group.entries){
	        entry = group.entries[activeAspectEntry];

	        if (!entry) return null;
        } else return null;

        return(
            <ViewEntryModal
                id="view-aspect"
                entry={entry}
                capitalizedModeName={"Aspect"}
                oppositeModeName={"Measure"}
             >
            <button type="button" data-dismiss="modal" aria-label="Close" className="btn btn-primary">Done</button>
        </ViewEntryModal>)
    }

    renderMeasureModal = () => {
        let {measuresList} = this.state.report;
        let {activeMeasureGroup, activeMeasureEntry, idaspects} = this.state;
        let group = measuresList[activeMeasureGroup];

        if (!group) return null;

        let entry = null;
        if (group.entries){
	        entry = group.entries[activeMeasureEntry];

	        if (!entry) return null;
        } else return null;

        if (!entry) return null;

        //console.log("renderMeasureModal entry:", entry);

        return(<ViewEntryModal
                id="mark-measure"
                entry={entry}
                capitalizedModeName={"Measure"}
                oppositeModeName={"Aspect"}
                filterIds = {idaspects}
             >
            <button type="button" data-dismiss="modal" aria-label="Close" className="btn btn-primary">Done</button>
        </ViewEntryModal>)
    }

}