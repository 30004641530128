import React, {useState, useEffect, Fragment} from 'react'
import SkillsManager from './SkillsManager'
import JwtDecode from 'jwt-decode'
import {
    WorkGroupsService
} from '../../../services'
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal'
import UpdateCrewMemberDetails from './UpdateCrewMemberDetails'

export default function AddCrewMember({unit, closeModal}){

    const userJWT = localStorage.getItem('token')
    const userDecoded = JwtDecode(userJWT);
    const creatorName = userDecoded ? userDecoded['name'] : null

    let defaultEmployeeObj = {idemployee: "", name: "", nameInvalid: false, phonenumber: "", role: "default", workRole: "", skills: []}

	let [employee, updateEmployee] = useState(_.cloneDeep(defaultEmployeeObj));
    let [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
	//let [skillsList, setSkillsList] = useState([{name: ""}])

	let updateEmployeeVals = (key, val) => {
        let clone_employee = _.cloneDeep(employee);
        clone_employee[key] = val;
        updateEmployee(clone_employee);
    }

    let submitEmployee = async () => {
         let splitName = _.split(employee.name, " ");

        if (splitName.length < 2){
            let clone_employee = _.cloneDeep(employee);
            clone_employee.nameInvalid = true;
            updateEmployee(clone_employee);
        } else {
            let res = await WorkGroupsService.sendInvite({employee, unit, creatorName});
            closeModal();
        }
    }

    useEffect(() => {

        let calcedSaveButtonDisabled = true;
        //let clone_employee = _.cloneDeep(employee)

        if (employee.name !== "" && employee.workrole !== "" && employee.role !== "default" && employee.phonenumber !== "" && employee.idemployee !== ""){
            calcedSaveButtonDisabled = false;
        }

        if (calcedSaveButtonDisabled != saveButtonDisabled){
            setSaveButtonDisabled(calcedSaveButtonDisabled);
        }

    }, [employee.name, employee.phonenumber, employee.role, employee.workrole, employee.idemployee])

    return <>

        <Modal.Header>
            <Modal.Title className="p strong fs-16" closeButton>Invite User</Modal.Title>
            <button type="button" class="close" onClick={closeModal} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </Modal.Header>

        <Modal.Body className="p-0">
              
            <div class="card-body">

                <div class="form-group pb-5">
                    <div class="col-12 p-0 border-bottom mb-4">
                        <label class="strong">Enter their contact info and roles:</label>
                    </div>
                    <div class="row mx-1 ml-md-2 gap-x">

                        <UpdateCrewMemberDetails
                            crewMember={employee}
                            callback={updateEmployeeVals}
                            existingCrewMember={false}
                        />

                    </div>
                </div>
                
                <SkillsManager
                    passedEmployee={employee}
                    updatePassedEmployee={updateEmployee}
                    useExistingEmployee={false}
                />

            </div>

        </Modal.Body>

        <Modal.Footer>
          <button class="btn btn-sm btn-secondary float-right mx-2" onClick={closeModal}>Close</button>
          <button class="btn btn-sm btn-primary float-right mx-2" disabled={saveButtonDisabled} onClick={submitEmployee}>SAVE</button>
        </Modal.Footer>
    </>
}