/*This function is intended for loading report data which is already fetched from the backend into the report setup components*/
import _ from 'lodash';

export function chLoadReport({
	employeesList = [], 
	employeeIDs = [], 
	groupsList = [], 
	sitesList = [],
    measuresList = [],
    includeMeasures = false,
	loadedReportData = {},
	idreport = null,
    partialLoad = false,
}){

    console.log("chLoadReport loadedReportData:", loadedReportData)

    //console.log("activeReportToEdit chLoadReport params:",employeesList, employeeIDs, groupsList, sitesList, measuresList, includeMeasures, loadedReportData, idreport)

	let returnData = {} //keep the names here consistent with components/Reports/ReportSetup/index.js state variables

    if (!partialLoad && loadedReportData.idreport){
        returnData.idreport = loadedReportData.idreport;
    } else {
        returnData.idreport = null;
    }

	if (!_.isEmpty(loadedReportData.employeesList) && !_.isEmpty(employeesList) && !_.isEmpty(employeeIDs)){
		returnData.employeesList = loadReportEmployees(employeesList, loadedReportData.employeesList, employeeIDs);
	}

	if (!_.isEmpty(loadedReportData.groupsList) && !_.isEmpty(groupsList)){
		returnData.groupsList = loadReportAspectMeasures(groupsList, loadedReportData.groupsList, "idaspect");
	}

    if (!_.isEmpty(loadedReportData.measuresList) && !partialLoad){
        console.log("1140 loaded report data measures list:", loadedReportData.measuresList);

        let loadMeasures = true;
        if (loadedReportData.status == "setup_create"){
            let entriesFound = false;

            for (var i = 0; i < loadedReportData.measuresList.length; i++){
                console.log("1140 loadedReportData.measuresList["+i+"].entries:", loadedReportData.measuresList[i].entries);
                if (!_.isEmpty(loadedReportData.measuresList[i].entries)) entriesFound = true;
            }

            console.log("1140 entriesFound:", entriesFound);

            if (entriesFound) loadMeasures = true;
            else loadMeasures = false;
        }

        if (loadMeasures){
            console.log("1140 loading measures");
            returnData.measuresList = _.cloneDeep(loadedReportData.measuresList);
        } else {
            console.log("1140 Not loading measures");
        }
    }

    if (loadedReportData.idsite && !_.isEmpty(sitesList)){
        returnData.idsite = loadedReportData.idsite;
        for (var i = 0; i < sitesList.length; i++){
            if (sitesList[i].idsite == loadedReportData.idsite){
                returnData.name = sitesList[i].name;
            }
        }
    }

    if (!_.isEmpty(sitesList)){
        returnData.sitesList = sitesList;
    }

    if (loadedReportData.status && !partialLoad){
        returnData.phase = loadedReportData.status.split('_')[1];
    } else {
        returnData.phase = "create"
    }

    if (loadedReportData.date && !partialLoad){
        console.log("1143 loadedReportData.date:", loadedReportData.date)
        console.log("1143 loadedReportData.date typeof:", typeof loadedReportData.date);

        let date = new Date(loadedReportData.date);

        let utcConvertedDate =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        console.log("1143 utcConvertedDate;", utcConvertedDate);

        let now = new Date();


        var final = new Date(utcConvertedDate + now.getTimezoneOffset() * 60000);

        console.log("1143 final date to return:", final);

        returnData.startDate = final;
    }

    if (loadedReportData.notes && !partialLoad){
        returnData.notes = loadedReportData.notes
    }

    if (loadedReportData.createdby && !partialLoad){
        returnData.createdby = loadedReportData.createdby;
    }

	return returnData;

}

function loadReportEmployees(passedStateEmployeesList, passedLoadedEmployeesList, passedEmployeeIDs){

	let stateEmployeesList = _.cloneDeep(passedStateEmployeesList);
	let employeeIDs = _.cloneDeep(passedEmployeeIDs);
	let loadedEmployeesList = _.cloneDeep(passedLoadedEmployeesList);

    for (var i = 0; i < stateEmployeesList.length; i++){
        stateEmployeesList[i].selected = false;
    }

    for (var i = 0; i < loadedEmployeesList.length; i++){
        let loadedEmployee = loadedEmployeesList[i];
        
        let loadedEmployeeIndex = employeeIDs.indexOf(loadedEmployee.idemployee);

        if (loadedEmployeeIndex !== -1){
            stateEmployeesList[loadedEmployeeIndex].selected = true;
        }
    }

	return stateEmployeesList;
}

function loadReportAspectMeasures(passedStateGroupsList, passedLoadedGroupsList, id){
	let stateGroupsList = _.cloneDeep(passedStateGroupsList);
	let loadedGroupsList = _.cloneDeep(passedLoadedGroupsList);

    console.log("stateGroupsList:", stateGroupsList);
    console.log("loadedGroupsList:", loadedGroupsList);

    for (var i = 0; i < stateGroupsList.length; i++){
        for (var j = 0; j < stateGroupsList[i].entries.length; j++){
            stateGroupsList[i].entries[j].selected = false;
        }
    }


	let loadedGroups = loadedGroupsList.map(({name}) => {return name})

    for (var i = 0; i < stateGroupsList.length; i++){

        //First, get the corresponding group from the old report data
        let index = loadedGroups.indexOf(stateGroupsList[i].name);
         console.log("groupsList index of " + stateGroupsList[i].name+ ":",index)
        

        //Then, if and only if that group has any entries, iterate through the entries
        if (index !== -1 && loadedGroupsList[index].entries !== undefined){

            if (id == "idaspect"){
                markAspects(stateGroupsList, loadedGroupsList, id, i, index)
            } else {
                markMeasures(stateGroupsList, loadedGroupsList, id, i)
            }

        } else {
            console.log("groupsList setting all in group to false")
            for (var j = 0; j < stateGroupsList[i].entries.length; j++){
                stateGroupsList[i].entries[j].selected = false;
            }
        }

    }


    return stateGroupsList;

}

function markAspects(stateGroupsList, loadedGroupsList, id = "idaspect", i, index){
    //alert("Calling markAspects")
    let oldAspectIDs = loadedGroupsList[index].entries.map((entry) => {return entry[id]});
    console.log("groupsList oldAspectIDs:", oldAspectIDs);

    for (var j = 0; j < stateGroupsList[i].entries.length; j++){
        let aspectIndex = oldAspectIDs.indexOf(stateGroupsList[i].entries[j][id]);
        console.log("groupsList aspectIndex is "+aspectIndex+" for entry ",stateGroupsList[i].entries[j]);
        if (aspectIndex !== -1){
            console.log("groupsList selected is true");
            stateGroupsList[i].entries[j].selected = true;
        } else {
            console.log("groupsList selected is false");
            stateGroupsList[i].entries[j].selected = false;
        }
    }
}

function markMeasures(stateGroupsList, loadedGroupsList, id = "idmeasure", i){
    //alert("Calling markMeasures");
    for (var j = 0; j < stateGroupsList[i].entries.length; j++){
        stateGroupsList[i].entries[j].status = loadedGroupsList[i].entries[j].status;
    }
}