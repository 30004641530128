import React, {Fragment, Component} from 'react';
import BaseGuideModal from './BaseGuideModal';

export default class SetupGuideModal extends Component {

  constructor(props){

    super(props)

    this.state={
      pages: [
        {
          img: "../../img/onboard/modal-1.png",
          title: <strong>Welcome to Assured Deliveries</strong>,
          text: <span>Assured Deliveries tracks haul projects by connecting two <strong>Sites</strong> through an <strong>Agreement</strong> between two <strong>Partners</strong>.</span>,
        },
        {
          img: "../../img/onboard/modal-2.png",
          title: <strong>Get Started with My Sites & Agreements</strong>,
          text: <span>Once that is set up, the map will display your Sites with an overview of <strong>real-time haul activity for each material</strong> on every Agreement.</span>,
        },
        {
          img: "../../img/onboard/modal-3.png",
          title: <strong>Site Teams will handle Tickets</strong>,
          text: <span>You and others on your <strong>Site Team</strong> can <strong>open, view, and/or close</strong> tickets on all agreements related to your Site.</span>,
        },
        {
          img: "../../img/onboard/modal-3-2.png",
          title: <strong>Quick Start Guide</strong>,
          text: <span>For more details to help you get started, check out the Quick Start Guide under the Support menu. It will guide you through the app based on your role on a Site Team.</span>,
        },
      ]
    }
  }

  render(){

    return(<Fragment>

      <BaseGuideModal id="setup" markAsSeen={this.props.markAsSeen} pages={this.state.pages} />

    </Fragment>)
  }

}