import React, {useState, useEffect, Fragment} from 'react'
import JwtDecode from 'jwt-decode'
import Combobox from '../../common/Combobox'
import DatePicker from 'react-datepicker' 
import SuccessPopup from '../../common/Popups';
import "react-datepicker/dist/react-datepicker.css"
import {
    WorkGroupsService
} from '../../../services'
import moment from 'moment';
import _ from 'lodash';

export default function SkillsManager({passedEmployee, updatePassedEmployee, useExistingEmployee}){

    let defaultSkillsObj = {skill: {name: ""}, level: "", startDate: null, expiryDate: null}
    let levelsList = ["O: Unsatisfactory skill &/or experience level", "I: Has extensive op time and job experience", "II: Has limited op time and some job experience"]


    let [skillsList, setSkillsList] = useState([])
    let [skillSuccess, setSkillSuccess] = useState(false);
    let [employee, updateEmployee] = useState({})
    let [whichEmployeeSkillsArray, setWhichEmployeeSkillsArray] = useState('skills')

    let loadSkillsList = async () => {
        setSkillsList(await WorkGroupsService.getSkills())
    }

    useEffect(() => {
        loadSkillsList();
    }, [])

    useEffect(() => {
        let clone_passedEmployee = _.cloneDeep(passedEmployee)
        if (JSON.stringify(employee) !== JSON.stringify(clone_passedEmployee) && clone_passedEmployee != undefined){
            if (_.isEmpty(clone_passedEmployee[whichEmployeeSkillsArray])){
                clone_passedEmployee[whichEmployeeSkillsArray] = [_.cloneDeep(defaultSkillsObj)];
            }
            updateEmployee(clone_passedEmployee)
        }
    }, [passedEmployee])


    useEffect(() => {
        if (useExistingEmployee){
            setWhichEmployeeSkillsArray('newSkills');
        } else {
            setWhichEmployeeSkillsArray('skills')
        }
    }, [useExistingEmployee])


    let updateSkills = (index, key, val) => {

        console.log("updateSkills params:", index, key, val, useExistingEmployee)

        let clone_employee = _.cloneDeep(employee);

        if (key == "skill"){
            clone_employee[whichEmployeeSkillsArray][index].skill.name = val;
        } else {
            clone_employee[whichEmployeeSkillsArray][index][key] = val;
        }
        
        updateEmployee(clone_employee);
        updatePassedEmployee(clone_employee);
        
    }


    let addSkillRow = () => {
        let clone_employee = _.cloneDeep(employee);
        clone_employee[whichEmployeeSkillsArray].push(_.cloneDeep(defaultSkillsObj));
        updateEmployee(clone_employee);
    }

    let addSkills = async (index, val) => {
        console.log("addSkills val:", val);
        let clone_skillsList = _.cloneDeep(skillsList);
        clone_skillsList.push({name: val});
        updateSkills(index, "skill", val);
        setSkillsList(clone_skillsList)
        await WorkGroupsService.addSkill({skill: val});
    }

    let sendSkillsToDB = async () => {
        let res = await WorkGroupsService.editSkills(employee);

        if (res.success){
            setSkillSuccess(true);
        }

        updatePassedEmployee(_.cloneDeep(employee));
    }

	
    let toggleSkillToRemove = (index) => {
        let clone_employee = _.cloneDeep(employee);
        clone_employee.skills[index].toRemove = !clone_employee.skills[index].toRemove;
        updateEmployee(clone_employee)
        updatePassedEmployee(clone_employee)
    }

    let renderAddSkillsRows = () => {

        console.log("renderAddSkillsRows employee[whichEmployeeSkillsArray]:", employee[whichEmployeeSkillsArray])

        return((employee && employee[whichEmployeeSkillsArray] && !_.isEmpty(employee[whichEmployeeSkillsArray])) ? 
            employee[whichEmployeeSkillsArray].map(({skill, level, startDate, expiryDate}, i) => <Fragment key={i}>
                <div class="row mx-1 ml-md-2 gap-x">
                    <div class="col-12 col-lg-3 p-0 mr-1 ml-0 mb-1">
                        <Combobox
                            items={skillsList}
                            value={skill.name}
                            identifier="name"
                            onSelect={(value) => updateSkills(i, "skill", value.name)}
                            className="form-control fs-13"
                            renderItem={item => {
                                console.log("renderItem for " + item);
                                return item.name
                            }}
                            normalStyle={{backgroundColor: 'white'}}
                            hoverStyle={{backgroundColor: '#ddd'}}
                            filterOptions={(skill, val, other) => {
                                return skill && skill.name ? skill.name.toLowerCase().startsWith(val) : false;
                            }}
                            showAddOption={true}
                            onAddElement={(val) => addSkills(i, val)}
                            placeholder={"Search or Add a Skill"}
                            hideSelectedOptions={false}
                        />

                    </div>
                    <div class="col-12 col-lg-3 p-0 mr-1 ml-0 mb-1">
                        <div class="p-0 mr-1">
                            <select className="form-control fs-13" value={level} onChange={e => updateSkills(i, "level", e.target.value)}>
                                <option value={null}>Select Level</option>
                                <option value={1}>{levelsList[1]}</option>
                                <option value={2}>{levelsList[2]}</option>
                                <option value={0}>{levelsList[0]}</option>   
                            </select>
                        </div>
                    </div>
                    <div class="col-12 twonhalf-col p-0 mr-1 ml-0 mb-1">
                        <DatePicker
                            className={"form-control fs-13"}
                            dateFormat="MM/dd/yyyy"
                            selected = {startDate || null}
                            onChange={ date => updateSkills(i, "startDate", date) }
                            placeholderText="MM/DD/YY"
                        />
                    </div>
                    <div class="col-12 twonhalf-col p-0 mr-1 ml-0 mb-1">
                        <DatePicker
                            className={"form-control fs-13"}
                            dateFormat="MM/dd/yyyy"
                            selected = {expiryDate || null}
                            onChange={ date => updateSkills(i, "expiryDate", date) }
                            placeholderText="MM/DD/YY (Optional)"
                        />
                    </div>
                </div>
            </Fragment> )
        : null)
    }

    let renderViewSkillsRows = () => {

        if (!employee || _.isEmpty(employee.skills)){
            return <div className="col-lg-12 p-0"><p>No skills!</p></div>
        } else {

            return(employee.skills.map(({skillname, level, startDate, expiryDate, toRemove}, i) => <Fragment key={i}>
                <div class="row mt-2 ml-2 gap-x mb-4 mb-lg-0">
                    <div class={"col-lg-3 p-0 mr-1" + (toRemove ? " text-danger" : "")}>
                        <label class="mb-0 fs-14">{skillname}</label>
                    </div>
                    <div class={"col-lg-3 p-0 mr-1" + (toRemove ? " text-danger" : "")}>
                        <label class="mb-0">{levelsList[level]}</label>
                    </div>
                    <div class={"twonhalf-col p-0 mr-1" + (toRemove ? " text-danger" : "")}>
                        <label class="mb-0">{startDate == null ? "N/A" : moment(startDate).add('days', 1).format("MM/DD/YYYY")}</label>
                    </div>
                    <div class={"twonhalf-col p-0 mr-1" + (toRemove ? " text-danger" : "")}>
                        <label class="mb-0">{expiryDate == null ? "N/A" : moment(expiryDate).add('days', 1).format("MM/DD/YYYY")}</label>
                    </div>
                    <div class="p-0 mx-2 mt-0 float-right">
                        <a class={"small item-remove text-dark mt-1 float-right" + (toRemove ? " text-danger" : " text-dark")} href="#">
                            <i class="ti ti-trash" onClick={() => toggleSkillToRemove(i)}></i>
                        </a>
                    </div>
                </div>

            </Fragment>))
        }

    }


    return <>
      
        <div class="col-12 pl-0 ml-0 mb-3">
            <div class="col-12 p-0 border-bottom mb-3">
                <label class="strong">Enter their skills: (optional)</label>
            </div>
            <div class="row ml-2 gap-x border-bottom">
                <div class="col-5 col-lg-3 p-0 mr-1 ml-0 mb-1">
                    <label class="mb-0">Skill</label>
                </div>
                <div class="col-5 col-lg-3 p-0 mr-1 ml-0 mb-1">
                    <label class="mb-0">Level</label>
                </div>
                <div class="col-5 twonhalf-col p-0 mr-1 ml-0 mb-1">
                    <label class="mb-0">Start Date</label>
                </div>
                <div class="col-5 twonhalf-col p-0 mr-1 ml-0 mb-1">
                    <label class="mb-0">Expiration Date</label>
                </div>
            </div>
        </div>

        {useExistingEmployee ? renderViewSkillsRows() : null}

        <div class="form-group mt-3">
            {renderAddSkillsRows()}
            <button class="btn btn-circle btn-sm  btn-outline-secondary mb-4 mb-lg-0 ml-lg-4 px-4 mt-4" onClick={addSkillRow}>
                <i class="fa fa-plus fw-400"></i>
            </button>
        </div>
        
        <SuccessPopup
            popupId="updateSkillsPopup"
            title="Success!"
            style="success"
            message={"Skills updated"}
            trigger={skillSuccess}
            callback={() => setSkillSuccess(false) }
            useButton = {false}
            />

    </>
}


