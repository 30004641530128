import React, {Component, Fragment} from 'react'
import { url } from '../../constants'
import {
    LibraryService
} from '../../services'
import {
    loadEntryImages
} from "../../utils"

export default class ViewEntryModal extends Component {


    constructor(props){
        super(props);

        this.state = {
            images: [],
            oppositeRelations: [],
            identry: 0,
        }
    }

    componentWillReceiveProps = async (newProps) => {
        let {identry} = this.state;
        let {mode} = this.props;
        let idnew = (newProps.entry != null ? newProps.entry.idaspect || newProps.entry.idmeasure : null);
        let images = loadEntryImages(newProps.entry)
        let modeNameSimple = newProps.capitalizedModeName.toLowerCase();
        let oppositeRelations = await LibraryService.getOppositeRelations(idnew, modeNameSimple);
        this.setState({images, oppositeRelations});
    }

    render = () => {
        let {entry, id, children, capitalizedModeName, oppositeModeName, filterIds} = this.props;
        let {images, oppositeRelations} = this.state;

    	return(<div class="modal fade" id={id} tabindex="-1" role="dialog" aria-labelledby={id} aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <p class="modal-title strong fs-17" id={id}>{entry ? entry.name : ""}</p>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-modal-button">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                    <div class="col-12">
                        <p class="fs-16"><span class="small strong">Library Group: </span><i class="fa fa-cubes px-2" aria-hidden="true"></i><strong>{entry ? entry.type : ""}</strong></p>
                        <p class="small strong mb-1">{capitalizedModeName} Description:</p>
                        <p>{entry ? entry.description : ""}</p>

                        {images ? <div class="row">
                            <div class="col-6 col-sm-3 px-1">
                            	{images.map((image, index) => this.renderImage(image, index, 0, 4))}
                            </div>
                            <div class="col-6 col-sm-3 px-1">
                                {images.map((image, index) => this.renderImage(image, index, 1, 4))}
                            </div>
                            <div class="col-6 col-sm-3 px-1">
                                {images.map((image, index) => this.renderImage(image, index, 2, 4))}
                            </div>
                            <div class="col-6 col-sm-3 px-1">
                                {images.map((image, index) => this.renderImage(image, index, 3, 4))}
                            </div>
                         </div> : null}

                        {filterIds ? 
                            <p class="small strong mt-4 mb-1">Relevant {oppositeModeName}s:</p>
                        :
                            <p class="small strong mt-4 mb-1">Connected {oppositeModeName}s:</p>
                        }
                        <ul class="card-columns">
                            {oppositeRelations.length == 0 ? 
                                <li>None!</li>
                            : (oppositeRelations.map((relation, index) => {

                                if (filterIds != undefined){
                                    let id = relation.idaspect || relation.idmeasure;
                                    if (filterIds.indexOf(id) !== -1){
                                        return this.renderRelation(relation, index);
                                    } else {
                                        return null;
                                    }
                                } else {
                                    return this.renderRelation(relation, index)
                                }

            
                            }))}
                        </ul>
                    </div>
                </div>

                <div class="modal-footer">
                    {children}
                </div>
              </div>
            </div>
        </div>);
    }

    renderRelation = (relation, index) => {
        return(<li key={index}>{relation.name}</li>)
    }

    renderImage = (image, index, rowNum, totalRows) => {
        if (index % totalRows == rowNum){
            return <img src={url.backend + "/images/aspectmeasures/" + image} class="img-fluid img-thumbnail p-3 mb-2" data-provide="lightbox" alt="..." />
        } else {
            return null;
        }
    }

}