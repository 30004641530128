import React from 'react';

export default function EnterEntry ({entry, entryIndex, groupIndex, mode, terms, updateEntry, updateEntryIndex, smallCharLimit, bigCharLimit}) {
    
        return(<div class="col-12 pl-0 mb-7 mb-lg-3 ml-4 ml-lg-8 clearfix">
            <i class="fa fa-cube float-left mt-4 pr-2 pr-lg-0" aria-hidden="true"></i>
            <div class="col-11 col-lg-6 p-0 pr-lg-6 mr-lg-2 mb-4 ml-lg-4 float-left">
                <div className="contrast">
                    <input className={"col-11 form-control p-0"}
                        type="text" 
                        placeholder={terms.mode.display + " Name"}
                        value={entry.name}
                        maxLength={smallCharLimit}
                        onChange={(e) => updateEntry("name", e.target.value, groupIndex, entryIndex)} />
                </div>
            </div>
            <div className="col-lg-6 float-left ml-lg-4">
                <div className="form-group">
                    <textarea className={"form-control"} 
                        placeholder={terms.mode.display + " Description"} 
                        rows="2"
                        value={entry.description}
                        maxLength={bigCharLimit}
                        onChange={(e) => updateEntry("description", e.target.value, groupIndex, entryIndex)}></textarea>

                </div>
            </div>
            <div className="col-lg-5 float-left">
                <button type="button" className="btn btn-xs btn-outline-primary mr-lg-3" data-toggle="modal" data-target="#connect-practices"
                    onClick={() => updateEntryIndex(groupIndex, entryIndex)}>Connect {terms.opposite.displayPlural}</button>
                <button type="button" className="btn btn-xs btn-secondary" data-toggle="modal" data-target="#add-photos"
                    onClick={() => updateEntryIndex(groupIndex, entryIndex)}>Add Images</button>
            </div>
        </div>)
    

}