import React, {Component, Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { url } from '../../../constants'
import {
    LibraryService
} from '../../../services'
import {
    etchLibraryTerms,
    loadEntryImages
} from '../../../utils'


export default function ViewEntryModal({entry, mode, id, footer, children, filterIds}) {


    const terms = etchLibraryTerms(mode)
    const [images, setImages] = useState([]);
    const [oppositeRelations, setOppositeRelations] = useState([])

    useEffect(() => {
        loadData();
    }, [JSON.stringify(entry)])

    let loadData = async () => {
        let {identry} = terms.mode;

        if (entry[identry]){
            setImages(() => {
                loadEntryImages(entry)
            });

            setOppositeRelations(await LibraryService.getOppositeRelations(entry[identry], mode))
        }

    }

    let renderRelation = (relation, index) => {
        console.log("calling renderRelation with relation", relation);
        return(<li key={index}>{relation.name}</li>)
    }

    let renderImage = (image, index, rowNum, totalRows) => {
        if (index % totalRows == rowNum){
            return <img src={url.backend + "/images/"+terms.library.simple+"/" + image} class="img-fluid img-thumbnail p-3 mb-2" data-provide="lightbox" alt="..." />
        } else {
            return null;
        }
    }


	return(<div class="modal fade" id={id} tabindex="-1" role="dialog" aria-labelledby={id} aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title strong fs-17" id={id}>{entry ? entry.name : ""}</p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-modal-button">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
                <div class="col-12">
                    <p class="fs-16"><span class="small strong">Library Group: </span><i class="fa fa-cubes px-2" aria-hidden="true"></i><strong>{entry ? entry.type : ""}</strong></p>
                    <p class="small strong mb-1">{terms.mode.display} Description:</p>
                    <p>{entry ? entry.description : ""}</p>

                    {images ? <div class="row">
                        <div class="col-6 col-sm-3 px-1">
                        	{images.map((image, index) => renderImage(image, index, 0, 4))}
                        </div>
                        <div class="col-6 col-sm-3 px-1">
                            {images.map((image, index) => renderImage(image, index, 1, 4))}
                        </div>
                        <div class="col-6 col-sm-3 px-1">
                            {images.map((image, index) => renderImage(image, index, 2, 4))}
                        </div>
                        <div class="col-6 col-sm-3 px-1">
                            {images.map((image, index) => renderImage(image, index, 3, 4))}
                        </div>
                     </div> : null}

                    {filterIds ? 
                        <p class="small strong mt-4 mb-1">Relevant {terms.opposite.display}s:</p>
                    :
                        <p class="small strong mt-4 mb-1">Connected {terms.opposite.display}s:</p>
                    }
                    <ul class="card-columns">
                        {_.isEmpty(oppositeRelations) ? 
                            <li>None!</li>
                        : (oppositeRelations.map((relation, index) => {

                            if (filterIds === undefined || filterIds.indexOf(entry[terms.mode.identry]) !== -1){
                                return renderRelation(relation, index);
                            } else {
                                return null;
                            }

                        }))}
                    </ul>
                </div>
            </div>

            <div class="modal-footer">
                {footer ? footer() : children ? children : null}
            </div>
          </div>
        </div>
    </div>);

}