import React, {Fragment} from 'react';

import ReportSetup from '../components/Reports/ReportSetup'
import ViewReport from '../components/Reports/ViewReport'
import HuddleReports from '../components/Reports/HuddleReports/HuddleReports'

import ActiveSites from '../components/SiteManagement/ActiveSites'
import NewSite from '../components/SiteManagement/NewSite'
import ImportSites from '../components/SiteManagement/ImportSites'

import WorkUnit from '../components/People/WorkUnitCrew/WorkUnitCrew'
import StakeholderContacts from '../components/People/StakeholderContacts/StakeholderContacts'

import AddGroup from '../components/Library/AddGroup';
import ViewLibrary from '../components/Library/ViewLibrary'

import _ from 'lodash';

const common = "/app"

let panels = new Map([
   

    //Reports Sub-Menu
    
    ['report-setup',{
      path: common+"/report-setup",
      menu: "Reports",
      linkName: "New Report",
      displaySidebar: true,
      showInSidebar: true,
      displayTo: ["admin", "crewleader"],
      showIfNoWorkGroup: false,
      JSX: (props) => <ReportSetup {... props} />
    }],
    ['huddle-reports',{
      path: common+"/huddle-reports",
      menu: "Reports",
      linkName: "Huddle Reports",
      displaySidebar: true,
      showInSidebar: true,
      displayTo: ["employee", "admin", "crewleader"],
      showIfNoWorkGroup: false,
      JSX: (props) => <HuddleReports {...props} />
    }],
    ['report-view',{
      path: common+"/report-view",
      menu: "Reports",
      linkName: "View Report",
      displaySidebar: true,
      showInSidebar: false,
      displayTo: ["employee", "admin", "crewleader"],
      showIfNoWorkGroup: false,
      JSX: (props) => <ViewReport {...props} />
    }],


    //Sites sub-menu

   ['active-sites', {
      path: common+'/active-sites',
      menu: "Sites",
      linkName: "Active Sites",
      displaySidebar: true,
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <ActiveSites {...props} />
    }],
    ['new-site', {
      path: common+'/new-site',
      menu: "Sites",
      linkName: "New Site",
      displaySidebar: true,
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <NewSite {...props} />
    }],
    ['import-sites', {
      path: common+'/import-sites',
      menu: "Sites",
      linkName: "Import Sites",
      displaySidebar: true,
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <ImportSites {...props} />
    }],
    //Archived Sites placeholder 

    //People sub-menu

    ['workunit-crew', {
      path: common+'/workunit-crew',
      menu: "People",
      linkName: "Work Unit Crew",
      displayTo: ["admin", "crewleader"],
      showInSidebar: true,
      showIfNoWorkGroup: true,
      displaySidebar: true,
      JSX: (props) => <WorkUnit {...props} />
    }],
    ['stakeholder-contacts', {
      path: common+'/stakeholder-contacts',
      menu: "People",
      linkName: "Stakeholder Contacts",
      displaySidebar: true,
      displayTo: ["admin", "crewleader"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <StakeholderContacts {...props} />
    }],

    //Library sub-menu
    ['workunit-library-aspects', {
      path: common+'/workunit-library-aspects',
      menu: "Library",
      linkName: "Aspect Collection",
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <ViewLibrary mode={"aspect"} {...props} />
    }],
    ['workunit-library-measures', {
      path: common+'/workunit-library-measures',
      menu: "Library",
      linkName: "Measure Collection",
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <ViewLibrary mode={"measure"} {...props} />
    }],
    ['aspect-group-setup',{
      path: common+'/aspect-group-setup',
      menu: "Library",
      linkName: "",
      displayTo: ["admin"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: false,
      JSX: (props) => <AddGroup mode={"aspect"} {...props} /> 
    }],
    ['measure-group-setup', {
      path: common+'/measure-group-setup',
      menu: "Library",
      linkName: "",
      displayTo: ["admin"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: false,
      JSX: (props) => <AddGroup mode={"measure"} {...props} />
    }]

]);

export default panels;