import _ from 'lodash'

export function alphanumericSort(passedArray, sortKey = null){

	if (passedArray === undefined || passedArray === null || !Array.isArray(passedArray)){
		return passedArray;
	}

	let arr = _.cloneDeep(passedArray);

	if (arr.length > 0){
		if (typeof arr[0] == 'object' || arr[0] instanceof Object){
			return alphanumericSortObj(arr, sortKey);
		}
	}

	return passedArray;
}

function alphanumericSortObj(arr, sortKey){
	let numericList = _.sortBy(_.filter(_.cloneDeep(arr), (item) => {
		return getIsNumber(item[sortKey][0]);
	}), (item) => { return item[sortKey] })

	let alphabeticList = _.sortBy(_.filter(_.cloneDeep(arr), (item) => {
		return !getIsNumber(item[sortKey][0]);
	}), (item) => { return item[sortKey] })

	return _.concat(alphabeticList, numericList);
}

function getIsNumber(char){
  if (typeof char !== 'string') {
    return false;
  }

  if (char.trim() === '') {
    return false;
  }

  return !isNaN(char);
}